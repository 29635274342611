/**
 * API SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-22
 */

import AbstractService from '../../library/AbstractService';

export default class ApiService extends AbstractService {
    name= 'api';
}