/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import {combineReducers} from 'redux';
import auth from './auth';
import admin from './admin';

export default combineReducers({
    auth,
    admin
});
