/**
 * CONTROLLER ABSTRACT
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import bcrypt from 'bcryptjs';
import {Storage} from "./index";

export default class Utils {

    /**
     * FORMAT TEXT CAPITALIZE
     * @param name
     */
    static ucFirst = (name: any) => {
        return name.charAt(0).toUpperCase() + name.slice(1)
    }

    /**
     * CREATE PASSWORD ENCRYPTING
     * @param password
     * @returns {*}
     */
    static cryptPassword = (password: any) => {
        return bcrypt.hashSync(password, 10)
    };

    /**
     * VALIDATE PASSWORD
     * @param password
     * @param hash
     * @returns {*}
     */
    static checkPassword = (password: any, hash: any) => {
        return bcrypt.compare(password, hash);
    };

    /**
     * ORDER BY OBJECT BY PARAMETER
     * @param property
     * @param type
     * @returns
     */
    static OrderBy = (property: any, type: any = 'asc') => {
        let sortOrder: any = type === 'asc' ? 1 : -1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return (a: any, b: any) => {
            try {
                if (sortOrder === -1) {
                    return b[property].localeCompare(a[property]);
                } else {
                    return a[property].localeCompare(b[property]);
                }
            } catch (e) {
            }
        }
    }

    /**
     *
     * @returns GENERATE ID
     */
    static Id = () => {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + '-' + s4();
    };

    /**
     * FORMAT AND REMOVE ALL ACCENTS
     * @param s
     * @returns
     */
    static noAccents = (s: string) => {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/\s/g), "");
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        r = r.replace(new RegExp(/\W/g), "");
        return r;
    };

    /**
     * FORMAT AND REMOVE ALL ACCENTS
     * @param s
     * @returns
     */
    static noAccentsSpace = (s: string) => {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        return r;
    };

    /**
     * SHOW IMAGEM PATH SERVER
     * @param local
     * @param image
     * @param name
     * @returns
     */
    static _showImage = (local: any, image: any, name: any = null) => {
        let URL_API: any = Utils.getEnvironment('api');
        URL_API = URL_API.split('/');
        URL_API.pop('api');
        URL_API = URL_API.join('/');

        let [, host_name,]: any = window.location.hostname.split('.');
        if (!host_name) {
            [host_name] = Storage.get('entity');
        }
        try {
            let folder_name: any = host_name.split('.').join('_');
            if (name) {
                folder_name = name;
            }
            let src = `${URL_API}/uploads/${folder_name}/${local}/${image?.filename}`;
            if (!image) {
                const imageEntity: any = Storage.get('entity');
                let imageEmpty: any = `${URL_API}/images/NoImages.png`
                if (Object.keys(imageEntity).includes('logo_product') && imageEntity?.logo_product) {
                    imageEmpty = `${URL_API}/uploads/${host_name}/entities/${imageEntity?.logo_product?.filename}`;
                }
                src = imageEmpty;
            }
            return src
        } catch (error: any) {
            let imageEmpty: any = `${URL_API}/images/NoImages.png`
            return imageEmpty;
        }
    }

    static showImage = (local: any, image: any, field: any = 'cover', thumb: any = false) => {
        let URL_API: any = Utils.getEnvironment('api');
        let src: any = `${URL_API}/image/view/${local}/${field}/${image[field]?.filename.split('.')[0]}${thumb ? '?thumb=true' : ''}`;
        return src;
    }

    /**
     * FORMAT Calculation MONEY
     * @param value
     * @returns
     */
    static formatValue = (value: any) => {
        if (!value) return 0;
        return (parseFloat(value)).toLocaleString('pt-BR',
            {
                style: 'currency',
                currency: 'BRL'
            }
        );
    }

    /**
     * Parse value to currency
     * @param {number|string} input
     * @param {string} locale - Desired locale i.e: "en-US" "hr-HR"
     * @param {string} currency - Currency to use "USD" "EUR" "HRK"
     * @return {object}
     */
    static parse(input: any, locale = "pt-BR", currency = "BRL") {
        let fmt = String(input)
            .replace(/(?<=\d)[.,](?!\d+$)/g, "")
            .replace(",", ".");
        const pts = fmt.split(".");
        if (pts.length > 1) {
            if (+pts[0] === 0) fmt = pts.join(".");
            else if (pts[1].length === 3) fmt = pts.join("");
        }
        const number = Number(fmt);
        const intlNFOpts = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: currency,
        }).resolvedOptions();
        const output = number.toLocaleString(locale, {
            ...intlNFOpts,
            style: "decimal",
        });
        return output;
    };

    /**
     * GENERATE OBJECTID
     * @returns
     */
    static ObjectId = () => {
        var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };

    /**
     * CHECK DATE IS ISO STRING
     * @param str
     * @returns
     */
    static isIsoDate(str: any) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        var d = new Date(str);
        return d.toISOString() === str;
    }

    /**
     * CONVERT SIZE FILE IN BYTES
     * @param bytes
     * @returns
     */
    static SizeUnits = (bytes: any) => {
        if (bytes >= 1073741824) {
            bytes = (bytes / 1073741824).toFixed(2) + " GB";
        } else if (bytes >= 1048576) {
            bytes = (bytes / 1048576).toFixed(2) + " MB";
        } else if (bytes >= 1024) {
            bytes = (bytes / 1024).toFixed(2) + " KB";
        } else if (bytes > 1) {
            bytes = bytes + " bytes";
        } else if (bytes === 1) {
            bytes = bytes + " byte";
        } else {
            bytes = "0 bytes";
        }
        return bytes;
    }

    /**
     * GET CURRENT DATE UTC
     */
    getDateUTC() {
        const date: any = new Date();
        let new_date: any = new Date(date - 3600 * 1000 * 3);
        return new_date.toISOString();
    }

    /**
     * TOFXD NOT ARROUNDING
     * @param num
     * @param dec
     */
    static toFixed(num: any, dec: any) {
        dec = dec || 0;
        let s = String(num);
        if (num % 1) s = s.replace(/5$/, '6');
        return Number((+s).toFixed(dec));
    }

    /**
     * GET PI
     * @param x
     */
    static rad = (x: any) => {
        return x * Math.PI / 180;
    };

    /**
     * GET DISTANCE OF TWO POINTS
     * @param p1
     * @param p2
     */
    static getDistance = (p1: any, p2: any) => {
        let R: any = 6378137;
        let dLat: any = Utils.rad(p2.lat - p1.lat);
        let dLong: any = Utils.rad(p2.lng - p1.lng);
        let a: any = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(Utils.rad(p1.lat)) * Math.cos(Utils.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        let c: any = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let d: any = R * c;
        return d;
    };

    /**
     * FORMAT PHONE
     * @param phone
     */
    static formatPhone = (phone: any) => {
        if (phone.length <= 8) {
            return phone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2')
        } else if (phone.length <= 10) {
            phone = '85' + phone;
            return phone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2')
        } else if (phone.length <= 9) {
            return phone.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2')
        } else {
            return phone
        }
    }

    static getPropertyCSS(field: any) {
        document.documentElement.style.getPropertyValue(`--${field}`);
    }

    /**
     * GET NUMBER OF WEEK
     */
    static getNumberOfWeek() {
        const today: any = new Date();
        const firstDayOfYear: any = new Date(today.getFullYear(), 0, 1);
        const pastDaysOfYear: any = (today - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    /**
     * GET WEEK BY DATE
     * @param week
     * @param year
     */
    static getDateByWeek(week: any, year: any) {
        let d = new Date(year, 0);
        let dayNum = d.getDay();
        let requiredDate = --week * 7;
        if (dayNum !== 0 || dayNum > 4) {
            requiredDate += 7;
        }
        d.setDate(1 - d.getDay() + ++requiredDate);
        return d;
    }

    /**
     * GET NEXT WEEK
     */
    static nextweek() {
        let today: any = new Date();
        let nextweek: any = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
        return nextweek;
    }

    /**
     * GET WEEK
     * @param date
     */
    static getWeeking(date: any) {
        var target: any = new Date(date);
        var dayNr = (new Date().getDay() + 6) % 7;
        target.setDate(target.getDate() - dayNr + 3);
        var firstThursday = target.valueOf();
        target.setMonth(0, 1);
        if (target.getDay() !== 4) {
            target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
        }
        return 1 + Math.ceil((firstThursday - target) / 604800000);
    }

    /**
     * GET DATE RANGE OF WEEK
     * @param week
     */
    static getDateRangeOfWeek = (week: any) => {
        let d1: any = new Date();
        let numOfdaysPastSinceLastMonday: any = (d1.getDay() - 1);
        d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
        let weekNoToday: any = Utils.getWeeking(d1);
        let weeksInTheFuture: any = (week - weekNoToday);
        d1.setDate(d1.getDate() + (7 * weeksInTheFuture));
        let rangeIsFrom: any = d1.toISOString().slice(0, 10);
        d1.setDate(d1.getDate() + 6);
        let rangeIsTo: any = d1.toISOString().slice(0, 10);

        return [rangeIsFrom, rangeIsTo];
    };

    /**
     * GET WEEK NUMBER
     * @param date
     */
    static weekNumber(date: any) {
        let onejan: any = new Date(date.getFullYear(), 0, 1);
        let week: any = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() - 1) / 7);
        return {number: (week - 1), label: `${date.getFullYear()}-W${week - 1}`};
    }

    /**
     * GET ENVIRONMENT ACTUAL
     * @param name
     */
    static getEnvironment = (name: any) => {
        let {REACT_APP_ENV}: any = process.env;
        REACT_APP_ENV = REACT_APP_ENV.toUpperCase();
        return process.env[`REACT_APP_${REACT_APP_ENV}_${name.toUpperCase()}`];
    }

    /**
     * SUM DISCOUNT TOTAL OF REQUEST
     * @param document
     */
    static check_discount_total = (document: any) => {
        return Utils.formatValue(document?.totalDiscount);
    }

    /**
     * CHECK EXISTS DISCOUNT
     * @param document
     */
    static check_exists_discount = (document: any) => {
        return (document?.totalDiscount * -1) > 0;
    }

    /**
     * SHOW TIME EXPIRED DISCOUNT
     * @param time
     */
    static discount_expired = (time: any) => {
        let hours: any = time / 60;
        let minutes: any = time % 60;

        hours = Math.floor(hours);
        minutes = minutes <= 9 ? `0${minutes}` : minutes;
        if (hours > 0) {
            return `${hours}h${minutes}`;
        } else {
            return `${minutes}min`;
        }
    }

    /**
     * CONVERT ANY VALUE FOR NEGATIVE
     * @param item
     */
    static convert_value_negative = (item: any) => {
        const amount: any = (item?.discount);
        if (amount.toString().indexOf('-') !== -1) {
            return Utils.formatValue((item?.discount));
        } else {
            return Utils.formatValue((item?.discount) * -1);
        }
    }

    /**
     * RANDOM VALUES
     * @param min
     * @param max
     */
    static getRandomInt(min: any, max: any) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
    }

    /**!
     * SET VALUE FLOAT
     * @param value
     */
    static valueFloat = (value: any) => {
        try {
            if (value.indexOf(',') !== -1) {
                return value.replace(/,/g, '.');
            } else {
                return value;
            }
        } catch (e) {
            return value;
        }
    }

    /**
     * CHECK AMOUNT VALUE IN PRODUCT
     * @param item
     */
    static check_amount_value = (item: any) => {
        if (item?.amount && item?.amount !== "0") {
            return parseFloat(item?.amount.replace(/,/g, '.'))
        } else {
            return item?.price;
        }
    }

    /**
     * FORMAT NAME CLIENT
     * @param key
     */
    static get_customer_name = (key: any) => {
        let check_name: any;
        if (Object.keys(key).includes('customer')) {
            check_name = key?.customer?.name.split(' ');
        } else {
            check_name = key?.name.split(' ');
        }
        check_name = check_name.filter((n: any) => n !== 'undefined');
        // @ts-ignore
        check_name = [...new Set(check_name)];
        if (check_name.length > 1) {
            return check_name.slice(0, 2).join(" ").toUpperCase();
        } else {
            return check_name[0].toUpperCase() || key?.customer?.name.toUpperCase();
        }
    }

    /**
     * CALCULATE TOTAL ITEMS REQUEST
     * @param req
     */
    static calculate_total_request = (req: any) => {
        let values: any = []
        req?.items.map((item: any) => {
            let price_item: any = (item.price * item.quantity);
            values.push(price_item)
            if (item.subItems.length > 0) {
                item.subItems.map((sub: any) => {
                    let price_subitem: any = (sub.price * sub.quantity);
                    values.push(price_subitem);
                    return sub;
                });
            }
        });

        values = values.reduce((a: any, b: any) => (a + b));
        let deliveryFee: any = req?.deliveryFee || 0;
        let totalDiscount: any = req?.totalDiscount || 0;
        return ((values + deliveryFee) + totalDiscount);
    }

    /**
     * CALCULATE VALUE DELIVERY FEE
     * @param store
     * @param address
     */
    static calculateFee = (store: any = null, address: any) => {
        try {
            const {REACT_APP_MAX_DISTANCE_KM}: any = process.env;
            let storeCoords: any = store?.coords.split(',').map((c: any) => parseFloat(parseFloat(c).toFixed(2)));
            let LatLngAdr: any = address?.geometry?.location;
            let LatLngSto: any = {lat: storeCoords[0], lng: storeCoords[1]}
            let distance: any = Utils.getDistance(LatLngAdr, LatLngSto);
            //@ts-ignore
            distance = parseFloat(distance / 1000);
            let max_distance: any = parseInt(REACT_APP_MAX_DISTANCE_KM);
            let prices: any = store?.prices;
            let result: any = {km: 0, value: 0};

            if (prices.length > 0) {
                result = prices.filter((p: any) => p.km <= max_distance).reduce((anterior: any, corrente: any) => {
                    return (Math.abs(corrente.km - parseFloat(distance.toFixed(2))) < Math.abs(anterior.km - parseFloat(distance.toFixed(2))) ? corrente : anterior);
                });
            } else {
                result = {
                    km: parseFloat(distance.toFixed(2)),
                    value: 0,
                }
            }

            return {km: result.km, fee: result.value, distance};
        } catch (error: any) {
            return {km: 0, fee: 0};
        }
    }

    /**
     * REMOVE ADDRESS DUPLICATE
     * @param array
     */
    static remove_duplicate_address(array: any) {
        return Array.from(new Set(array.map((a: any) => a.place_id)))
            .map(id => {
                return array.find((a: any) => a.place_id === id)
            })
    }

    static formatDateString(data: any, time: any = false) {
        try {
            if (!data) return '';
            if (!time) {
                return data.slice(0, 10).split('-').reverse().join('/');
            } else {
                const explode: any = data.split('T');
                return `${explode[0].slice(0, 10).split('-').reverse().join('/')} às ${explode[1].slice(0, 5)}`;
            }
        } catch (error: any) {
            return data;
        }
    }

    static replacer(match: any, pIndent: any, pKey: any, pVal: any, pEnd: any) {
        let key: any = '<span data-name=json-key>';
        let val: any = '<span data-name=json-value>';
        let str: any = '<span data-name=json-string>';
        var r = pIndent || '';
        if (pKey)
            r = r + key + pKey.replace(/[": ]/g, '') + '</span>: ';
        if (pVal)
            r = r + (pVal[0] === '"' ? str : val) + pVal + '</span>';
        return r + (pEnd || '');
    }

    static pretty(obj: any) {
        let jsonLine: any = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/mg;
        return JSON.stringify(obj, null, 3)
            .replace(/&/g, '&amp;').replace(/\\"/g, '&quot;')
            .replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(jsonLine, Utils.replacer);
    }

    /**
     * GET CURRENT DATE UTC
     */
    static getDateTimeCurrent() {
        const date: any = new Date();
        let new_date: any = new Date(date - 3600 * 1000 * 3);
        return new_date.toISOString();
    }

    static thumbnail = (data: any) => {
        if (data && Object.keys(data).includes('filename')) {
            return Utils.showImage('students', data, 'photo');
        } else {
            return require(`../../assets/${data?.genre || 'default'}.png`)
        }
    }
}
