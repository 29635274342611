/**
 * Parameter SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import AbstractService from '../../library/AbstractService';

export default class ParameterService extends AbstractService {
    name= 'parameter';
    /**
     * GET DOCUMENTS BY FIELD
     * @param field 
     * @returns 
     */
    async byField(field: any) {
        return await this.getAdapter().get(`${this.name}/by_field`, { params: { field } });
    }
}