/**
 * STUDENTS SERVICE
 */

import AbstractService from '../library/AbstractService';

export default class StudentsService extends AbstractService {
    name = 'students';

    async installments(id: any) {
        return await this.getAdapter().get(`${this.name}/installments`, {params: id});
    }

    async down_payment(id: any) {
        return await this.getAdapter().get(`${this.name}/down_payment`, {params: id});
    }

    async school_report(data: any) {
        return await this.getAdapter().post(`${this.name}/school_report`, data);
    }

    async school_assessment(data: any) {
        console.log(data);
        return await this.getAdapter().post(`${this.name}/school_assessment`, data);
    }

    async clear_installments(id: any) {
        return await this.getAdapter().get(`${this.name}/clear_installments`, {params: id});
    }

    async behavior(data: any) {
        return await this.getAdapter().post(`behaviorstudent`, data);
    }
};