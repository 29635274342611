/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'checkbox',
    }

    private field = createRef<any>();

    componentDidMount() {
        this.setState({type: this.props.type});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */

    render() {
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                        <label>{this.props.label}</label>
                        <span data-active={this.props.value}>
                        <input
                            name={this.props.name}
                            checked={this.props.type === 'checkbox' ? this.props.value : false}
                            required={this.props.required}
                            type={this.props.type || this.state.type}
                            placeholder={this.props.placeholder}
                            value={this.props.value}
                            onChange={this.props.change}
                            data-theme={this.props.theme ? this.props.theme : 'default'}
                        />
                        <figure/>
                    </span>

                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
