/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import AbstractService from '../../library/AbstractService';

/**
 * SERVICE LOGIN
 */
export default class LoginService extends AbstractService {

    name= 'login';

    /**
     * AUTHENTICATE USER
     * @param data
     * @returns
     */
    async auth(data: any) {
        return await this.getAdapter().post(this.name, data);
    }

    /**
     * RECOVER PASSWORD BY MAIL
     * @param data
     * @returns
     */
    async recovery(data: any) {
        return await this.getAdapter().post(`${this.name}/recovery`, data);
    }

    /**
     * RECOVER PASSWORD BY MAIL
     * @param data
     * @returns
     */
    async password_change(data: any) {
        return await this.getAdapter().post(`${this.name}/password_change`, data);
    }
}
