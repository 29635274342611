/**
 * SUBJECTCATEGORY PAGE INDEX
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../layout';
import {List} from '../../components/Crud';
import {Select} from "../../components/Fields";

/**
 * COMPONENT PAGE SUBJECTCATEGORY
 */
class Page extends List {


    /**
     * CHANGE STATUS DOCUMENT
     * @param item
     * @param field
     */
    changeHistory = async (item: any, field: any) => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: item[field] ? 'Não exibir' : 'Exibir',
            message: item[field] ? 'Deseja não exibir esta categoria no histórico?' : 'Deseja exibir esta categoria no histórico?',
            buttons: [
                {
                    label: "Não",
                    close: true
                },
                {
                    label: "Sim",
                    callback: async (props: any) => {
                        const getIndex: any = this.state.documents.findIndex((i: any) => i._id === item._id);
                        const documents: any = this.state.documents;
                        documents[getIndex][field] = !documents[getIndex][field];
                        this.setState({documents});
                        await this.getService().save({
                            _id: documents[getIndex]._id,
                            [field]: Boolean(documents[getIndex][field]) || false
                        });
                        props.close();
                    }
                }
            ]
        });
    }
    changeAssessment = async (item: any, field: any) => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: item[field] ? 'Desativar' : 'Ativar',
            message: item[field] ? 'Deseja ativar a avaliação?' : 'Deseja desativar a avaliação?',
            buttons: [
                {
                    label: "Não",
                    close: true
                },
                {
                    label: "Sim",
                    callback: async (props: any) => {
                        const getIndex: any = this.state.documents.findIndex((i: any) => i._id === item._id);
                        const documents: any = this.state.documents;
                        documents[getIndex][field] = !documents[getIndex][field];
                        this.setState({documents});
                        await this.getService().save({
                            _id: documents[getIndex]._id,
                            [field]: Boolean(documents[getIndex][field]) || false
                        });
                        props.close();
                    }
                }
            ]
        });
    }
    changeBehavior = async (item: any, field: any) => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: item[field] ? 'Ativar' : 'Desatiar',
            message: item[field] ? 'Deseja ativar o comportamento?' : 'Deseja desativar o comportamento?',
            buttons: [
                {
                    label: "Não",
                    close: true
                },
                {
                    label: "Sim",
                    callback: async (props: any) => {
                        const getIndex: any = this.state.documents.findIndex((i: any) => i._id === item._id);
                        const documents: any = this.state.documents;
                        documents[getIndex][field] = !documents[getIndex][field];
                        this.setState({documents});
                        await this.getService().save({
                            _id: documents[getIndex]._id,
                            [field]: Boolean(documents[getIndex][field]) || false
                        });
                        props.close();
                    }
                }
            ]
        });
    }

    historyComponent = (item: any, body: any, index: any) => {
        return (<>
            <div style={{cursor: 'pointer'}} onClick={() => this.changeHistory(body, 'history')} data-status={true} title={body.history ? 'Não exibir no histórico' : 'Exibir no histórico'}>
                <i className={`fa fa-circle`} style={{color: body.history ? 'var(--color-green)' : 'var(--color-red)'}}/>
            </div>
        </>);
    }

    assessmentComponent = (item: any, body: any, index: any) => {
        return (<>
            <div style={{cursor: 'pointer'}} onClick={() => this.changeAssessment(body, 'assessment')} data-status={true} title={body.assessment ? 'Desativar avaliação' : 'Ativar avaliação'}>
                <i className={`fa fa-circle`} style={{color: body.assessment ? 'var(--color-green)' : 'var(--color-red)'}}/>
            </div>
        </>);
    }

    behaviorComponent = (item: any, body: any, index: any) => {
        return (<>
            <div style={{cursor: 'pointer'}} onClick={() => this.changeBehavior(body, 'behavior')} data-status={true} title={body.behavior ? 'Desativar comportamento' : 'Ativar comportamento'}>
                <i className={`fa fa-circle`} style={{color: body.behavior ? 'var(--color-green)' : 'var(--color-red)'}}/>
            </div>
        </>);
    }

    addFilter = () => {
        const {filter, extra}: any = this.state;
        return (<>
            {extra?.entity.length > 0 && <Select value={filter.entity} name='entity' label_option='Entidade' items={extra} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'entity', value: ''})}/>}
            <Select value={filter.status} name='status' label_option='Status' items={extra?.status} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'status', value: ''})}/>
        </>);
    }

    render() {
        return (<Layout>
            {super.render()}
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);