/**
 * REPORT PAGE VIEW
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../layout';
import {View} from '../../components/Crud';
import {Service} from "../../api";
import style from "./style.module.scss";
import {Button} from "../../components/Fields";
import {Utils} from "../../api/utils";
import styles from "../../components/Crud/style.module.scss";

/**
 * COMPONENT VIEW REPORT
 */
class Page extends View {

    set_presence = (student: any) => {
        const {extra}: any = this.state;
        let {classeroom}: any = extra;
        extra.classeroom = classeroom.map((s: any) => {
            if (s?._id === student?._id) {
                s.presence = !s.presence;
            }
            return s;
        });
        this.setState({extra});
    }

    save_report = () => {
        const {Alert}: any = this.props;
        try {
            const {document, extra}: any = this.state;
            const {classeroom, subjects}: any = extra;
            let reports: any = [];

            classeroom.map((c: any) => {
                subjects.map((s: any) => {
                    let item: any = {
                        presence: c?.presence,
                        student: c?._id,
                        date: document.date,
                        subject: s?._id,
                    }
                    reports.push(item);
                    return s;
                });

                return c;
            });
            let data: any = {
                _id: document?._id,
                reports
            }
            Service.getReportService().save(data).then(() => {
                Alert({message: 'A frequência foi salva com sucesso!'});
            });
        } catch (error: any) {
            Alert({title: 'Error', message: error.message});
        }
    }

    save_all = () => {
        const {Alert}: any = this.props;
        Alert({
            title: 'Salvar frequência',
            message: 'Deja salvar a frequência de todos os alunos da forma que está agora?',
            buttons: [
                {
                    label: 'Sim',
                    callback: () => this.save_report()
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }

    check_presence = (std: any) => {
        const {document}: any = this.state;
        return document.reports.find((s: any) => s?.student === std._id).presence;
    }

    check_birthdate = (student: any) => {
        const birthdate: any = student?.birthday.slice(0, 10);
        const currente_date: any = new Date().toISOString().slice(0, 10);
        const [birth_month, birth_day]: any = birthdate.split('-').slice(1, 2);
        const [current_month, current_day]: any = currente_date.split('-').slice(1, 2);
        return ((birth_day === current_day) && (birth_month === current_month));
    }

    render() {
        const {children}: any = this.props;
        const {loading, document, extra}: any = this.state;
        const {classeroom}: any = extra;
        return (<Layout>
            <>
                {loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
                </div>}

                {!loading && !this.getPermissions('view') && <div className={styles.MenuEmpty}>
                    <img src={require('../../assets/denied.svg')} alt='Forbiden'/>
                    <br/>
                    <label>Seus previlégios de usuário não lhe dão acesso para visualizar</label>
                    <br/>
                    <Button label='VOLTAR' data-name="save" icon='times' action={() => this.cancel()}/>
                </div>}

                {!loading && this.getPermissions('view') && <div className={styles.ViewContain}>
                    {children && React.cloneElement(children, {...this.props, ...this.state})}
                    {!children && <>
                        <section>
                            <h1><i className='fa-solid fa-list-check'/> <span>FRENQUÊNCIA</span></h1>
                            <div group-button="true" style={{display: 'flex', gap: 15}}>
                                <Button label='VOLTAR' icon='arrow-left' action={() => this.cancel()}/>
                                <Button label='SALVAR' icon='check' action={() => this.save_all()}/>
                            </div>
                        </section>
                        <section data-view={this.props.module}>
                            <div className={style.Teacher}>
                                <hgroup>
                                    <div>
                                        <figure style={{backgroundImage: `url(${Utils.thumbnail(document?._teacher)})`}}/>
                                        <span>
                                            <h1>{document?._teacher?.name}</h1>
                                            <label><b>Turma.: </b> {document?._classe?.name}</label>
                                        </span>
                                    </div>
                                </hgroup>
                                <hgroup>
                                </hgroup>
                            </div>
                            <div className={style.ClasseRoom}>
                                {classeroom && <ul>

                                    {classeroom.map((student: any, i: any) => <li key={i}>

                                        <figure style={{backgroundImage: `url(${Utils.thumbnail(student)})`}}>
                                            {this.check_birthdate(student) && <img src={require('../../assets/birthday.png')} alt='Birthday'/>}
                                        </figure>

                                        <div>
                                            <strong>{student?.name}</strong>
                                            <span><b>Matrícula:</b> {student?.register}</span>
                                            <span><b>Nasc.:</b> {Utils.formatDateString(student?.birthday)}</span>
                                            {student?.presence.toString()}
                                            <div onClick={() => this.set_presence(student)} data-presence={this.check_presence(student)}>
                                                <i className={`fa-solid fa-${this.check_presence(student) ? 'check' : 'times'}`}/>
                                                <em/>
                                            </div>
                                        </div>
                                    </li>)}
                                </ul>}
                            </div>
                        </section>
                    </>}
                </div>}
            </>

        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);