/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */
import React from 'react';
import Routes from './Routes';
import ClearCache from './ClearCache';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "./store/actions/adminActionCreators";
import * as authActionCreators from "./store/actions/authActionCreators";
import {connect} from "react-redux";

/**
 * COMPONENT APP
 */
class App extends React.Component<any, any> {

    state = {
        notify: false,
        messages: []
    }

    render() {
        return (
            <ClearCache>
                <Routes/>
            </ClearCache>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(App);