/**
 * INPUTS PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-24
 */

import React from 'react';
import style from "./style.module.scss";
import styles from "../../../components/Crud/style.module.scss";

/**
 * COMPONENT VIEW INPUTS
 */
class Plugins extends React.Component<any, any> {

    render() {
        const {active, check}: any = this.props;
        const {plugins}: any = this.props.data;

        return (
            <div className={style.Blocs}>

                {plugins.length <= 0 && <div className={styles.noitems}>
                    NO ENTITY
                </div>}

                {plugins.length > 0 && <hgroup>
                    {plugins.map((item: any, idx: any) =>
                        <div key={idx}>
                            <i className={`fas fa-${item?.icon}`}/>
                            <small>
                                <strong>{item?.name}</strong>
                                {item?.detail}
                            </small>
                            <input type='checkbox' name={item?.code} checked={check(item)} onChange={(e) => active(item)}/>
                        </div>)}
                </hgroup>}
            </div>
        );
    }
}

export default Plugins