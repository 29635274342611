import React, {Component} from 'react';
import packageJson from '../package.json';
import Loader from "./components/Loader";
import style from "./entity.module.scss"

//@ts-ignore
global.appVersion = packageJson.version;
const semverGreaterThan = (versionA: any, versionB: any) => {
    return versionA !== versionB;
};

class CacheBuster extends Component {
    state = {
        isLatestVersion: false,
        meta: null
    };

    componentDidMount() {
        const time = new Date();
        fetch(`/meta.json?${time.getTime()}`)
            .then((response) => response.json())
            .then((meta) => {
                const {version}: any = packageJson;
                const latestVersion: any = meta.version;
                const shouldForceRefresh: any = semverGreaterThan(latestVersion, version);
                this.setState({isLatestVersion: shouldForceRefresh, meta});
            });
    }

    refreshCacheAndReload = () => {
        console.log('CLEAR CACHE');
        if ('caches' in window) {
            caches.keys().then((names: any) => {
                for (let name of names) {
                    caches.delete(name);
                }
            });
            caches.keys().then((keyList: any) => {
                return Promise.all(keyList.map((key: any) => {
                    return caches.delete(key);
                }));
            })
            setTimeout(() => window.location.reload(), 2500);
        }
    }

    render() {
        const {appVersion}: any = global;
        const {isLatestVersion, meta}: any = this.state;
        const {children}: any = this.props;
        if (isLatestVersion) {
            this.refreshCacheAndReload();
        }
        return (<>
            {isLatestVersion && <>
                <Loader/>
                <div className={style.Version}>
                    <hgroup>
                        <strong>ATUALIZANDO VERSÃO...</strong>
                        <span><small>Atual: <b>{appVersion}</b></small> <small>---</small> <small>Nova: <b>{meta?.version || 'V0.0.0'}</b></small></span>
                    </hgroup>
                </div>
            </>}
            {!isLatestVersion && <>{children}</>}
        </>);
    }
}

export default CacheBuster;