/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import style from "./style.module.scss";
import {Service} from "../../../api";
import {Utils} from "../../../api/utils";

class Page extends Component<any, any> {

    state = {
        statistic: {},
        birthdays: [],
        installments: [],
        curriculum: []
    }

    componentDidMount() {
        Promise.all([this.get_data()]).then((result: any) => {
            const {statistic, birthdays, installments, curriculum}: any = result[0];
            this.setState({statistic, birthdays, installments, curriculum});
        });
    }

    get_data = async () => {
        const {data}: any = await Service.getDashboardService().get();
        return data;
    }

    school_year = () => {
        const current_date: any = new Date();
        let next_date: any = new Date();
        next_date.setFullYear(current_date.getFullYear() + 1);
        return `${current_date.getFullYear()} - ${next_date.getFullYear()}`;
    }

    get_name_month = (d: any) => {
        const date: any = new Date(d);
        return date.toLocaleString('default', {month: 'long'});
    }

    get_value_delay = (data: any) => {
        let amount: any = data?.months[0].amount || 0;
        const months: any = data?.months.length || 0
        return (amount * months);
    }

    sum_days = (months: any) => {
        if (months) {
            return 1;
            //return (months.reduce((a: any, b: any) => (a?.delay + b?.delay), 0));
        } else {
            return 0;
        }
    }

    goto_installments = (item: any) => {
        window.location.href = `students/view/${item?.id}`
    }

    render() {
        let current_date: any = new Date();
        let {entity}: any = this.props;
        const {statistic, birthdays, installments, curriculum}: any = this.state;
        const date: any = new Date();
        const month = date.toLocaleString('default', {month: 'long'});
        return (<>
                <Layout>
                    <>

                        <div className={style.Container}>
                            <div className={style.Header}>
                                <strong>DASHBOARD, {entity?.name}</strong>

                                <div><b>Fortaleza</b>, {current_date.toLocaleDateString('pt-BR', {day: '2-digit'})} de {current_date.toLocaleDateString('pt-BR', {month: 'long'})} de {current_date.getFullYear()} às {current_date.toLocaleTimeString().slice(0, 5)} </div>
                                <i className='fal fa-clock'/>
                            </div>
                            <div className={style.Main}>

                                {statistic.length > 0 && <ul className={style.Statistis}>
                                    {statistic.map((stat: any, s: any) => <li key={s}>
                                        <i className={`fal fa-${stat?.icon} fa-lg`}/>
                                        <span>
                                                <label>{stat?.label}</label>
                                                <big>{stat?.value || 0}</big>
                                            </span>
                                    </li>)}
                                </ul>}

                                <section className={style.Blocks}>
                                    <hgroup>
                                        <strong>Grade curricular</strong>
                                        <article>
                                            {curriculum.length > 0 && <ul className={style.Curriculum}>
                                                {curriculum.map((cur: any, i: any) => <li key={i}>
                                                    <b>{cur?.curriculum?.name}</b>
                                                    <hgroup>
                                                        <strong>{cur?.name}</strong>
                                                        <div>
                                                            {cur?.curriculum?.subject.map((sub: any, k: any) => <label key={k}>{sub}</label>)}
                                                        </div>
                                                    </hgroup>
                                                </li>)}
                                            </ul>}
                                        </article>
                                    </hgroup>
                                    <hr/>
                                    <hgroup>
                                        <strong>Aniversariantes de {month}</strong>
                                        {birthdays.length > 0 &&
                                            <article>
                                                <ul className={style.Birthdays}>
                                                    {birthdays.map((student: any, i: any) => <li key={i}>
                                                        <figure style={{backgroundImage: `url(${Utils.thumbnail(student)})`}}/>
                                                        <div>
                                                            <strong>{student?.name}</strong>
                                                            <span>Matrícula: {student?.register}</span>
                                                            <span>Nasc.: {Utils.formatDateString(student?.birthday)}</span>
                                                            <span>Turma/Turno.: {student?.serie}</span>
                                                        </div>
                                                    </li>)}
                                                </ul>
                                            </article>
                                        }
                                        {birthdays.length <= 0 && <picture className={style.BirthdaysEmpty}>
                                            <img src={require('../../../assets/birthday.png')} alt='Sem aniverariantes'/>
                                            <h1>Não há aniversariantes hoje!</h1>
                                        </picture>}
                                    </hgroup>
                                    <hr/>
                                    <hgroup>
                                        <strong>Mensalidades em atraso</strong>
                                        <article>
                                            <ul className={style.Installments}>
                                                {Object.keys(installments).map((inst: any, i: any) => <li key={i} onClick={() => this.goto_installments(installments[inst])}>
                                                    <figure style={{backgroundImage: `url(${Utils.thumbnail(installments[inst])})`}}/>
                                                    <div>
                                            <span>
                                                <b>{installments[inst]?.responsible_name}</b>
                                                <small>{installments[inst]?.name}</small>
                                            </span>
                                                        <hgroup>
                                                            <span><small>{installments[inst]?.months.length} Meses</small></span>
                                                            <code><small>{Utils.formatValue(this.get_value_delay(installments[inst]) || 0)}</small></code>
                                                        </hgroup>
                                                    </div>
                                                </li>)}
                                            </ul>
                                        </article>
                                    </hgroup>
                                </section>

                            </div>
                            <div className={style.Footer}>
                                <div><b>Ano letivo</b> {this.school_year()}</div>
                            </div>
                        </div>
                    </>
                </Layout>
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);