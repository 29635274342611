/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import AlertService from './AlertService';
import Entities from "./Entities";
import './index.scss';

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <AlertService/>
                <Entities/>
                <App/>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>, document.getElementById('root')
);
serviceWorker.register();