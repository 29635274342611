/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";

import styles from './style.module.scss';
import FieldsProops from '../FieldsProops';

/**
 * COMPONENT FIELD
 */

/**
 * CLASS FIELD
 */
class Field extends FieldsProops {

    /**
     *
     */
    state = {
        type: 'button'
    }

    check_exclude_field = () => {
        try {
            return this.props?.items?.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    /**
     *
     */
    render() {

        return (<>
            {this.check_exclude_field() && null}
            {[undefined, false].includes(this.check_exclude_field()) && <>
                {this.props.onlyButton && <>
                    <button onClick={() => this.props.action()} type={this.props.type || 'button'} className={styles.onlyButton} data-theme={this.props.theme ? this.props.theme : 'default'}>
                        {this.props.theme}
                        {this.props.icon && <i className={`fal fa-${this.props.icon}`}/>}
                        {this.props.label || 'Click'}
                    </button>
                </>}
                {!this.props.onlyButton && <>
                    <div className={styles.FieldButton} data-disabled={this.props.disabled}>
                        <button disabled={this.props.disabled || false} onClick={() => this.props.action !== undefined ? this.props.action() : {}} data-active={this.props.active} type={this.props.type || 'button'} data-theme={this.props.theme ? this.props.theme : 'default'}>
                            {this.props.icon && ([undefined, 'left']).includes(this.props.side) && <i className={`fal fa-${this.props.icon}`}/>}
                            <span>{this.props.label || 'Click'}</span>
                            {this.props.icon && (['right']).includes(this.props.side) && <i className={`fal fa-${this.props.icon}`}/>}
                        </button>
                    </div>
                </>}
            </>}
        </>);
    }
}

/**
 *
 * @param state
 */
const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
