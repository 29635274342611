/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import {Utils} from '../../../api/utils';
import {Emitter, Service} from '../../../api';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'file',
        file: {},
        files: [],
        galery: [],
        index: 0,
        metadata: {},
        loader: false,
        maxsize: 2048 * 2048 * 100,
        progress: ''
    }

    areaDrag = createRef<any>();
    progress = createRef<any>();

    acepts = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'application/svg+xml', 'image/svg+xml', 'image/gif']

    componentDidMount() {
        if (this.props.value) {
            this.setState({galery: this.props.value});
        }
        Emitter.on('CLEAR_FILE', () => this.clear_file());
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */
    checkName() {
        return (this.props.value === null || this.props.value === "") ? 'SELECIONE UM ARQUIVO PARA ENVIAR...' : this.props.value?.filename;
    }

    send_files = () => {
        let {galery, files, index}: any = this.state;
        galery[index].started = true;
        this.setState({galery}, () => {
            this.upload(files[index], index).then(() => {
                if (index <= (galery.length - 1)) {
                    this.send_files();
                }
                index++;
                this.setState({index});
            });
        });
    }

    async upload(file: any, index: any) {
        let {galery,}: any = this.state;
        const {module}: any = this.props;

        this.setState({loader: true});
        const classService: any = Service;
        let moduleName = Utils.ucFirst(module);
        moduleName = moduleName.replace('-', '');
        return await classService[`get${moduleName}Service`]().uploads(file,
            (result: any, progress: any) => {
                galery[index].progress = progress;
                this.setState({galery});
            },
            (success: any) => {
                galery[index].complete = true;
                galery[index].started = false;
                this.setState({galery});
            },
            (error: any) => console.log(error)
        )
    }

    clear = () => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: 'Remover arquivo',
            message: 'Deseja remover este arquivo agora?',
            buttons: [
                {
                    label: 'Não',
                    close: true
                },
                {
                    label: 'Sim',
                    callback: (props: any) => this.clear_file()
                }
            ]
        })
    }

    clear_file = async () => {
        const {Alert, fields, module}: any = this.props;
        this.setState({loader: true})
        const classService: any = Service;
        let moduleName = Utils.ucFirst(module);
        moduleName = moduleName.replace('-', '');
        const file = fields[this.props.name].filename;
        classService[`get${moduleName}Service`]().clear_file({
            _id: fields._id,
            field: this.props.name,
            file
        }).then((success: any) => {
            this.props.fields[this.props.name] = {};
            this.props.setFields(this.props.fields);
            this.areaDrag.current.style.backgroundColor = 'transparent';
            this.setState({loader: false});
            Alert(false);
        }).catch((error: any) => {
            Alert(false);
        });
    }

    ondrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        let galery: any = [];
        let files: any = e.dataTransfer.files;
        this.setState({files});

        for (let f: any = 0; f < files.length; f++) {
            const file = files[f];

            let extension = file.type.split('/')[1] || file.type;

            if (extension.indexOf('svg') !== -1) {
                extension = 'svg';
            }

            file.registername = `${Utils.ObjectId()}.${extension}`;
            const reader: any = new FileReader();
            let checkImage: any = new Image();
            reader.onloadend = () => {
                checkImage.onload = () => {
                    let item_galery: any = {
                        orginal: file.name,
                        filename: file.registername,
                        type: file.type.trim(),
                        size: file.size,
                        data: reader.result,
                        progress: 0,
                        started: false,
                        complete: false,
                        dimensions: {width: checkImage.width, height: checkImage.height}
                    };
                    galery.push(item_galery);
                    this.setState({galery}, () => {
                        if (f >= (files.length - 1)) {
                            if (this.props.uploaded) this.send_files();
                            this.props.set_galery(galery);
                        }
                    });
                }
                checkImage.src = reader.result;
            }
            reader.readAsDataURL(file);

            /*try {
                if (!Object.keys(fields).includes(this.props.name)) {
                    let item_galery: any = {
                        orginal: file.name,
                        filename: file.registername,
                        type: file.type.trim(),
                        size: file.size,
                        progress: 0,
                        data: reader.result,
                        complete: false,
                        dimensions: {}
                    };
                }
                // const current = fields[this.props.name];
                // file.current = current.filename;
            } catch (error: any) {
                // Alert({
                //     title: 'Error',
                //     message: error?.message
                // });
            }*/
            // this.upload(file);
        }

        this.areaDrag.current.style.border = 'none';
        this.areaDrag.current.style.width = '100%';
        this.areaDrag.current.style.backgroundColor = 'white';
        this.areaDrag.current.style.justifyContent = 'flex-start !important';
        this.areaDrag.current.style.borderWidth = 'thin';
    }

    cleanup_fields = (galery: any) => {
        return galery.map((g: any) => {
            delete g?.process;
            delete g?.started;
            delete g?.data;
            delete g?.complete;
            return g;
        });
    }

    ondragover = (e: any) => {
        e.preventDefault();
        this.areaDrag.current.style.borderColor = 'var(--primary)';
        this.areaDrag.current.style.backgroundColor = '#00000020';
    }

    ondragenter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    ondragleave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.areaDrag.current.style.borderColor = '#00000026';
        this.areaDrag.current.style.backgroundColor = 'transparent';
    }

    selectFile = (e: any) => {
        console.log(e.files);
        // let file = e.files[0];
        // let {fields, Alert}: any = this.props;
        // const fileSize: any = this.state.maxsize;
        // if (file.size > fileSize) {
        //     Alert({
        //         title: 'Limite excedido',
        //         message: `Não foi possivel enviar o arquivo pois excede o mimite máximo de <b>${Utils.SizeUnits(fileSize)}</b> o arquivo tem <b>${Utils.SizeUnits(file.size)}</b>`
        //     })
        //     return;
        // }
        // const acepts: any = this.acepts;
        // if (!acepts.includes(file?.type)) {
        //     Alert({
        //         title: 'Arquivo não permitido',
        //         message: `O arquivo que enviou não é do tipo valido verifique os tipos abaixo e tente novamento: <br/> ${acepts.join(', ')}`
        //     })
        //     return;
        // }
        //
        // const extension = file.type.split('/')[1] || file.type;
        // file.registername = `${Utils.ObjectId()}.${extension}`;
        // const reader: any = new FileReader();
        //
        // reader.onloadend = () => {
        //     let checkImage: any = new Image();
        //     checkImage.onload = () => {
        //
        //         fields[this.props?.name] = {
        //             orginal: file.name,
        //             filename: file.registername,
        //             type: file.type.trim(),
        //             size: file.size,
        //             data: reader.result,
        //             dimensions: {width: checkImage.width, height: checkImage.height}
        //         };
        //         this.props.setFields(fields);
        //         this.setState({file});
        //     }
        //     checkImage.src = reader.result;
        // }
        // reader.readAsDataURL(file);
        // try {
        //     if (!Object.keys(fields).includes(this.props.name)) {
        //         fields[this.props.name] = {
        //             orginal: file.name,
        //             filename: file.registername,
        //             type: file.type.trim(),
        //             size: file.size,
        //             data: reader.result,
        //             dimensions: {}
        //         };
        //     }
        //     const current = fields[this.props.name];
        //     file.current = current.filename;
        // } catch (error: any) {
        //     Alert({
        //         title: 'Error',
        //         message: error?.message
        //     });
        // }
        // this.upload(file);
    }

    checkFileExist = () => {
        const {fields, name}: any = this.props;
        if (!Object.keys(fields).includes(name)) return true;
        if ([undefined, null, ' '].includes(fields[this.props?.name])) return true;
        if (typeof (fields[this.props?.name]) === 'string') {
            return true;
        } else {
            return Object.keys(fields[this.props?.name]).length <= 0;
        }
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {galery}: any = this.state;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-drag={this.props.drag} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        {!this.props.drag && <>
                            <i className={`fal fa-upload`}/>
                            <input
                                name={this.props.name}
                                checked={this.props.type === 'checkbox' ? this.props.value : false}
                                required={this.props.required}
                                type={this.state.type}
                                data-name={this.checkName()}
                                data-title='Clique aqui para selecionar um arquivo.'
                                placeholder={this.props.placeholder}
                                className={this.props.type === 'checkbox' ? 'switch' : ''}
                                onChange={this.props.change}
                                data-theme={this.props.theme ? this.props.theme : 'default'}
                            />
                        </>}
                        {this.props.drag && <>
                            <div ref={this.areaDrag} data-populate={galery.length > 0}
                                 onDrop={(e) => this.ondrop(e)}
                                 onDragOver={(e) => this.ondragover(e)}
                                 onDragEnter={(e) => this.ondragenter(e)}
                                 onDragLeave={(e) => this.ondragleave(e)}>

                                {galery.length <= 0 && <>
                                    <span>Arraste e solte aqui a imagem para fazer upload ou clique no botão para procura-lá!</span>
                                    <article>
                                        <input type='file' multiple={true} data-title='Procurar...'/>
                                        <button><i className='fa fa-folder'/>Selecionar arquivo</button>
                                    </article>
                                </>}

                                {galery.length > 0 && <>
                                    <ol className={styles.Galeries}>
                                        {galery.map((g: any, i: any) => <li key={i}>
                                            <figure style={{backgroundImage: `url(${g?.data})`}}>
                                                <i className='fa fa-times'/>
                                                {g?.started && <picture className={styles.LoaderList}>
                                                    <span data-pct={g?.progress || 0}><figure role="progressbar"/></span>
                                                </picture>}
                                            </figure>
                                        </li>)}
                                    </ol>
                                </>}
                            </div>
                        </>}
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({...state.admin, ...state.auth,});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
;