/**
 * PROFILE SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import AbstractService from '../../library/AbstractService';

export default class ProfileService extends AbstractService {
    name= 'profile';
}