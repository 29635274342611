/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import CurrencyInput from "react-currency-input-field";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'money',
    }

    private field = createRef<any>();

    componentDidMount() {
        this.setState({type: this.props.type});
    }

    formatValue = (value: any) => {
        let formatter = value?.toString();
        return formatter;
    }

    change = (value: any, name: any) => {
        this.props.change_money(value, name);
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <CurrencyInput
                            ref={this.field}
                            id={this.props.name}
                            name={this.props.name}
                            data-type='money'
                            prefix="R$ "
                            defaultValue={this.formatValue(this.props.value || 0)}
                            value={this.formatValue(this.props.value || 0)}
                            onValueChange={(value, name) => this.change(value, name)}
                            groupSeparator='.'
                            decimalSeparator=','
                        />
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);