/**
 * INPUTS PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-24
 */

import React from 'react';
import style from "./style.module.scss";
import {Utils} from "../../../api/utils";

/**
 * COMPONENT VIEW INPUTS
 */
class Permissions extends React.Component<any, any> {
    render() {
        const {modules, permissions}: any = this.props.data;
        let {checkAllModule, checkValidate, checkAllPermission, addPermission, checkPermissionInProfile}: any = this.props;

        return (<>
                <table className={style.TablePermissions}>
                    <thead>
                    <tr>
                        <th/>
                        <th/>
                        {permissions && permissions.sort(Utils.OrderBy('label')).map((th: any, th_idx: number) =>
                            <th key={th_idx} onClick={() => checkAllModule(th)}>{th?.label.toUpperCase().slice(0, 4)}</th>
                        )}
                    </tr>
                    </thead>
                </table>
                <div className={style.ScrollTable}>
                    <table className={style.TablePermissions}>
                        <tbody>
                        {modules.length > 0 && modules.sort(Utils.OrderBy('name')).map((tr: any, tr_idx: number) =>
                            <tr key={tr_idx} style={{backgroundColor: tr.isroute ? '#c9d3e650' : '', opacity: checkValidate(tr) ? 1 : 0.3}}>
                                <td><i className={`fal fa-${tr.icon}`}/></td>
                                <td onClick={() => checkAllPermission(tr)}>{tr?.name}</td>
                                {permissions.length > 0 && permissions.map((td: any, td_idx: number) =>
                                    <td key={td_idx}>
                                        {<div onClick={() => addPermission(tr, td)}>
                                            <i
                                                className={`fal fa-${checkPermissionInProfile(tr, td) ? 'check' : 'times'}`}
                                                style={{color: checkPermissionInProfile(tr, td) ? 'var(--color-green)' : 'var(--color-red)'}}/>
                                        </div>}
                                    </td>)}
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

export default Permissions;
