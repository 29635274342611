/**
 * ASSESSMENT PAGE INDEX
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../layout';
import {List} from '../../components/Crud';
import {Select} from "../../components/Fields";


/**
 * COMPONENT PAGE ASSESSMENT
 */
class Page extends List {

    addFilter = () => {
        const {filter, extra}: any = this.state;
        return (<>
            <Select value={filter?.status} name='status' label_option='Status' items={extra?.status} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'status', value: ''})}/>
            <Select value={filter?.group} name='group' label_option='Categoria' items={extra?.group} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'status', value: ''})}/>
        </>);
    }

    render() {
        return (<Layout>
            {super.render()}
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);