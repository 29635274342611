/**
 * MODULE SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import AbstractService from '../../library/AbstractService';

export default class ModuleService extends AbstractService {

    name= 'module';

    /**
     * GET ALL FIELDS MODEL
     * @returns 
     */
    async get_model(name: any) {
        return await this.getAdapter().get(`${this.getUrl()}/get_model`, { params: {name} });
    }

    /**
     * SAVE MODEL
     * @returns 
     */
    async save_model(data: any) {
        return await this.getAdapter().post(`${this.getUrl()}/save_model`, data);
    }
}