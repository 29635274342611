/**
 * STUDENTS PAGE VIEW
 */

import React from 'react';
import {View} from '../../components/Crud';
import style from "./style.module.scss";
import Logo from "../../components/Logo";
import Loader from "../../components/Loader";

/**
 * COMPONENT VIEW STUDENTS
 */
class ReportPrint extends View {

    report: any = React.createRef();

    componentDidMount() {
        super.componentDidMount();
        this.setState({loader: false});
    }

    calculate_result = (subject: any) => {
        try {
            const {extra}: any = this.props;
            const {reports}: any = extra;
            const total: any = [];
            Object.keys(reports).map((f: any) => {
                if (f.indexOf(subject) !== -1 && reports[f] !== "") {
                    total.push(parseFloat(reports[f].replace(',', '.')));
                }
                return f;
            });
            let note: any = total.reduce((a: any, b: any) => (a + b)) / total.length;
            note = note.toFixed(2);
            return note;
        } catch (error: any) {
            return '';
        }
    }

    sum_total_lack = (s: any) => {
        const {extra}: any = this.props;
        const {frequencies}: any = extra;
        if (!frequencies) return 0;
        return frequencies.filter((f: any) => f?.subject === s?._id && !f?.presence).length || '-';
    }

    check_value = (bim: any) => {
        const {extra}: any = this.props;
        const {reports}: any = extra;
        try {
            return reports[bim];
        } catch (error: any) {
            return '';
        }
    }

    render() {
        const {loading, document, extra}: any = this.props;
        const {subjects, bimistres}: any = extra;
        const {student_data}: any = document;
        return (<>
            {!document?._id && <Loader/>}
            {document?._id && <div className={style.Printer} ref={this.report}>

                <section>
                    <br/>
                    <br/>
                    <h1>BOLETIM ESCOLAR</h1>
                </section>
                <hgroup>
                    <article>
                        <h1>{student_data?._unit?.name}</h1>
                        <div><b>CNPJ:</b> {student_data?._unit?.cnpj}</div>
                        <div><b>Telefones:</b> {student_data?._unit?.contact}</div>
                    </article>
                    <picture>
                        <Logo brand={true}/>
                    </picture>
                </hgroup>

                <section>
                    <h1>{student_data?.name}</h1>
                    <div><b>Série:</b> {student_data?._classe?.name} | <b>Turno:</b> {student_data?._period?.name} | <b>Ano:</b> {document?.school_year}</div>
                </section>
                {!loading && <table>
                    <thead>
                    <tr>
                        <th rowSpan={2}>Componentes curriculares</th>
                        <th colSpan={4}>Notas</th>
                        <th rowSpan={2}>Total de faltas</th>
                        <th rowSpan={2}>Resultado final</th>
                    </tr>
                    {bimistres && <tr>
                        {bimistres.map((b: any, i: any) => <th key={i}>{b?.name}</th>)}
                    </tr>}
                    </thead>
                    {subjects && subjects.length > 0 && <tbody>
                    {subjects.map((subject: any, s: any) => <tr key={s}>
                        <td>
                            {subject?.name}
                        </td>

                        {bimistres && bimistres.map((b: any, i: any) => <td key={i}>
                            {this.check_value(`bim_${(b?.id)}_${subject?._id}`) || 0}
                        </td>)}

                        <td>{this.sum_total_lack(subject)}</td>
                        <td>{this.calculate_result(subject?._id)}</td>

                    </tr>)}

                    </tbody>}
                </table>}


            </div>}
        </>);
    }
}


export default ReportPrint;