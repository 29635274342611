import React, {Component} from "react";
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../store/actions/adminActionCreators";
import * as authActionCreators from "../store/actions/authActionCreators";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import styles from './style.module.scss';
import {Utils} from "../api/utils";

class Menu extends Component<any, any> {

    state = {
        store: {},
        total_messages: 0,
        entity: {},
    }

    render() {
        const {menus, permissions} = this.props;
        const {total_messages} = this.state;
        return (<div className={styles.Menu}>
            {menus && <ul>
                {menus.sort(Utils.OrderBy('name')).filter((f: any) => !f.parent && permissions[f?.to]?.canList).map((cat: any, idx: any) => <li key={idx}>
                    <div className={styles.TitleMenu}><span>{cat?.name}</span></div>
                    {<ul>
                        {menus.sort(Utils.OrderBy('name')).filter((p: any) => p.parent === cat._id && permissions[p?.to]?.canList).map((item: any, indice: any) => <li key={indice} id={item?.to}>
                            <div title={item?.name}>
                                <NavLink to={`/${item?.to}`}>
                                    <i className={`fal fa-${item?.icon} fa-lg`}/>
                                    <span>{item?.name}</span>
                                </NavLink>
                            </div>
                            {item?.to === 'chat' && total_messages > 0 && <label>{total_messages}</label>}
                        </li>)}
                    </ul>}
                </li>)}
            </ul>}
        </div>);
    }
}

const mapStateToProps = (state: any) => ({
        ...state.admin,
        ...state.auth
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators(
    {
        ...adminActionCreators,
        ...authActionCreators
    }
    , dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Menu);