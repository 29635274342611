/**
 * AUDIT PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-5-8
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {View} from '../../../components/Crud';
import styles from './style.module.scss';
import {Button} from '../../../components/Fields';
import {Utils} from "../../../api/utils";

/**
 * COMPONENT VIEW AUDIT
 */
class Page extends View {

    fields: any = [];

    callBack = () => {
        const {document}: any = this.state;
        Promise.all([this.getModel(Utils.ucFirst(document.module))]).then((response: any) => {
            const [fields]: any = response;
            this.setState({fields, loading: false});
        })
    }

    render() {
        const {document, fields, loading}: any = this.state;
        return (<><Layout>
            <>
                {(fields && !loading && !this.getPermissions('view')) && <section className={styles.NoList}>
                <span>
                    NOT ACCESS
                </span>
                </section>}

                {loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
                </div>}

                {!loading && fields && <div className={styles.viewContain}>
                    <section>
                        {this.getTitle('Visualizando')}
                        <div group-button='true'>
                            <Button label='Voltar' icon='arrow-left' action={() => this.cancel()}/>
                        </div>
                    </section>
                    {document?.user && <div><b>Usuário:</b> {document?.user}</div>}
                    {document?.module && <div><b>Module:</b> {document?.module}</div>}
                    {document?.method && <div><b>Método:</b> {document?.method}</div>}
                    {document?.createAt && <div><b>Data:</b> {Utils.formatDateString(document?.createAt, true)}</div>}
                    <strong>Registro da auditória</strong>
                    <hgroup>
                        <pre dangerouslySetInnerHTML={{__html: Utils.pretty(JSON.parse(document?.register))}}/>
                    </hgroup>
                    <article>
                        <Button label={'Inserir'}/>
                        <Button label={'Resetar'}/>
                    </article>
                </div>}
            </>
        </Layout></>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
