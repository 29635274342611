/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import style from './style.module.scss';

/**
 * COMPONENT PAGE
 */
class Page extends Component<any, any> {

    render() {
        return (<>
                <section className={style.Notfound}>
                    <span>404</span> <p>O caminho solicitado não foi encontrado</p>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
