/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {Storage, Utils} from '../../api/utils';
import {Service} from '../../api';
import styles from './style.module.scss';
import {Button} from "../Fields";


/**
 * CLASS LIST ABSTRACT
 */
class State {
    fields?: any;
    model?: any;
    search?: string;
    module?: string;
    document?: any;
    data_view?: any;
    module_field?: any;
    modules?: any;
    modules_field?: any;
    classifications?: any;
    original_data?: any;
    loading?: any;
    request?: any;
    store?: any;
    importing?: any;
    printer?: any;
    periods?: any;
    days?: any;
    extra?: any;
    loader?: any;
};

export class Props {
    page?: any;
    location?: any;
    menus?: any;
    totalPages?: any;
    history?: any;
    fields?: any;
    Alert?: any;
    match?: any;
    setModule?: any;
    module?: any;
    setFields?: any;
    store?: any;
    periods?: any;
    days?: any;
    permissions?: any;
};

export default class Form extends Component<Props, State> {

    /**
     * SET STATES
     */
    state = {
        model: [],
        module: '',
        search: '',
        data_view: {},
        document: {},
        extra: {},
        modules: [],
        modules_field: [],
        original_data: [],
        loading: true,
        request: false,
        store: {},
        importing: '',
        printer: false,
        periods: [],
        days: [],
        tab: 0
    }

    /**
     * ONLOAD COMPONENT
     */
    componentDidMount() {
        try {
            const module: any = Utils.ucFirst(this.props.location.pathname.split('/')[1]);
            Promise.all([this.getModel(module)]).then(async (response: any) => {
                let [model]: any = response;
                this.props.setModule(module);
                this.setState({model, module});
                if (this.props.match.params.id) {
                    Promise.all([this.getService().get(this.props.match.params.id)]).then((response: any) => {
                        const [result]: any = response;
                        const {data}: any = result;
                        this.setState({document: data.documents, extra: data.extra, loading: false}, () => {
                            this.callBack();
                        });
                    });
                }
            });
        } catch (error: any) {
            this.exceptError(error);
        }
    }

    /**
     * CAPTURE ERROR
     * @param error
     */
    exceptError(error: any) {
        const {Alert}: any = this.props;
        Alert({
            title: 'Error debug view',
            debug: true,
            autoSize: '80%',
            message: error.message
        });
        this.setState({loading: false});
    }

    /**
     * GET MODEL DEFAULT
     */
    async getModel(module: any = null) {
        const guid: any = Storage.exists('entity', '');
        const [client]: any = guid;
        const core_control: any = ['api', 'audit', 'dashboard', 'entities', 'entity', 'login', 'module', 'parameter', 'plugins', 'profile', 'register', 'settings', 'user'];
        const path_core: any = core_control.includes(module.toLowerCase());
        let model: any = (await import(`../../api/models/${path_core ? 'core/' : ''}${module}`)).default;
        try {
            model = (await import(`../../api/models/${path_core ? 'core/' : ''}${client}/${module}`)).default;
        } catch (error: any) {
        }
        return model;
    }

    /**
     * GET DATA BEFORE LOAD
     */
    callBack = () => {
    }

    /**
     * GET SERVICE MODULE
     * @returns
     */
    getService = () => {
        if (this.state.module) {
            const classService: any = Service;
            return classService[`get${this.state.module}Service`]();
        }
    }

    /**
     * GET TITLE MODULE
     */
    getTitle = (prefix: any, icon: any = null, name: any = true) => {
        const {menus} = this.props;
        const {module} = this.state;
        if (module) {
            const document: any = this.state.document;
            if (menus.length <= 0) return '';
            const currentModule = menus.find((m: any) => m?.to === module.toLocaleLowerCase());

            /**
             * RENDER NAME TITLE
             */
            return (<h1>
                <i className={`fal fa-${icon === null ? currentModule?.icon : icon}`}/> {prefix && prefix} {name ? currentModule?.name : ''} {document?.name}
            </h1>);
        }
    }

    /**
     * CANCEL FORM
     */
    cancel = () => {
        this.props.history.goBack();
    }

    /**
     * SAVE FORM
     */
    save = async () => {
        const {Alert} = this.props;
        const document: any = this.state.document;
        const result: any = await this.getService().save(document);
        if (result.status === 200) {
            Alert({
                type: 'success',
                title: 'Sucesso',
                message: 'Registro cadastrado com sucesso',
            });
        } else {
            if (result.data.message) {
                Alert({
                    type: 'error',
                    message: result.data.message
                });
            } else {
                let errors = '';
                result.data.writeErrors.map((error: any) => {
                    errors += `${error.errmsg}<br/>`;
                    return error;
                });
                Alert({
                    type: 'error',
                    message: errors
                });
            }
        }
    }

    /**
     * FORMAT Calculation MONEY
     * @param value
     * @returns
     */
    formatValue = (value: any) => {
        return (parseFloat(value)).toLocaleString('pt-BR',
            {
                style: 'currency',
                currency: 'BRL'
            }
        );
    }

    /**
     * GET PERMISSIONS
     * @param permission
     * @returns
     */
    getPermissions = (permission: any) => {
        if (this.props?.permissions) {
            const moduleName: any = this.state.module.toLocaleLowerCase();
            let permissionName = `can${Utils.ucFirst(permission)}`;
            if (permission === 'admin') {
                permissionName = `is${Utils.ucFirst(permission)}`;
            }
            if (this.props?.permissions[moduleName]) {
                return this.props?.permissions[moduleName][permissionName] || false;
            } else {
                return false;
            }
        }
    }

    render() {
        const item: any = this.props.children;
        const {loading}: any = this.state;
        return (<>

            {loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
            </div>}

            {!loading && !this.getPermissions('view') && <div className={styles.MenuEmpty}>
                NOT ACCESS
                <label>Seus previlégios de usuário não lhe dão acesso para visualizar</label>
                <Button label='VOLTAR' data-name="save" icon='times' action={() => this.cancel()}/>
            </div>}

            {!loading && this.getPermissions('view') && <div className={styles.ViewContain}>
                {this.props.children && React.cloneElement(item, {...this.props, ...this.state})}
                {!this.props.children && <>
                    <section>
                        {this.getTitle('Configurando')}
                    </section>
                    <section data-view={this.props.module}>

                    </section>
                </>}
            </div>}
        </>);
    }
}