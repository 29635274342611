/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "./store/actions/adminActionCreators";
import * as authActionCreators from "./store/actions/authActionCreators";
import {connect} from "react-redux";
import Alert from './components/Alert';

class AlertService extends Component<any, any> {
    render() {
        return (<>
            {this.props.alert && <Alert {...this.props}/>}
        </>);
    }
}

const mapStateToProps = (state:any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch:any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AlertService);
