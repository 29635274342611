import React, {createRef, Component} from "react";
import {Button} from "../Fields";
import styles from './style.module.scss'


/**
 * COMPONENT ALERT
 */
export default class Alert extends Component<any, any> {

    /**
     * CREATE REFS HTML
     */
    private drop = createRef<HTMLDivElement>();
    private box = createRef<HTMLDivElement>();

    /**
     * START COMPONENT
     */
    componentDidMount() {
        setTimeout(() => {
            document.documentElement.style.setProperty('--background-modal', 'rgba(0,0,0,.8)');
            document.documentElement.style.setProperty('--scale-box', '1');
            document.documentElement.style.setProperty('--opacity-box', '1');
            document.body.style.overflow = 'hidden';
        }, 200);
    }

    /**
     * CLOSE MODAL
     */
    close = () => {
        const {Alert, alert} = this.props;
        document.documentElement.style.setProperty('--background-modal', 'rgba(0,0,0,0)');
        document.documentElement.style.setProperty('--scale-box', '0');
        document.documentElement.style.setProperty('--opacity-box', '0');
        document.body.style.overflow = 'auto';
        setTimeout(() => {
            Alert(false);
            if (alert.callback) {
                alert.callback(this);
            }
        }, 400);
    }

    /**
     * GET TYPE MODAL
     * @param type
     */
    getType = (type: string) => {
        switch (type) {
            case 'confirm':
                return 'question-square';
            case 'success':
                return 'check-circle';
            case 'error':
                return 'times-circle';
            default:
                return 'exclamation-square';
        }
    }

    /**
     * CALLBACK BUTTONS
     * @param btn
     */
    callback = (btn: any) => {
        if (btn.callback) {
            btn.callback(this);
        } else {
            this.close();
        }
    }

    render() {
        return (<div className={styles.alertContainer} ref={this.drop}>
            <div className={styles.alertBox} style={{maxWidth: (this.props.alert.autoSize ? this.props.alert.autoSize : 380)}} ref={this.box}>
                <div className={styles.alertBody}>
                    {!this.props.alert.component && <i className={`fal fa-${this.getType(this.props.alert.type) || 'exclamation-square'} fa-2x`} data-type={this.props.alert.type}/>}
                    {!!this.props.alert.component && this.props.alert.title && <h1 data-title="true">{this.props.alert.title || 'Ops'}</h1>}
                    {!!this.props.alert.title && !this.props.alert.component && <strong>{this.props.alert.title || 'Ops'}</strong>}

                    {!!this.props.alert.message && !this.props.alert.debug && <span dangerouslySetInnerHTML={{__html: this.props.alert.message}}/>}
                    {!!this.props.alert.message && this.props.alert.debug && <code>{JSON.stringify(this.props.alert.message, null, 2)}</code>}

                    {this.props.alert.component && <>
                        {this.props.alert.component && React.cloneElement(this.props.alert.component, {...this.props, ...this.state})}
                    </>}
                </div>
                <div className={styles.alertFoot}>
                    {!this.props.alert.buttons && <Button label='OK' action={() => this.close()}/>}
                    {!!this.props.alert.buttons && Object.values(this.props.alert.buttons).map((btn: any, idx: number) =>
                        <Button label={btn.label} key={idx} action={() => btn.close ? this.close() : this.callback(btn)}/>
                    )}
                </div>
            </div>
        </div>);
    }
}
