/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import style from './style.module.scss';
import {Utils} from "../../api/utils";

/**
 * PROPS TYPE
 */
export class Props {
    svg?: any;
    direction?: any;
    color?: any;
    name?: any;
    entity?: any;
    dataimage?: any = false;
    brand?: any = false;
}

/**
 * COMPONENT LOGO
 */
class Logo extends Component<Props, any> {

    render() {
        const {entity}: any = this.props;
        let {name, brand}: any = this.props;
        name = name === undefined ? 'logo' : name;
        if (!entity[name]) return null;
        return (<>
            {brand === true && <>
                {!entity[name] && <h1 className={style.Title}>{entity?.name}</h1>}
                {entity[name] && <img className={style.Brand} src={Utils.showImage('entities', entity, 'logo')} alt={entity?.name}/>}
            </>}

            {[false, undefined].includes(brand) && <div className={style.Brand}>
                <svg viewBox="0 0 198 120">
                    <g id="primary">
                        <path className="cls-1"
                              d="m176.74,57.58c-9.21,0-16.33,4.79-19.27,12.39-2.78-7.44-9.8-12.39-19.02-12.39-9.87,0-17.37,5.67-19.81,14.08v-13.07h-9.24v19.96c0,7.3-3.88,10.8-9.71,10.8s-9.71-3.5-9.71-10.8v-19.96h-9.24v19.96c0,12.9,7.61,19.42,18.95,19.42,10.06,0,17.17-5.2,18.66-15.32,2.04,9.08,9.76,15.32,20.11,15.32,9.07,0,16.01-4.78,18.88-12.02,2.99,7.98,10.52,12.02,17.77,12.02,6.06,0,11.26-2.87,13.59-8.54v7.54h8.47v-18.88c0-12.35-8.39-20.51-20.43-20.51Zm-27.72,25.01c-1.63,4.12-5.52,6.76-10.57,6.76-6.76,0-11.34-4.82-11.34-11.57s4.58-11.58,11.34-11.58c5.05,0,8.93,2.64,10.57,6.76h7.56c-.37,1.64-.58,3.37-.58,5.21,0,1.57.14,3.04.41,4.43h-7.39Z"/>
                        <path className="cls-1"
                              d="m98.42,53.66c1.18-.45,2.09-.79,3.05-1.03,2.93-.73,5.91-1.04,8.92-.85,2.18.13,4.35.4,6.49.9,2.44.57,4.87,1.19,7.26,1.95,1.7.55,1.42.86,2.08-.96,4.14-11.51,8.26-23.02,12.39-34.53.37-1.04.37-1.05-.7-1.44-3.35-1.21-6.8-2.02-10.31-2.59-2.74-.44-5.5-.52-8.27-.19-2.16.26-4.24.79-6.3,1.5-1.11.38-1.6.95-1.96,1.97-3.97,11.14-7.97,22.26-11.96,33.39-.19.53-.38,1.06-.68,1.88Z"/>
                        <path className="cls-1"
                              d="m89.86,44.86c2.74,2.22,5.13,4.72,6.89,7.79.06.1.12.21.42.17.2-.55.42-1.18.65-1.81,3.94-10.98,7.87-21.97,11.83-32.95.32-.89.31-1.56-.16-2.45-1.84-3.49-4.43-6.33-7.52-8.67-3.8-2.87-8.02-5.03-12.5-6.64-1.4-.5-1.2-.48-1.66.8-4.07,11.32-8.13,22.65-12.19,33.98-.12.34-.25.69-.36,1.03-.25.83-.25.82.53,1.15,1.18.49,2.35.98,3.53,1.48,0,0-.02,0-.02,0,.18.21.4.3.68.28-.07-.09-.15-.16-.24-.21.17.03.31.12.43.27,1.45.51,2.74,1.34,4.08,2.04,1.99,1.04,3.86,2.31,5.62,3.74Z"/>
                        <path className="cls-1"
                              d="m70.79,64.18c-2.72-4.27-7.3-6.6-12.82-6.6-7.48,0-15.26,4.31-18.04,12.79-3.95-10.63-14.46-15.75-25.72-11.9-11.47,3.92-16.78,14.77-13.01,25.8,3.77,11.02,14.62,16.34,26.09,12.42,6.37-2.18,10.8-6.45,12.82-11.7,1.34,3.81,3.72,6.95,6.87,9.18.08-1.65.57-3.18,1.5-4.57,1.02-1.52,2.39-2.6,4.07-3.31.57-.24,1.17-.37,1.76-.51.07-.02.12.02.18.06.71.51,1.47.92,2.31,1.18.49.15,1,.26,1.51.31.57.05,1.15.05,1.72.03,1.58-.06,3.01-.54,4.29-1.48.14-.1.29-.07.43-.04,1.43.31,2.74.89,3.88,1.83,1.36,1.11,2.31,2.5,2.86,4.16.19.58.32,1.17.36,1.77.01.16.04.33.04.49,0,.09,0,.18.02.26,5.06-3.51,8.12-9.41,8.12-16.9v-35.66h-9.24v22.37Zm-38.8,18.17c-1.03,2.98-3.52,5.06-7.42,6.4-5.15,1.76-10.14.35-13.13-4.54l27.55-9.43c-.09.84-.14,1.7-.14,2.6s.05,1.73.13,2.57l-7,2.4Zm36.57-10.33c-.61.28-1.23.54-1.85.81-.32.14-.64.28-.96.41-.08.19-.04.38-.04.58,0,.68-.03,1.37.02,2.05.19.53.29,1.07.35,1.62.13,1.16-.08,2.26-.47,3.34-.34.96-.87,1.81-1.54,2.58-.52.59-1.11,1.1-1.78,1.5-.63.38-1.31.62-2.03.74-.24.04-.47.07-.71.08-.98.04-1.89-.24-2.75-.7-1.11-.6-1.97-1.46-2.66-2.5-.58-.88-.99-1.84-1.22-2.86-.15-.64-.19-1.3-.13-1.97,0,0,.01-.01.01-.01,0-.01-.01-.02-.02-.03,0-.42.09-.84.21-1.24.11-.39.2-.77.19-1.18-.02-.6,0-1.21,0-1.82,0-.1,0-.17-.11-.21-.59-.25-1.18-.51-1.77-.76-.06-.03-.2-.13-.22-.02-.01.08-.03.26.13.32.25.09.32.29.31.54,0,.13-.04.25-.14.29-.26.1-.2.32-.17.49.06.34.04.69.08,1.03.06.59.06,1.19.14,1.79.02.18.02.37.04.56.06.51.08,1.01.1,1.52.03.59-.24,1.11-.41,1.65-.02.05-.04.09-.11.1-.07,0-.09-.05-.11-.1-.29-.76-.55-1.52-.38-2.35.05-.22.02-.46.05-.69.05-.56.04-1.12.1-1.68.08-.67.07-1.35.17-2.02.01-.09,0-.16-.09-.22-.36-.25-.34-.66.05-.88.15-.08.13-.19.14-.31,0-.13-.07-.17-.18-.21-.2-.08-.4-.17-.59-.28-.32-.2-.33-.43-.02-.65.27-.19.59-.28.89-.41.88-.39,1.76-.77,2.65-1.15.95-.41,1.9-.82,2.86-1.23.61-.26,1.22-.53,1.82-.8.46-.2.94-.22,1.42-.16.69.08,1.27.44,1.9.7.93.38,1.84.79,2.76,1.19.87.38,1.74.75,2.61,1.13.5.22,1,.44,1.49.66.17.08.33.15.33.4,0,.24-.17.33-.34.4Z"/>
                    </g>

                    <g id="secundary">
                        <path id="orela_2" className="cls-2"
                              d="m129.63,60.29c4.09-11.39,8.18-22.79,12.27-34.18.16-.45.28-.91.52-1.32-.93-.32-1.79-.8-2.69-1.19-.87-.38-.89-.36-1.2.51-3.95,11.02-7.91,22.04-11.87,33.06-3.8-1.26-7.59-2.57-11.56-3.2-2.82-.45-5.64-.76-8.5-.48-1.59.16-3.2.27-4.72.84-.17-.09-.31-.05-.41.11h0c-.07.03-.2.09-.2.1.04.17.16.14.28.09.14.05.28.1.4-.03.72-.05,1.44.23,2.1.2,2.12-.08,4.14.51,6.2.77,2.16.28,4.27.84,6.38,1.44,3.75,1.07,7.36,2.46,10.86,4.14,1.55.74,1.53.78,2.12-.86Z"/>
                        <path id="orela_1" className="cls-2"
                              d="m85.91,46.78c2.62,1.54,5.22,3.12,7.87,4.96-.17-.9-.76-1.19-1.1-1.66-1.58-2.16-3.7-3.77-5.86-5.24-1.48-1.01-2.89-2.25-4.68-2.77-1.01-1.08-2.45-1.37-3.7-1.98-1.7-.82-3.53-1.4-5.3-2.09-.06-.08-.12-.16-.18-.24.14-.46.27-.93.43-1.39,3.7-10.33,7.41-20.66,11.12-31,.11-.3.21-.61.33-.91.19-.49-.05-.74-.77-.78-.68-.04-1.36-.2-1.96-.51-.49-.25-.85-.23-1.01.2-.08.23-.18.45-.26.68-4.19,11.66-8.38,23.33-12.57,34.99-.1.29-.34.57-.15.97,3.09.68,6.15,1.57,9.14,2.71,2.98,1.14,5.88,2.42,8.65,4.05Z"/>
                        <path className="cls-2"
                              d="m136.52,104.84c.18,0,.33.11.56.23.26.13.48.23.73.23.56,0,.83-.41.82-1.16h-.56c-.02.38-.13.47-.32.47-.18,0-.37-.11-.56-.22-.25-.13-.46-.24-.72-.24-.52,0-.83.49-.82,1.22h.56c.04-.32.12-.53.31-.53Z"/>
                        <path className="cls-2"
                              d="m75.93,105.86l-2.75,8.08h1.08l.84-2.54h2.87l.86,2.54h1.12l-2.76-8.08h-1.26Zm-.61,4.73l.79-2.34c.16-.49.29-.98.41-1.46h.02c.12.47.24.95.42,1.48l.79,2.33h-2.43Z"/>
                        <path className="cls-2"
                              d="m83.87,105.8c-.84,0-1.5.07-2,.16v7.99h1.04v-3.24c.24.06.53.07.84.07,1.02,0,1.92-.3,2.47-.89.41-.42.62-1,.62-1.73s-.26-1.31-.71-1.69c-.48-.43-1.23-.67-2.27-.67Zm-.1,4.14c-.35,0-.64-.02-.86-.08v-3.14c.18-.05.53-.08.98-.08,1.15,0,1.91.52,1.91,1.58s-.77,1.73-2.03,1.73Z"/>
                        <path className="cls-2"
                              d="m90.99,105.8c-.84,0-1.5.07-2,.16v7.99h1.04v-3.24c.24.06.53.07.84.07,1.02,0,1.92-.3,2.47-.89.41-.42.62-1,.62-1.73s-.26-1.31-.71-1.69c-.48-.43-1.24-.67-2.27-.67Zm-.1,4.14c-.35,0-.64-.02-.86-.08v-3.14c.18-.05.53-.08.98-.08,1.15,0,1.91.52,1.91,1.58s-.77,1.73-2.03,1.73Z"/>
                        <path className="cls-2"
                              d="m100.16,109.55c-.7,0-1.27.55-1.26,1.26,0,.71.56,1.25,1.26,1.25s1.26-.55,1.26-1.26-.56-1.25-1.26-1.25Z"/>
                        <path className="cls-2"
                              d="m110.26,110.53h1.64v2.41c-.24.12-.71.22-1.39.22-1.88,0-3.12-1.21-3.12-3.26s1.28-3.25,3.25-3.25c.82,0,1.36.16,1.79.35l.25-.85c-.35-.17-1.08-.37-2.01-.37-2.71,0-4.37,1.76-4.38,4.17,0,1.26.43,2.34,1.13,3.01.79.76,1.8,1.07,3.02,1.07,1.09,0,2.02-.28,2.48-.44v-3.89h-2.66v.84Z"/>
                        <polygon className="cls-2"
                                 points="116.3 110.15 119.44 110.15 119.44 109.29 116.3 109.29 116.3 106.73 119.62 106.73 119.62 105.86 115.25 105.86 115.25 113.94 119.8 113.94 119.8 113.06 116.3 113.06 116.3 110.15"/>
                        <path className="cls-2"
                              d="m124.31,109.36c-1.12-.43-1.61-.8-1.61-1.56,0-.55.42-1.21,1.52-1.21.73,0,1.27.24,1.54.38l.29-.85c-.36-.2-.96-.4-1.79-.4-1.57,0-2.61.94-2.61,2.2,0,1.14.82,1.82,2.13,2.29,1.09.42,1.52.85,1.52,1.61,0,.82-.62,1.38-1.69,1.38-.72,0-1.4-.24-1.87-.53l-.26.88c.43.29,1.28.52,2.06.52,1.91,0,2.83-1.08,2.83-2.33s-.7-1.85-2.06-2.37Z"/>
                        <polygon className="cls-2"
                                 points="127.61 106.74 130.07 106.74 130.07 113.94 131.13 113.94 131.13 106.74 133.6 106.74 133.6 105.86 127.61 105.86 127.61 106.74"/>
                        <path className="cls-2"
                              d="m136.47,105.86l-2.75,8.08h1.08l.84-2.54h2.87l.86,2.54h1.12l-2.76-8.08h-1.26Zm-.61,4.73l.79-2.34c.16-.49.29-.98.41-1.46h.02c.12.47.24.95.42,1.48l.79,2.33h-2.43Z"/>
                        <path className="cls-2"
                              d="m145.5,105.72c-2.16,0-3.75,1.68-3.75,4.25s1.5,4.1,3.63,4.1,3.75-1.47,3.75-4.26c0-2.4-1.43-4.09-3.63-4.09Zm-.06,7.5c-1.64,0-2.58-1.55-2.58-3.27s.86-3.37,2.59-3.37,2.58,1.68,2.58,3.29c0,1.83-.93,3.36-2.59,3.36Z"/>
                        <path className="cls-2"
                              d="m156.72,105.8c-.83,0-1.58.07-2.22.17v7.95c.53.06,1.15.1,1.9.1,1.57,0,2.82-.41,3.57-1.16.75-.74,1.16-1.82,1.16-3.14s-.42-2.24-1.14-2.9c-.71-.66-1.77-1.01-3.27-1.01Zm-.12,7.38c-.41,0-.79-.01-1.06-.06v-6.38c.28-.06.68-.11,1.22-.11,2.21,0,3.29,1.21,3.27,3.12,0,2.18-1.21,3.43-3.44,3.43Z"/>
                        <rect className="cls-2" x="163.23" y="105.86" width="1.04" height="8.08"/>
                        <path className="cls-2"
                              d="m170.32,110.53h1.64v2.41c-.24.12-.71.22-1.39.22-1.88,0-3.12-1.21-3.12-3.26s1.28-3.25,3.25-3.25c.82,0,1.36.16,1.79.35l.25-.85c-.35-.17-1.08-.37-2.01-.37-2.71,0-4.37,1.76-4.38,4.17,0,1.26.43,2.34,1.13,3.01.79.76,1.8,1.07,3.02,1.07,1.09,0,2.02-.28,2.48-.44v-3.89h-2.66v.84Z"/>
                        <rect className="cls-2" x="175.31" y="105.86" width="1.04" height="8.08"/>
                        <polygon className="cls-2"
                                 points="177.99 106.74 180.45 106.74 180.45 113.94 181.5 113.94 181.5 106.74 183.97 106.74 183.97 105.86 177.99 105.86 177.99 106.74"/>
                        <path className="cls-2"
                              d="m186.84,105.86l-2.75,8.08h1.08l.84-2.54h2.87l.86,2.54h1.12l-2.76-8.08h-1.26Zm-.61,4.73l.79-2.34c.16-.49.29-.98.41-1.46h.02c.12.47.24.95.42,1.48l.79,2.33h-2.43Z"/>
                        <polygon className="cls-2"
                                 points="193.83 113.06 193.83 105.86 192.78 105.86 192.78 113.94 197.28 113.94 197.28 113.06 193.83 113.06"/>
                    </g>

                </svg>
            </div>}

        </>);
    }
}

const mapStateToProps = (state: any) => ({
        ...state.admin,
        ...state.auth
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
        ...adminActionCreators,
        ...authActionCreators,
    }
    , dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Logo);