export default class Remote {

    static set(name: any, value: any) {
        sessionStorage.setItem(name, JSON.stringify(value));
    }

    static get(name: any) {
        const _name: any = sessionStorage.getItem(name);
        return JSON.parse(_name);
    }

    static del(name: any) {
        sessionStorage.removeItem(name);
    }

    static exists(name: any, cb:any) {
        return Remote.get(name) || cb;
    }

    static clear() {
        sessionStorage.clear();
    }
}