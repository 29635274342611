/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'video',
    }

    field: any = createRef();

    componentDidMount() {
    }

    get_id = () => {
        //@ts-ignore
        return this.props?.value.split('=')[1] || null
    }

    remove_video = () => {
        let {fields}: any = this.props;
        fields[this.props.name] = '';
        this.props.setFields(fields);
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <div>

                            {this.props?.value &&
                                <hgroup>
                                    <iframe
                                        src={`https://www.youtube.com/embed/${this.get_id()}`}
                                        title="Player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>

                                </hgroup>}
                            {!this.props?.value && <article>
                                <i className='fa fa-times' onClick={() => this.remove_video()}/>
                                <span>Cole aqui a url video do Youtube para adicionar</span>
                                <input type='text'
                                       ref={this.field}
                                       name={this.props?.name}
                                       onChange={this.props.change}
                                       autoComplete='false'
                                       value={this.props?.value || null}
                                       readOnly={this.props?.readonly || false}
                                       required={this.props?.required}
                                       disabled={this.props?.disabled}
                                       data-theme={this.props?.theme ? this.props?.theme : 'default'}
                                       placeholder='Ex: https://www.youtube.com/watch?v=t3m-hkbQpQA'
                                       onKeyDown={this.props?.keydown}
                                />
                            </article>}
                        </div>
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);