/**
 * PLUGINS SERVICE
 * Author: Thiago Silva
 * CreateAt: 26/11/2021
 */

import AbstractService from '../../library/AbstractService';

export default class PluginsService extends AbstractService {
    name= 'plugins';
}