/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component, createRef} from 'react';
import {Service} from '../../api';
import Utils from '../../api/utils/Utils';
import {Button, Input} from '../Fields';
import {Remote, Storage} from "../../api/utils";
import styles from './style.module.scss';
import ImageEmpty from "../../assets/empty.svg";

/**
 * CLASS LIST ABSTRACT
 */

export class Props {
    page?: any;
    location?: any;
    menus?: any;
    totalPages?: any;
    history?: any;
    setFields?: any;
    setModule?: any;
    permissions?: any;
    setAddress?: any;
}

export default class List extends Component<Props, any> {

    /**
     * SET STATES
     */
    state = {
        model: [],
        documents: [],
        module: '',
        page: 1,
        fields: {},
        extra: {},
        totalPages: 1,
        actions: [],
        loading: true,
        search: '',
        date_start: '',
        date_end: '',
        sort_dir: false,
        sort: {},
        load: true,
        ids: [],
        filter: {},
        searching: true,
        imageEmpty: true,
        query: true,
        text: '',
        editing: false,
        row_selected: null,
        scrolling: false,
        actEdit: 'edit',
        actTrash: 'trash',
        actView: 'eye',
        actClone: 'clone',
        empty_name: 'empty',
        empty_label: false,
    }
    grid: any = createRef<any>();
    head: any = createRef<any>();
    main: any = createRef<any>();
    actions: any = createRef<any>();
    min_size_screen: any = 1000;
    rows = [];

    /**
     * MOUNBT COMPONENT
     */
    componentDidMount() {
        try {
            let module: any = Utils.ucFirst(window.location.pathname.split('/')[1]);
            Promise.all([this.getModel(module)]).then(async (response: any) => {
                let [model]: any = response;
                const page: any = Storage.exists(`page_${module.toLowerCase()}`, 1);
                const filter: any = Remote.exists(`filter_${module.toLowerCase()}`, {});

                let sort: any = [];
                const get_first_sort = model.find((m: any) => m.first_sort);
                sort[get_first_sort?.id || 'name'] = 1;

                model = model.filter((m: any) => m.id !== "actions");

                this.setState({model, page, module, filter, sort, imageEmpty: ImageEmpty});
                this.props.setModule(module);
                if (this.state.load) {
                    await this.getData(page);
                } else {
                    this.setState({loading: false, imageEmpty: ImageEmpty});
                }
            });

            window.addEventListener('resize', () => this.checkScrolling());

        } catch (error: any) {
            this.exceptError(error);
        }
    }

    checkScrolling = () => {
        let scrolling: any = document.body.clientHeight <= 870;
        let hideColumns: any = document.body.clientWidth <= this.min_size_screen;
        // this.resize_table();
        this.setState({scrolling, hideColumns});
    }

    resize_table = () => {
        try {
            const grid: any = this.grid.current;
            const table_header: any = grid.querySelector('table');
            const table_body: any = grid.querySelector('div > hgroup > table');
            Array.from(table_body.querySelector('tbody > tr').children).forEach((el: any, x: any) => {
                table_header.querySelector('thead > tr').children.item(x).width = `${el.offsetWidth}px !important`;
            });
        } catch (e: any) {

        }
    }

    /**
     * GET MODEL DEFAULT
     */
    async getModel(module: any = null) {
        const guid: any = Storage.exists('entity', '');
        const [client]: any = guid;
        const core_control: any = ['api', 'audit', 'dashboard', 'entities', 'entity', 'login', 'module', 'parameter', 'plugins', 'profile', 'register', 'settings', 'user'];
        const path_core: any = core_control.includes(module.toLowerCase());
        let model: any = (await import(`../../api/models/${path_core ? 'core/' : ''}${module}`)).default;
        try {
            model = (await import(`../../api/models/${path_core ? 'core/' : ''}${client}/${module}`)).default;
        } catch (error: any) {
        }
        return model;
    }

    /**
     * CAPTURE ERROR
     * @param error
     */
    exceptError(error: any) {
        const {Alert}: any = this.props;
        Alert({
            title: 'Error debug',
            debug: true,
            autoSize: '80%',
            message: error.message
        });
        this.setState({loading: false, debug: true});
    }

    /**
     * ACCESSIBILITY
     */
    accessibility = () => {
        document.onkeydown = (e: any) => {
            if (e.key === 'ArrowRight' && this.state.page < this.state.totalPages) {
                this.navigate('next');
            }
            if (e.key === 'ArrowLeft' && this.state.page > 1) {
                this.navigate('previous');
            }
            if (e.key === 'ArrowUp') {
                this.navigateRow('up');
            }
            if (e.key === 'ArrowDown') {
                this.navigateRow('down');
            }
            if (e.key.toLowerCase() === 'n' && e.shiftKey) {
                this.newItem();
            }
            if (e.key.toLowerCase() === 's' && e.shiftKey) {
                this.selectRow();
            }
            if (e.key.toLowerCase() === 'c' && e.shiftKey && this.state.editing) {
                const {row_selected}: any = this.state;
                row_selected.dataset.selected = false;
                this.setState({editing: false, row_selected: null});
            }
            if (e.key.toLowerCase() === 'e' && e.shiftKey && this.state.editing) {
                const {row_selected}: any = this.state;
                row_selected.querySelectorAll('span:last-child > i:first-child').item(0).click();
            }
            if (e.key.toLowerCase() === 'delete' && this.state.editing) {
                const {row_selected}: any = this.state;
                row_selected.querySelectorAll('span:last-child > i[data-name="action-trash"]').item(0).click();
            }
        };
    }

    /**
     * GET SERVICE MODULE
     * @returns
     */
    getService = () => {
        try {
            let module: any = Utils.ucFirst(window.location.pathname.split('/')[1]);
            const classService: any = Service;
            return classService[`get${module?.replace('-', '')}Service`]();
        } catch (error: any) {
            const {Alert}: any = this.props;
            Alert({
                title: 'Error',
                message: error.message
            });
        }
    }

    /**
     * GET PERMISSIONS
     * @param permission
     * @returns
     */
    getPermissions = (permission: any) => {
        if (this?.props?.permissions) {
            const moduleName: any = this.state?.module.toLocaleLowerCase();
            const permissionName = `can${Utils.ucFirst(permission)}`;
            if (this.props?.permissions[moduleName]) {
                return this.props?.permissions[moduleName][permissionName];
            } else {
                return false;
            }
        }
    }

    /**
     * OPEN DELETE ITEM
     * @param index
     * @param item
     */
    openDeleteItem = (index: any, item: any) => {
        let data: any = this.state.documents;
        data = data.map((d: any) => {
            delete d.deleting;
            return d;
        });
        data[index].deleting = item._id;
        this.setState({data});
    }

    /**
     * DELETE ITEM
     * @param item
     */
    deleteItem = (item: any) => {
        let documents: any = this.state.documents;
        const {Alert}: any = this.props;
        Alert({
            type: 'question',
            title: 'Excluir item',
            message: `Deseja excluir este item agora?`,
            buttons: [
                {
                    label: 'Não',
                    callback: (props: any) => Alert(false)
                },
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        let index: any = documents.findIndex((r: any) => r._id === item._id);
                        documents[index].deleted = true;
                        this.setState({documents}, () => {
                            (async () => {
                                this.getService().remove(item._id).then(() => {
                                    documents = documents.filter((d: any) => d._id !== item._id);
                                    this.setState({documents});
                                });
                                Alert(false);
                            })();
                        });
                    }
                }
            ]
        });
    }

    excluir_item = (e: any) => {
        if (e.closest('button').dataset.disabled === 'true') {
            return;
        }

        let documents: any = this.state.documents;
        const {Alert}: any = this.props;
        Alert({
            type: 'question',
            title: 'Excluir item',
            message: `Deseja excluir este item agora?`,
            buttons: [
                {
                    label: 'Não',
                    close: true
                },
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        let items: any = this.grid.current.querySelectorAll('table > tbody > tr');
                        let ids: any = [];
                        items.forEach((el: any) => {
                            if (el.dataset.active === 'true') {
                                ids.push(el.id);
                            }
                        });

                        (async () => {
                            this.getService().delete_all(ids).then(() => {
                                documents = documents.filter((d: any) => !ids.includes(d._id));
                                this.setState({documents});
                                props.close();
                            });
                        })();
                    }
                }
            ]
        });
    }

    edit_item = (e: any) => {
        if (e.closest('button').dataset.disabled === 'true') {
            return;
        }
        const {ids}: any = this.state;
        const [id]: any = ids;
        this.props.history.push(`/${this.state.module.toLowerCase()}/form/${id}`);
    }

    clone_item = (e: any) => {
        if (e.closest('button').dataset.disabled === 'true') {
            return;
        }
        const {Alert}: any = this.props;
        Alert({
            type: 'question',
            title: 'Clonar item',
            message: `Deseja clonar este item agora?`,
            buttons: [
                {
                    label: 'Não',
                    close: true
                },
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        const {ids, documents}: any = this.state;
                        const [id]: any = ids;
                        const item: any = documents.find((d: any) => d._id === id);
                        let document: any = Object.assign({}, item);
                        delete document._id;
                        delete document.createAt;
                        delete document.updateAt;
                        document.name = `${document?.name} (Clone)`;
                        (async () => {
                            let result: any = await this.getService().save(document);
                            result = result.data.document[0];
                            documents.push(result)
                            this.setState({documents});
                            props.close();
                        })();
                    }
                }
            ]
        });
    }

    /**
     * CANCEL DELETE ITEM
     * @param index
     */
    cancelItem = (index: any) => {
        let data: any = this.state.documents;
        delete data[index].deleting;
        this.setState({data});
    }

    /**
     * GET LOAD DATA OTHER OPTIONS
     * @param method
     * @param params
     */
    loadData = async (method: any, params: any = {}) => {
        const {data} = await this.getService()[method](params);
        return data;
    }

    /**
     * GET STATE BEFORE LOAD DATA
     */
    callBack = () => {
    }

    /**
     * GET DATA MODULE
     */
    getData = async (page: any, param: any = {}) => {
        let fieldSort: any = this.state.model;

        try {
            fieldSort = fieldSort.find((m: any) => m.sort).id;
        } catch (error) {
            fieldSort = 'name';
        }

        let params: any = {}
        params.page = page;

        const {search, filter}: any = this.state;

        if (this.state.search) {
            params.search = {field: fieldSort, value: search};
        }

        if (Object.keys(filter).length > 0) {
            params.filter = {...filter};
        }

        let sortable: any = Remote.exists('sort_cache', {});

        params = {...params, ...param, sort: sortable};

        Promise.all([this.getService().list(params)]).then((queue: any) => {
            let {model}: any = this.state;

            if (!this.getPermissions('change')) {
                model = model.filter((f: any) => f.id !== 'status');
            }

            if (!this.getPermissions('update') && !this.getPermissions('delete') && !this.getPermissions('view')) {
                model = model.filter((f: any) => f.id !== 'actions');
            }

            let [result]: any = queue;
            const {data}: any = result;
            const {extra}: any = data;

            if (extra.excludes.length > 0) {
                let {excludes}: any = extra;
                let excludes_name: any = excludes.map((x: any) => `${x}_name`);
                excludes = [...excludes, ...excludes_name]
                model = model.filter((r: any) => !excludes.includes(r?.id))
            }

            this.setState({...data, model, loading: false});
            this.callBack()
            this.accessibility();
        });
    }

    /**
     * SELECT ROW LIST
     */
    selectRow = () => {
        const row_selected: any = this.grid.current.children[1];
        row_selected.dataset.selected = true;
        this.setState({editing: true, row_selected});
    }

    /**
     * NAVIGATE FOR ROWS GRID
     * @param dir
     */
    navigateRow = (dir: any) => {
        if (this.state.editing) {
            try {
                let {row_selected}: any = this.state;
                if (dir === 'up') {
                    row_selected.dataset.selected = false;
                    row_selected.previousSibling.dataset.selected = true;
                    row_selected = row_selected.previousSibling;
                }
                if (dir === 'down') {
                    row_selected.dataset.selected = false;
                    row_selected.nextSibling.dataset.selected = true;
                    row_selected = row_selected.nextSibling;
                }
                this.setState({row_selected});
            } catch (e) {
                this.selectRow();
            }
        }
    }

    /**
     * NAVIGATE PAGES
     * @param type
     */
    navigate = (type: any) => {
        let {page} = this.state;
        let {module}: any = this.props;
        if (type === 'next') {
            page++;
            if (page > this.state.totalPages) {
                page = this.state.totalPages
            }
        } else {
            page--;
            if (page <= 1) {
                page = 1;
            }
        }

        this.setState({page}, () => Storage.set(`page_${module.toLowerCase()}`, page));
        this.getData(page).then();
    }

    /**
     * GET TITLE MODULE
     */
    getTitle = () => {
        const {menus} = this.props;
        if (this.state.module) {
            if (menus.length <= 0) return '';
            const module = menus.find((m: any) => m?.to === this.state.module.toLocaleLowerCase());
            return (<h1>
                <i className={`fal fa-${module?.icon}`}/>
                <span>{module?.name}</span>
            </h1>);
        }
    }

    /**
     * CREATE NEW ITEM
     */
    newItem = () => {
        this.props.history.push(`/${this.state.module.toLowerCase()}/form`);
    }

    /**
     * EDIT ITEM
     * @param item
     */
    editItem = (item: any) => {
        this.props.history.push(`/${this.state.module.toLowerCase()}/form/${item._id}`);
    }

    /**
     * VIEW ITEM
     * @param item
     */
    viewItem = (item: any) => {
        if (!this.getPermissions('view')) return;
        this.props.history.push(`/${this.state.module.toLowerCase()}/view/${item._id}`);
    }

    /**
     * VIEW ITEM
     * @param item
     */
    cloneItem = (item: any) => {
        const {Alert}: any = this.props;
        Alert({
            type: 'question',
            title: 'Clonar item',
            message: `Deseja clonar este item agora?`,
            buttons: [
                {
                    label: 'Não',
                    close: true
                },
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        const document: any = Object.assign({}, item);
                        const {documents}: any = this.state;
                        delete document._id;
                        document.name = `${document.name} (Clone)`;
                        (async () => {
                            let result: any = await this.getService().save(document);
                            result = result.data.document[0];
                            documents.push(result)
                            this.setState({documents});
                            props.close();
                        })();
                    }
                }
            ]
        });
    }

    /**
     * TOTAL DE COLUMNS VISIBLE IN MODEL
     * @returns
     */
    getTotalColumns = () => {
        const {model}: any = this.state;
        let fields: any = [];
        model.filter((f: any) => f.list).map((field: any, key: any) => {
            if (key === 0) {
                fields.push('1fr');
            } else {
                fields.push(field?.size ? `${isNaN(field?.size) ? `${field?.size}` : `${field?.size}px`}` : '150px');
            }
            return field;
        });

        let rows: any = `${fields.join(' ')}`;
        rows = rows.split(' ');

        if (rows.length > 5) {
            this.min_size_screen = 1080;
        }

        if (document.body.clientWidth <= this.min_size_screen) {
            rows = ['1fr', '150px', '80px', '130px'];

            if (!model.map((m: any) => m?.id).includes('status')) {
                rows = ['1fr', '150px', '140px'];
            }

            if (!model.map((m: any) => m?.id).includes('actions')) {
                rows = ['1fr', '150px', '140px'];
            }

            if (!model.map((m: any) => m?.id).includes('status') && !model.map((m: any) => m?.id).includes('actions')) {
                rows = ['1fr', '150px'];
            }
        }

        return rows.join(' ');
    }

    /**
     * SEARCH DATA
     * @param e
     */
    seaching = (e: any) => {

        console.log(e);

        if (e.key === 'Enter') {
            this.setState({page: 1});
            this.getData(1).then();
        }
    }

    /**
     * FORMAT Calculation MONEY
     * @param value
     * @returns
     */
    formatValue = (value: any) => {
        if (typeof (value) === 'string') {
            value = value.replace(',', '.');
        }
        return (parseFloat(value)).toLocaleString('pt-BR',
            {
                style: 'currency',
                currency: 'BRL'
            }
        );
    }

    /**
     * CHANGE STATUS DOCUMENT
     * @param item
     */
    changeStatus = async (item: any) => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: 'Ativar/Desativar',
            message: 'Desejar confirmar a mudança de status agora?',
            buttons: [
                {
                    label: "Não",
                    close: true
                },
                {
                    label: "Sim",
                    callback: async (props: any) => {
                        const getIndex: any = this.state.documents.findIndex((i: any) => i._id === item._id);
                        const documents: any = this.state.documents;
                        documents[getIndex].status = !documents[getIndex].status;
                        this.setState({documents}, async () => {
                            await this.getService().save({
                                _id: documents[getIndex]._id,
                                status: documents[getIndex].status
                            });
                            Alert(false);
                        });
                    }
                }
            ]
        });
    }

    /**
     * GET
     * @returns
     */
    getName = () => {
        try {
            const {model} = this.state;
            const name: any = model.find((m: any) => m?.sort);
            return name?.id;
        } catch (error) {
            return 'name';
        }
    }

    /**
     * GET
     * @returns
     */
    getNameObject = (body: any) => {
        try {
            const {model} = this.state;
            const name: any = model.find((m: any) => m?.sort);
            if (typeof body[name?.id] === 'object') {
                return body[name?.id]['name']
            } else {
                return body[name?.id]
            }
        } catch (error) {
            return 'name';
        }
    }

    /**
     * CHECK FORMAT OF VALUE TEXT/OBJECT
     * @param value
     * @param item
     * @param body
     */
    checkFormat = (value: any, item: any, body: any) => {
        const {module}: any = this.props;
        try {
            if ([null, undefined, ''].includes(value) && item?.type !== 'actions') {
                return (<>
                    <div>-</div>
                </>);
            }

            if (item.compound) {
                return value[item.compound[0]][item.compound[1]];
            }

            if (typeof value === 'object' && item?.type !== 'file' && value?.length === 0) {
                if (!value?.id) {
                    const {field}: any = item?.service || {};
                    return (<>--
                        <div>{value[field || 'name'] === undefined ? '' : value[field || 'name']}</div>
                    </>);
                } else {
                    return '';
                }
            } else if (['date', 'date_input', 'datepicker'].includes(item.type)) {
                if (value) {
                    return (<>
                        <div>
                            {value.split('T')[0].split('-').reverse().join("/")}
                        </div>
                    </>);
                } else {
                    return (<div>Fixo</div>)
                }
            } else if (item?.type === 'money') {
                return (<>
                    <div>{typeof (value) === 'string' ? Utils.formatValue(value.replace(/,/g, '.')) : Utils.formatValue(value)}</div>
                </>);
            } else if (item?.type === 'image') {
                if (value) {
                    return (<>
                        <div>
                            <picture style={{backgroundImage: `url(${value})`}} title={body?.name || 'Foto'}/>
                        </div>
                    </>);
                } else {
                    return (<>
                        <div><i className="fal fa-image fa-3x"/></div>
                    </>);
                }
            } else if (item.id === 'emoji') {
                return (<div style={{fontSize: '1.2rem'}}>{body[item.id]?.emoji}</div>);
            } else if (item?.type === 'file') {
                if (value) {
                    return (<>
                        <div>
                            <picture style={{backgroundImage: `url(${Utils.showImage(module.toLowerCase(), value)})`}} title={body?.name || 'Foto'}/>
                        </div>
                    </>);
                } else {
                    return (<>
                        <div><i className="fal fa-image fa-3x"/></div>
                    </>);
                }
            } else if (item?.id === 'status') {
                return (<>
                    <div data-name="status" onClick={() => this.changeStatus(body)} data-status={true}>
                        <i className='fa fa-circle' style={{color: value ? 'var(--color-green)' : 'var(--color-red)'}}/>
                    </div>
                </>);
            } else if (item?.type === 'actions') {
                const {actions, actEdit, actView, actTrash, actClone}: any = this.state;
                return (<>
                    <div>
                        {this.getPermissions('update') && <i className={`fal fa-${actEdit}`} data-name='action-edit' onClick={() => this.editItem(body)}/>}
                        {this.getPermissions('clone') && <i className={`fal fa-${actClone}`} data-name='action-clone' onClick={() => this.cloneItem(body)}/>}
                        {this.getPermissions('delete') && <i className={`fal fa-${actTrash}`} data-name='action-trash' onClick={() => this.deleteItem(body)}/>}
                        {this.getPermissions('view') && <i className={`fal fa-${actView}`} data-name='action-view' onClick={() => this.viewItem(body)}/>}
                        {actions.length > 0 && actions.map((ac: any, ac_idx: any) => <i key={ac_idx} className={`fal fa-${ac?.ico || 'cog'}`} onClick={() => ac.cb ? ac.cb(body) : {}}/>)}
                    </div>
                </>)
            } else if (item?.type === 'boolean') {
                return (<>
                    <div>{String(value)}</div>
                </>);
            } else if (item.type === 'array') {
                return (<>
                    <div className={styles.ListItems}>{value.map((v: any, i: any) => <span key={i}>{v}</span>)}</div>
                </>);
            } else if (item.type === 'social') {
                return (<>
                    <div><i className={`fa-brands fa-${value} fa-2x`}/></div>
                </>);
            } else {
                return (<>
                    {['string', 'number'].includes(typeof (value)) && <div>{String(value)}</div>}
                    {typeof (value) === 'object' && <div>{String(value?.name)}</div>}
                </>);
            }
        } catch (error: any) {
            console.log(error.message);
        }
    }

    /**
     * SET VALUE FIELD
     * @param target
     */
    setValue = (target: any) => {
        const fields: any = this.state.fields;
        if (target.type === 'file') {
            fields[target.type] = target.files[0];
            fields[target.name] = {
                filename: target.files[0].name,
                type: target.files[0].type.trim(),
                size: target.files[0].size,
                data: null
            };
        } else {
            fields[target.name] = target.value;
        }
        this.setState({fields})
    }

    /**
     * SET VALUE FIELD SELECT
     * @param item
     */
    setValueSelect = (item: any) => {
        const fields: any = this.state.fields;
        fields[item.target.name] = item.target.value;
        this.setState({fields})
    }

    /**
     * CREATE COMPONENT FIELD LIST
     * @param item
     * @param body
     * @param index
     */
    componentField = (item: any, body: any, index: any) => {
        try {
            // @ts-ignore
            return this[`${item?.id}Component`](item, body, index);
        } catch (error: any) {
            return `${item?.id}, notfound`;
        }
    }

    /**
     * UPDATE FIELD
     * @param target
     * @param key
     * @param index
     */
    updateField = (target: any, key: any, index: any) => {
        let {documents}: any = this.state;
        documents[index][target.name] = target.value;
        this.setState({documents});
    }

    /**
     * SAVE DOC
     * @param key
     * @param index
     */
    saveDoc = (key: any, index: any) => {
        let {documents}: any = this.state;
        this.getService().save(documents[index])
        this.setState({documents});
    }

    /**
     * ADDING FILTER OF LIST
     */
    addFilter = () => {
        return (<></>);
    }

    /**
     * APPLY FILTERS OS LIST
     * @param target
     */
    setFilter = (target: any) => {
        let {filter, load}: any = this.state;
        if (target.value === '') {
            delete filter[target.name];
        } else {
            filter[target.name] = target.value.toString();
        }
        const {module}: any = this.props;
        Remote.set(`filter_${module.toLowerCase()}`, filter);
        if (load) {
            this.setState({filter, loading: true, search: '', page: 1}, () => {
                this.getData(1).then();
            });
        } else {
            this.setState({filter, search: '', page: 1});
        }
    }

    /**
     * CHECK SHOW AND HIDDEN COLUMN LIST
     * @param item
     */
    checkColumn = (item: any) => {
        if (document.body.clientWidth <= this.min_size_screen) {
            if (['label', 'customer'].includes(item.id)) {
                return ['label', 'customer', 'name', 'createAt', 'status', 'actions'].includes(item.id);
            } else if (!['status'].includes(item.id)) {
                return ['name', 'createAt', 'actions'].includes(item.id);
            } else {
                return ['name', 'createAt', 'status', 'actions'].includes(item.id);
            }
        } else {
            return true;
        }
    }

    /**
     * SORTING LIST BY FIELD
     * @param item
     */
    sortList = (item: any) => {
        let {page, sort}: any = this.state;
        sort[item?.id] = !sort[item?.id];
        Remote.set('sort_cache', {field: item?.id, direction: sort[item?.id] ? 1 : -1})
        let sortable: any = Remote.exists('sort_cache', {});
        this.setState({sort, sortable}, () => this.getData(page).then());
    }
    set_active = (index: any) => {
        let active: any = this.grid.current.querySelectorAll('table > tbody > tr').item(index).dataset.active;
        active = active === 'false';
        this.grid.current.querySelectorAll('table > tbody > tr').item(index).dataset.active = active;
        let ids: any = [];
        this.grid.current.querySelectorAll('table > tbody > tr').forEach((el: any) => {
            if (el.dataset.active === 'true') {
                ids.push(el.id);
            }
        });

        let actions: any = this.actions.current;
        actions.querySelectorAll('button').forEach((btn: any) => {
            btn.dataset.disabled = false;
            btn.dataset.disabled = ids.length > 1 && ['edit', 'clone'].includes(btn.id);
        });

        if (ids.length === 0) {
            actions.querySelectorAll('button').forEach((btn: any) => {
                btn.dataset.disabled = true;
            });
        }

        this.setState({ids});
    }
    get_select_ids = () => {
        let ids: any = [];
        this.grid.current.querySelectorAll('table > tbody > tr').forEach((el: any) => {
            if (el.dataset.active === 'true') {
                ids.push(el.id);
            }
        });
        return ids;
    }

    get_id = (item: any) => {
        let {_id}: any = item;
        return _id;
    }

    /**
     * RENDER HTML
     * @returns
     */
    render() {
        const {model, documents, page, totalPages, loading, searching, empty_label, search, actions} = this.state;
        const {module}: any = this.props;
        const item: any = this.props.children;
        return (<>

            {!loading && !this.getPermissions('list') &&
                <div className={styles.MenuEmpty}>
                    <img src={require('../../assets/denied.svg')} alt='Forbiden'/>
                    <label>Seus previlégios de usuário não lhe dão acesso a listar</label>
                </div>}

            {!loading && this.props.children && !loading && React.cloneElement(item, {...this.props, ...this.state})}

            {(this.getPermissions('list')) && <div className={styles.ListContain}>

                {!loading && <section data-filter={module.toLowerCase()}>
                    {this.getTitle()}
                    {(this.getPermissions('list')) && <div className={styles.SearchList}>
                        {searching && <>
                            <Input placeholder='Digite para buscar...' type='search' value={search} change={(e: any) => this.setState({search: e.target.value})} keyup={(e: any) => this.seaching(e)}/>
                        </>}
                        {this.addFilter()}
                    </div>}
                    {this.getPermissions('create') && <Button label='Novo' icon='plus' action={() => this.newItem()}/>}
                    {(this.getPermissions('list')) && totalPages > 1 && <div className={styles.ListPagination}>
                        <button disabled={page <= 1} onClick={() => this.navigate('previous')}>
                            <i className='fal fa-angle-left'/>
                        </button>
                        <span>{page}/{totalPages}</span>
                        <button disabled={page >= totalPages} onClick={() => this.navigate('next')}>
                            <i className='fal fa-angle-right'/>
                        </button>
                    </div>}
                </section>}

                {loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
                </div>}

                {(documents.length <= 0 && !loading && this.getPermissions('list')) && <div className={styles.noitems}>
                    <svg width="647.63626" height="632.17383" viewBox="0 0 647.63626 632.17383">
                        <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/>
                        <path
                            d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z"
                            transform="translate(-276.18187 -133.91309)"
                            fill="#3f3d56"/>
                        <path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="var(--primary)"/>
                        <circle cx="190.15351" cy="24.95465" r="20" fill="var(--primary)"/>
                        <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/>
                        <path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
                        <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)"
                              fill="#3f3d56"/>
                        <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="var(--primary)"/>
                        <circle cx="433.63626" cy="105.17383" r="20" fill="var(--primary)"/>
                        <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
                    </svg>
                    <label>{empty_label ? empty_label : 'Não há registros no momento, para serem exibibos!'}</label>
                </div>}

                {!loading && documents.length > 0 && this.getPermissions('list') && model &&
                    <section data-list={module.toLowerCase()} ref={this.grid}>

                        <div className={styles.ButtonActions} ref={this.actions}>
                            {this.getPermissions('delete') && <button id="delete" onClick={(e: any) => this.excluir_item(e.target)} data-disabled={true}>
                                <i className='fa fa-trash'/>
                                <label>Excluir</label>
                            </button>}
                            {this.getPermissions('update') && <button id="edit" onClick={(e: any) => this.edit_item(e.target)} data-disabled={true}>
                                <i className='fa fa-edit'/>
                                <label>Editar</label>
                            </button>}
                            {this.getPermissions('clone') && <button id="clone" data-disabled={true} onClick={(e: any) => this.clone_item(e.target)}>
                                <i className='fa fa-copy'/>
                                <label>Duplicar</label>
                            </button>}

                            {actions.length > 0 && actions.map((ac: any, ac_idx: any) =>

                                <button id="clone" data-disabled={true} onClick={(e: any) => (ac.cb && this.get_select_ids().length > 0) ? ac.cb(e.target) : {}} key={ac_idx}>
                                    <i className={`fal fa-${ac?.ico || 'cog'}`}/>
                                    <label>{ac.label}</label>
                                </button>
                            )}

                        </div>
                        <div className={styles.ScrollListTable}>
                            <hgroup>
                                <table className={styles.TableList}>
                                    <thead>
                                    <tr>
                                        {model.sort((a: any, b: any) => a.order - b.order).filter((h: any) => h.list && this.checkColumn(h)).map((head: any, head_index: any) =>
                                            <th key={head_index} data-name={head?.id}>
                                                <div>
                                                    {head?.sort && <i className='fal fa-sort' onClick={() => this.sortList(head)}/>}
                                                    {head?.label}
                                                </div>
                                            </th>
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {documents.length > 0 && documents.map((body: any, body_index: any) => <tr key={body_index} data-active={false} id={this.get_id(documents[body_index])} onClick={() => this.set_active(body_index)} onDoubleClick={() => this.viewItem(documents[body_index])}>
                                        <>
                                            {model.sort((a: any, b: any) => a.order - b.order).filter((h: any) => h.list && this.checkColumn(h)).map((item: any, item_index: any) => <td key={item_index} data-name={item?.id}>
                                                {!item?.component && <> {this.checkFormat(item?.compound ? body : body[item?.id], item, body)}</>}
                                                {item?.component && <>{this.componentField(item, body, body_index)}</>}
                                            </td>)}
                                        </>
                                    </tr>)}
                                    </tbody>
                                </table>
                            </hgroup>
                        </div>
                    </section>
                }
            </div>}
        </>);
    }
}