/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import AbstractService from '../../library/AbstractService';

/**
 * SERVICE LOGIN
 */
export default class EntitiesService extends AbstractService {
    name= 'entities';
    async getEntity() {
        const result = await this.getAdapter().get(`entity/info`);
        if (result) {
            return result;
        } else {
            return {data: {data: []}}
        }
    }

    async set_store_user(store: any) {
        return await this.getAdapter().get(`entity/set_store_user`, {params: {store}});
    }

    async getMenu() {
        return await this.getAdapter().get(`entity/menu`);
    }

    async getAll() {
        return await this.getAdapter().get(`${this.name}/all`);
    }

    async getData() {
        return await this.getAdapter().get(`entity/get_data`);
    }

    async getUser() {
        return await this.getAdapter().get(`entity/get_user`);
    }

    async getRequsts() {
        return await this.getAdapter().get(`entity/get_requests`);
    }
}
