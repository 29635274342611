/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as adminActionCreators from '../../../store/actions/adminActionCreators';
import * as authActionCreators from '../../../store/actions/authActionCreators';
import Login from '../Login';
import Admin from '../Admin';

/**
 * COMPONENT PAGE
 */
class Auth extends Component<any, any> {

    render() {
        const {auth} = this.props;
        return (
            <>
                {!auth && <Login/>}
                {auth && <Admin/>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
