/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {registerLocale} from "react-datepicker";
import pt from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', pt);

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'input',
    }

    private field = createRef<any>();

    componentDidMount() {
        this.setState({type: this.props.type});
    }

    /**
     * CREATE MASK FOR TYPE INPUT
     * @param prop
     * @returns
     */
    setMask = (prop: any) => {
        if (prop.value === undefined) return '';
        if (prop.name === 'cpf') {
            return prop.value.replace(/\D/g, '').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (prop.name === 'cnpj') {
            return prop.value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1.$2').replace(/(\d{3})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,2})/, '$1/$2').replace(/(\d{4})(\d{1,2})/, '$1-$2').replace(/(-\d{2})\d+?$/, '$1')
        } else if (prop.type === 'cel') {
            return prop.value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (prop.type === 'date') {
            if (prop.value.indexOf('T') !== -1) {
                return prop.value.split('T')[0]
            } else {
                return prop.value
            }
        } else if (prop.type === 'date_input') {
            if (prop.value.indexOf('T') !== -1) {
                return prop.value.split('T')[0].split('-').reverse().join('/')
            } else {
                return prop.value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{2})(\d)/, '$1/$2').replace(/(\d{3})(\d{1,2})\d+?$/, '$1');
            }
        } else if (prop.type === 'tel') {
            return prop.value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '($1) $2 ').replace(/(\d{4})(\d)/, '$1.$2').replace(/(\d{3})(\d{1,3})\d+?$/, '$1$2');
        } else if (prop.type === 'checkbox') {
            return !prop.value;
        } else if (prop.type === 'number') {
            return parseFloat(prop.value);
        } else {
            return prop.value;
        }
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */

    check_value = (item: any) => {
        if (!this.props?.value) return '';
        if (this.props?.value[item.name]) return this.props?.value[item?.name] || '';
    }

    render() {
        return (
            <>
                <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                    {this.props.label && <label>{this.props.label} {['data-object'].includes(this.props.type) && this.props?.data_object.map((item: any) => item.label).join('/')}</label>}
                    {this.props.obs && <small>{this.props.obs}</small>}
                    {this.props.type === 'password' && <i className={styles.stylesShowHidePwd + ` fal fa-eye${this.state.type === 'password' ? '-slash' : ''}`} onClick={() => this.showHidePwd()}/>}

                    {['text', 'checkbox', 'search', 'password', 'color', 'number', 'email', 'tel', 'cel'].includes(this.props.type) && <input
                        name={this.props.name}
                        autoComplete='false'
                        readOnly={this.props.readonly || false}
                        checked={this.props.type === 'checkbox' ? this.props.value : false}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        ref={this.props.reference}
                        type={this.state.type}
                        placeholder={this.props.placeholder}
                        value={this.setMask(this.props) || ''}
                        onChange={this.props.change}
                        onKeyUp={this.props.keyup}
                        data-theme={this.props.theme ? this.props.theme : 'default'}
                    />}

                    {['data-object'].includes(this.props.type) &&
                        <>
                            <hgroup>
                                {this.props.data_object.map((item: any, i: any) =>
                                    <input
                                        name={item.name}
                                        autoComplete='false'
                                        required={this.props.required}
                                        disabled={this.props.disabled}
                                        type='text'
                                        key={i}
                                        placeholder={item.label}
                                        value={this.check_value(item)}
                                        onChange={this.props.change}
                                        onKeyUp={this.props.keyup}
                                        data-theme={this.props.theme ? this.props.theme : 'default'}
                                    />
                                )}
                            </hgroup>
                        </>
                    }
                    {['date'].includes(this.props.type) &&
                        <input
                            name={this.props.name}
                            autoComplete='false'
                            readOnly={this.props.readonly || false}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            ref={this.props.reference}
                            type='date'
                            placeholder={this.props.placeholder}
                            value={this.setMask(this.props) || ''}
                            onChange={this.props.change}
                            data-theme={this.props.theme ? this.props.theme : 'default'}
                        />
                    }

                    {['datepicker'].includes(this.props.type) &&
                        <DatePicker
                            locale="pt-BR"
                            onChange={(date: any) => {
                                this.props.change({
                                    name: this.props.name,
                                    type: this.props.type,
                                    value: new Date(date).toISOString().slice(0, 10)
                                })
                            }
                            }
                        />
                    }

                    {['date_input'].includes(this.props.type) && <input
                        name={this.props.name}
                        autoComplete='false'
                        readOnly={this.props.readonly || false}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        ref={this.props.reference}
                        type='text'
                        value={this.setMask(this.props) || ''}
                        placeholder={this.props.placeholder}
                        onChange={this.props.change}
                        data-theme={this.props.theme ? this.props.theme : 'default'}
                    />}

                    {this.props.type === 'textarea' && <textarea
                        name={this.props.name}
                        autoComplete='false'
                        rows={this.props.rows || 5}
                        readOnly={this.props.readonly || false}
                        required={this.props.required}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        value={this.setMask(this.props) || ''}
                        onChange={this.props.change}
                        data-theme={this.props.theme ? this.props.theme : 'default'}
                    />}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);
const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);