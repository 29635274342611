/**
 * ROUTE DYNAMICS
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import AuthRouter from './auth';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import {bindActionCreators} from "redux";
import * as adminActionCreators from "./store/actions/adminActionCreators";
import * as authActionCreators from "./store/actions/authActionCreators";
import {connect} from "react-redux";

/**
 * ROUTER STATICS
 */
import Auth from './pages/core/Auth';
import Admin from './pages/core/Admin';
import Notfound from './pages/core/Notfound';
import RoutesDinamics from './RoutesDinamics';
import RoutesCore from './RoutesCore';

/**
 * ROUTES SYSTEM
 */
class Routes extends Component<any, any> {

    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route path='/' exact component={Auth}/>
                        <AuthRouter path='/admin' exact component={Admin} auth={this.props.auth}/>

                        {RoutesDinamics && RoutesDinamics.map((route: any) =>
                            <AuthRouter key={route.path} path={route.path} exact component={route.component} auth={this.props.auth}/>
                        )}

                        {RoutesCore && RoutesCore.map((route: any) =>
                            <AuthRouter key={route.path} path={route.path} exact component={route.component} auth={this.props.auth}/>
                        )}

                        <Route path='*' component={Notfound}/>
                    </Switch>
                </Router>
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
