/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import {Service} from '../../../api';
import {Remote, Utils} from '../../../api/utils';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: '',
        search: '',
        datalist: [],
        listing: [],
        allowed: []
    }

    field = createRef<any>();

    componentDidMount() {

        if (this.props.datalist === undefined) {
            this.loadDatalist(this.props);
        } else {
            this.setState({datalist: this.props.datalist});
        }
        const {allowed}: any = this.state;
        if (Array.isArray(this.props.value)) {
            let listing: any = this.props?.value;
            //@ts-ignore
            this.setState({listing});
        }
        this.setSelected({allowed});
    }

    /**
     * GET DATA SERVICE
     * @param props
     */
    loadDatalist = (props: any) => {
        (async () => {
            try {
                let options: any = [{_id: Utils.ObjectId(), name: props?.label_option || 'Selecione...'}];
                if (props.multi) {
                    options = [];
                }

                if (props?.service) {
                    if (!props?.service?.extra) {
                        const classService: any = Service;
                        let moduleName = Utils.ucFirst(props?.service?.name);
                        moduleName = moduleName.replace('-', '');
                        let {data} = await classService[`get${moduleName}Service`]().select(props?.service);
                        data.sort(Utils.OrderBy(props?.service?.field));
                        options = [...options, ...data];
                        this.setState({datalist: options});
                    } else {
                        let data: any = props.data_extra;

                        data.sort(Utils.OrderBy(props?.service?.field));
                        options = [...options, ...data];
                        this.setState({datalist: options});
                    }
                } else if (props?.items) {
                    try {
                        if (props?.items[this.props.name]) {
                            options = [...options, ...props?.items[this.props.name]];
                        } else {
                            options = [...options, ...props?.items];
                        }
                        this.setState({datalist: options});
                    } catch (error: any) {
                        console.log(error);
                    }
                } else {
                    let {data} = await Service.getParameterService().select({field: props.id});
                    data = data.map((d: any) => {
                        return {_id: d._id, name: d.value};
                    });
                    options = [...options, ...data];
                    this.setState({datalist: options});
                }
            } catch (error: any) {
                const {Alert}: any = this.props;
                Alert({
                    title: 'Error',
                    message: error.message,
                    debug: true
                });
            }
        })();
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     * @param props
     */

    /**
     * SELECT VALUE IN LIST
     * @param item
     */
    setSelected = (item: any) => {
        if (this.props.value === undefined) return false;
        if (typeof this.props.value === 'object') {
            return item._id === this.props.value._id;
        } else {
            return item._id === this.props.value;
        }
    }

    getName = (field: any) => {
        if (this.props.service) {
            return this.props.service.field;
        } else {
            return 'name';
        }
    }

    setValue = () => {
        let {value, name}: any = this.props;
        if ([undefined, null].includes(value)) {
            const filter: any = Remote.exists('filter', {});
            return filter[name];
        } else {
            if (value === undefined) return '';
            if (typeof (value) === 'string') return value;
            return value._id;
        }
    }

    searching = (item: any) => {
        return Utils.noAccents(item?.name || '').startsWith(this.state.search);
    }

    setSearch = (target: any) => {
        let search: any = Utils.noAccents(target?.value || '');
        //@ts-ignore
        this.setState({search});
    }
    additem = (item: any) => {
        let {listing}: any = this.state;
        const {id}: any = this.props;
        listing.push(item);
        //@ts-ignore
        this.setState({listing, search: ''}, () =>
            this.props.populate(id, listing)
        );
    }
    removeItem = (item: any) => {
        let {listing}: any = this.state;
        const {id}: any = this.props;
        listing = listing.filter((r: any) => r?._id !== item?._id);
        //@ts-ignore
        this.setState({listing, search: ''}, () => {
            this.props.populate(id, listing);
        });
    }
    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {listing, search}: any = this.state;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldSelect} data-multi={this.props.multi} data-disabled={this.props.disabled} data-selected={this.props.value !== undefined}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        {this.props.value && !this.props.noRemove && this.props.type === 'select' && !this.props.multi && <i onClick={(e: any) => this.props?.clear(e.target)} className={`${styles.IconClose} fal fa-times fa-lg`}/>}
                        {this.props.type === 'multi-select' &&
                            <hgroup>
                                {listing.map((item: any, idx: any) => <span key={idx}>
                                <i className='fal fa-times' onClick={() => this.removeItem(item)}/>
                                    {item[this.getName(item)]}
                            </span>)}
                                <div>
                                    <input value={this.state?.search} onChange={(e: any) => this.setSearch(e.target)} placeholder='Digite para listar...'/>
                                    {search.length >= 3 && <ul>
                                        {this.state.datalist.filter((f: any) => f[this.getName(f)] !== 'Selecione...').filter((s: any) => this.searching(s))
                                            .map((key: any, index: any) => <li key={index} onClick={() => this.additem(key)}>
                                                {key[this.getName(key)]}
                                            </li>)}
                                    </ul>}
                                </div>
                            </hgroup>
                        }
                        {[undefined, 'select'].includes(this.props.type) &&
                            <select
                                disabled={this.props.disabled}
                                ref={this.field}
                                multiple={this.props.multi}
                                data-theme={this.props.theme ? this.props.theme : 'default'}
                                name={this.props.name}
                                required={this.checkEdit(this.props)}
                                placeholder={this.props.placeholder}
                                onChange={this.props.change}
                                value={this.setValue()}>
                                {this.state.datalist.map((key: any, index: any) =>
                                    <option
                                        value={this.props?.service?.option ? key[this.props?.service?.option] : key?._id}
                                        key={index}
                                    >{key[this.getName(key)]}</option>)}
                            </select>}
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);