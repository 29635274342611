/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../pages/core/Login';


/**
 * COMPONENT AUTHENTICATE SESSION
 * @param Component
 * @param auth
 * @param rest
 */
// @ts-ignore
export default ({ component: Component, auth, ...rest }): any => {
    return (<Route {...rest} render={(props) => (auth === true ? <Component {...props} /> : <Login />)} />);
}
