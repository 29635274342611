/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

/**
 * SET AUTHENTICATE TRUE
 * @param auth
 * @returns {{auth, type: string}}
 */
const setAuth = (auth:any) => {
    return {
        type: 'SET-AUTH',
        auth
    };
};

/**
 * SET USER LOGGED
 * @param user
 * @returns {{type: string, user}}
 */
const setUser = (user:any) => {
    return {
        type: 'SET-USER',
        user
    };
};

export {setAuth, setUser};
