/**
 * PROFILE PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {View} from '../../../components/Crud';
import {Utils} from '../../../api//utils';
import styles from '../../../components/Crud/style.module.scss';
import style from './style.module.scss';
import {Button} from '../../../components/Fields';

/**
 * COMPONENT VIEW PROFILE
 */

class Page extends View {

    /**
     * GET DATA BEFORE LOAD
     */
    callBack = () => {
        let {modules, permissions}: any = this.state.extra;
        if (permissions) {
            permissions.sort(Utils.OrderBy('label'));
        }
        let module_allowed: any = [];
        Object.keys(this.props.permissions).map((per: any) => {
            if (this.props.permissions[per].canAccess) {
                module_allowed.push(per);
            }
            return per;
        });

        this.setState({data_view: {modules, permissions}});
    }

    /**
     * CHECK PERMISSION IN PERMISSION PROFILE
     * @param row
     * @param item
     */
    checkPermissionInProfile = (row: any, item: any): boolean => {
        let {document}: any = this.state;
        try {
            if (!document?.permissions) return false;
            return document?.permissions[row?.to][item?.value];
        } catch (error) {
            return false;
        }
    }

    /**
     * ADD NEW PERMISSIONS
     * @param row
     * @param item
     */
    addPermission = (row: any, item: any) => {
        let {document}: any = this.state;
        if (!document?.permissions) {
            document.permissions = {};
        }
        if (!Object.keys(document?.permissions).includes(row?.to)) {
            document.permissions[row?.to] = {};
            document.permissions[row?.to][item.value] = true;
        } else {
            document.permissions[row?.to][item.value] = !document?.permissions[row?.to][item.value];
        }
        this.setState({document});
    }

    /**
     * CHECK ALL PERMISSION
     * @param row
     */
    checkAllPermission = (row: any) => {
        const {data_view, document}: any = this.state;
        if (!document?.permissions) {
            document.permissions = {};
        }

        if (!Object.keys(document?.permissions).includes(row?.to)) {
            document.permissions[row?.to] = {};
            data_view.permissions.map((p: any) => {
                document.permissions[row?.to][p.value] = true;
                return p;
            });
        } else {
            data_view.permissions.map((p: any) => {
                document.permissions[row?.to][p.value] = !document?.permissions[row?.to][p.value];
                return p;
            });
        }
        this.setState({document});
    }

    /**
     * CHECK ALL MODULES
     * @param item
     */
    checkAllModule = (item: any) => {
        const {data_view, document}: any = this.state;
        if (!document?.permissions) {
            document.permissions = {};
        }
        data_view.modules.map((mod: any) => {
            if (!document?.permissions[mod.to]) {
                document.permissions[mod.to] = {};
            }
            if (!Object.keys(document.permissions).includes(mod?.to)) {
                document.permissions[mod.to][item.value] = true;
            } else {
                document.permissions[mod.to][item.value] = !document?.permissions[mod.to][item.value];
            }
            return mod;
        });
        this.setState({document});
    }

    /**
     * CHECK ALL PERMISSION AND MODULES
     */
    checkAll = () => {
        const {data_view, document}: any = this.state;
        if (!document.permissions) {
            document.permissions = {};
        }
        data_view.modules.map((mod: any) => {
            if (!document.permissions[mod.to]) {
                document.permissions[mod.to] = {};
            }
            data_view.permissions.map((perm: any) => {
                document.permissions[mod.to][perm.value] = true;
                return perm;
            });
            return mod;
        });
        this.setState({document});
    }

    /**
     * CHECK VALIDATE PERMISSIONS
     * @param item
     */
    checkValidate = (item: any) => {
        const {document}: any = this.state;
        if (document?.permissions === undefined) return;
        if (document?.permissions[item.to]) {
            return Object.values(document?.permissions[item.to]).some((s: any) => s);
        } else {
            return false;
        }
    }

    render() {
        const {loading, data_view}: any = this.state;
        return (<Layout>
            <div className={styles.ViewContain}>
                {loading && <div className={styles.LoaderList}>
                        <span>
                            <figure/>
                        </span>
                </div>}
                {!loading && <>
                    <section>
                        {this.getTitle('', 'user-lock')}
                        <div style={{marginRight: 10}}><Button label='Marcar todos' icon='check-double' action={() => this.checkAll()}/></div>
                        <div style={{marginRight: 10}}><Button label='Salvar' icon='check' action={() => this.save()}/></div>
                        <Button label='Voltar' icon='arrow-left' action={() => this.cancel()}/>
                    </section>
                    <section style={{padding: 0}}>
                        <table className={style.TablePermissions}>
                            <thead>
                            <tr>
                                <th/>
                                <th/>
                                {data_view?.permissions && data_view?.permissions.sort(Utils.OrderBy('label')).map((th: any, th_idx: number) =>
                                    <th key={th_idx} onClick={() => this.checkAllModule(th)}>{th?.label.toUpperCase().slice(0, 4)}</th>
                                )}
                            </tr>
                            </thead>
                        </table>
                        <div className={style.ScrollTable}>
                            <table className={style.TablePermissions}>
                                <tbody>
                                {data_view?.modules && data_view?.modules.sort(Utils.OrderBy('name')).map((tr: any, tr_idx: number) =>
                                    <tr key={tr_idx} style={{backgroundColor: tr?.isroute ? '#c9d3e650' : '', opacity: this.checkValidate(tr) ? 1 : 0.3}}>
                                        <td><i className={`fal fa-${tr.icon}`}/></td>
                                        <td onClick={() => this.checkAllPermission(tr)}>{tr?.name}</td>
                                        {data_view?.permissions && data_view?.permissions.map((td: any, td_idx: number) =>
                                            <td key={td_idx}>
                                                {<div onClick={() => this.addPermission(tr, td)}>
                                                    <i
                                                        className={`fal fa-${this.checkPermissionInProfile(tr, td) ? 'check' : 'times'}`}
                                                        style={{color: this.checkPermissionInProfile(tr, td) ? 'var(--color-green)' : 'var(--color-red)'}}/>
                                                </div>}
                                            </td>)}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>}
            </div>
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);