/**
 * INPUTS PAGE INDEX
 * Author: Thiago Silva
 * CreateAt: 2021-3-24
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {List} from '../../../components/Crud';
import './style.scss';

/**
 * COMPONENT PAGE INPUTS
 */
class Page extends List {

    callBack = () => {
        let actions = [
            {
                id: 'app',
                cb: (item: any) => this.mobile(item),
                ico: 'desktop'
            }
        ];
        const canview: any = this.getPermissions('view');
        const {permissions}: any = this.props;

        if (!canview) {
            actions = [];
        }
        let actView: any = false;

        if (Object.keys(permissions).includes('plugins') && permissions.plugins.canAccess) {
            actView = 'cog'
        }

        this.setState({actions, actView});
    }

    /**
     * EDIT ITEM
     * @param item
     */
    editItem = (item: any) => {
        this.props.history.push(`/${this.state.module.toLowerCase()}/form/${item._id}`);
    }

    /**
     * CHANGE STATUS DOCUMENT
     * @param item
     */
    changeStatus = async (item: any) => {
        const {Alert}: any = this.props;

        Alert({
            type: 'confirm',
            title: 'Ativar/Desativar',
            message: 'Desejar confirmar a mudança de status agora?',
            buttons: [
                {
                    label: "Sim",
                    callback: async (props: any) => {
                        const getIndex: any = this.state.documents.findIndex((i: any) => i._id === item._id);
                        const documents: any = this.state.documents;
                        documents[getIndex].status = !documents[getIndex].status;
                        this.setState({documents});
                        await this.getService().save({
                            _id: documents[getIndex]._id,
                            status: documents[getIndex].status
                        });
                        Alert(false);
                    }
                },
                {
                    label: "Não",
                    close: true
                }
            ]
        });
    }

    mobile = (item: any) => {
        let {protocol, hostname}: any = document.location;
        let {NODE_ENV}: any = process.env;
        let [domain]: any = item?.to;
        if (NODE_ENV === 'production') {
            hostname = `gestao.${domain}.pedreirodigital.net`
        } else {
            hostname = `gestao.${domain}.local`
        }
        let url: any = `${protocol}//${hostname}`;
        window.open(url);
    }

    render() {
        return (<Layout>
            {super.render()}
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
