export default class Validate {
    static Form = (target: any) => {
        const fields = target.querySelectorAll('input:required, textarea:required, select:required');
        const total = fields.length;
        let valid = 0;
        Array.from(fields).map((key: any, index) => {
            if (key.value) {
                key.style.border = 'none';
                valid++;
            } else {
                fields[valid].focus();
                key.style.border = '#ff000070 solid 1px';
            }
            return key;
        });
        return (valid >= total);
    }
}