export default class Storage {

    static set(name: any, value: any) {
        localStorage.setItem(name, JSON.stringify(value));
    }

    static get(name: any) {
        const _name: any = localStorage.getItem(name);
        return JSON.parse(_name);
    }

    static del(name: any) {
        localStorage.removeItem(name);
    }

    static remove(items: any) {
        if (items.length > 0) {
            items.forEach((item: any) => {
                localStorage.removeItem(item);
            });
        }
    }

    static exists(name: any, cb: any) {
        return Storage.get(name) || cb;
    }

    static clear() {
        localStorage.clear();
    }
}