/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import Editor from "react-quill";
import "react-quill/dist/quill.snow.css";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'input',
    }

    modules = {
        toolbar: [
            [{header: '1'}, {header: '2'}, {font: []}],
            [{size: []}],
            ['center', 'right', 'justify'],
            [
                {list: 'ordered'},
                {list: 'bullet'},
                {indent: '-1'},
                {indent: '+1'},
            ],
            ['link', 'image'],
            ['clean'],
            [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],

        ],
        clipboard: {
            matchVisual: false,
        },
    }

    formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'align',
    ]


    componentDidMount() {
        this.setState({type: this.props.type});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */

    render() {
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <Editor
                            modules={this.modules}
                            formats={this.formats}
                            placeholder={this.props.placeholder}
                            value={this.props.value || ''}
                            onChange={this.props.change}
                        />
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
;