/**
 * USER SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-19
 */

import AbstractService from '../../library/AbstractService';

export default class UserService extends AbstractService {
    name= 'user';
}