import React, {createRef} from "react";
import DataEmoji from "./emojis.json";
import style from './style.module.scss';

class Emojis extends React.Component<any, any> {
    state = {
        emojis: [],
        emoji_index: 0,
        show_emojis: false,
    };
    scrollEmojis: any = createRef<any>();


    componentDidMount() {
        let allEmojis: any = DataEmoji;
        let listEmojis: any = [];
        listEmojis = allEmojis.map((e: any) => {
            return e.category;
        });
        // @ts-ignore
        listEmojis = [...new Set(listEmojis)];
        listEmojis = listEmojis.map((c: any) => {
            return {
                label: c,
                emoji: allEmojis.find((f: any) => f.category === c).emoji,
                icons: allEmojis.filter((f: any) => f.category === c)
            };
        });
        listEmojis = listEmojis.filter((f: any) => f.label !== 'Flags');
        this.setState({emojis: listEmojis});
    }

    setGroup = (emoji_index: any) => {
        this.scrollEmojis.current.scrollTop = '0px';
        this.setState({emoji_index});
    }

    removeEmojis = (data: any) => {
        return !['☺️', '🪘', '🪗', '🪖', '🩴', '', '', '', '', '🪧', '🪦', '🪥', '🪣', '🪤', '🪠', '🪟', '🪞', '🛗', '🪜', '🪝', '🪛', '🪚', '🪃', '🪙', '🥲', '🤌', '🫂', '🥷', '🫁', '🫀', '🥸', '🪴', '🪱', '🪰', '🪳', '🦭', '🪶', '🦤', '🦣', '🦬', '🦫', '🧋', '🫖', '🫕', '🫔', '🫓', '🫑', '🫒', '🫐', '🛼', '🛻', '🛖', '🪵', '🪨', '🪢', '🪡', '🪆', '🪅', '🪄', '', '', '', '', '', '', ''].includes(data.emoji);
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    setEmoji = (key: any) => {
        const {set_emoji}: any = this.props;
        set_emoji(key);
    }


    selected = (emoji: any) => {
        const {value}: any = this.props;
        if (!value) return false;
        return value.aliases.every((v: any) => emoji.aliases.includes(v)) || false
    }
    render() {
        const {emojis, emoji_index}: any = this.state;
        const {open}: any = this.props;

        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    {<div className={`${style.BoxEmojis}`} data-open={open}>
                        <div className={style.Categories}>
                            {emojis && emojis.map((key: any, index: any) =>
                                <label key={index} onClick={() => this.setGroup(index)} data-selected={emoji_index === index}>{key.emoji}</label>
                            )}
                        </div>
                        <div className={style.AllIcons} ref={this.scrollEmojis}>
                            <ul>
                                {emojis[emoji_index] && emojis[emoji_index].icons.filter((f: any) => this.removeEmojis(f)).map((c: any, i: any) => <li key={i} data-selected={this.selected(c)} id={c.aliases[0]} onClick={() => this.setEmoji(c)}>
                                    {c.emoji && c.emoji}
                                </li>)}
                            </ul>
                        </div>
                    </div>}
                </>}
            </>
        );
    }
}

export default Emojis;