/**
 * Author: Thiago Silva
 * Date: 09/01/2022
 */

import {
    ApiService,
    AuditService,
    DashboardService,
    EntitiesService,
    LoginService,
    ModuleService,
    ParameterService,
    PluginsService,
    ProfileService,
    UserService

} from './services/core';

/**
 * CLASS SERVICE
 */
export default class Service {

    /**
     * CREATE STATIC INSTANCE
     */
    static ApiService: any;
    static AuditService: any;
    static DashboardService: any;
    static EntitiesService: any;
    static LoginService: any;
    static ModuleService: any;
    static ParameterService: any;
    static PluginsService: any;
    static ProfileService: any;
    static UserService: any;

    /**
     * SERVICE API
     */
    static getApiService() {
        if (Service.ApiService) return Service.ApiService;
        Service.ApiService = new ApiService();
        return Service.ApiService;
    }

    /**
     * SERVICE AUDIT
     */
    static getAuditService() {
        if (Service.AuditService) return Service.AuditService;
        Service.AuditService = new AuditService();
        return Service.AuditService;
    }

    /**
     * SERVICE DASHBOARD
     */
    static getDashboardService() {
        if (Service.DashboardService) return Service.DashboardService;
        Service.DashboardService = new DashboardService();
        return Service.DashboardService;
    }

    /**
     * SERVICE ENTITIES
     */
    static getEntitiesService() {
        if (Service.EntitiesService) return Service.EntitiesService;
        Service.EntitiesService = new EntitiesService();
        return Service.EntitiesService;
    }

    /**
     * SERVICE LOGIN
     */
    static getLoginService() {
        if (Service.LoginService) return Service.LoginService;
        Service.LoginService = new LoginService();
        return Service.LoginService;
    }

    /**
     * SERVICE MODULE
     */
    static getModuleService() {
        if (Service.ModuleService) return Service.ModuleService;
        Service.ModuleService = new ModuleService();
        return Service.ModuleService;
    }

    /**
     * SERVICE PARAMETER
     */
    static getParameterService() {
        if (Service.ParameterService) return Service.ParameterService;
        Service.ParameterService = new ParameterService();
        return Service.ParameterService;
    }

    /**
     * SERVICE PLUGINS
     */
    static getPluginsService() {
        if (Service.PluginsService) return Service.PluginsService;
        Service.PluginsService = new PluginsService();
        return Service.PluginsService;
    }

    /**
     * SERVICE PROFILE
     */
    static getProfileService() {
        if (Service.ProfileService) return Service.ProfileService;
        Service.ProfileService = new ProfileService();
        return Service.ProfileService;
    }

    /**
     * SERVICE USER
     */
    static getUserService() {
        if (Service.UserService) return Service.UserService;
        Service.UserService = new UserService();
        return Service.UserService;
    }
}