/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as adminActionCreators from '../../../store/actions/adminActionCreators';
import * as authActionCreators from '../../../store/actions/authActionCreators';
import Logo from '../../../components/Logo';
import {Button, Input} from '../../../components/Fields';
import {Service} from '../../../api';
import styles from './style.module.scss';
import {Storage, Validate} from "../../../api/utils";


/**
 * COMPONENT PAGE
 */
class Page extends Component<any, any> {

    state = {
        user: false,
        recovery: false,
        logging: false,
        loaded: false,
        token: false,
        fields: {
            username: '',
            password: '',
            repassword: '',
            email: '',
            token: '',
            code: '',
        }
    }

    data: any = {}
    private form = createRef<any>();

    componentDidMount() {
        const token: any = document.location.search.replace('?token=', '') || null;
        if (token) {
            this.setState({token});
        }
    }

    /**
     * LOGIN USER
     */
    login = (e: any) => {
        e.preventDefault();
        const {Alert} = this.props;
        const {fields} = this.state;
        if (!Validate.Form(this.form.current)) return false;
        this.form.current.disabled = true;
        this.setState({logging: true});
        Promise.all([Service.getLoginService().auth(fields)]).then((res: any) => {
            const [response]: any = res;
            const {data}: any = response;
            if (response.status === 200) {
                let {token, single}: any = data;
                Storage.set('auth', token);
                if (single) {
                    document.location.pathname = `/${single}`;
                } else {
                    document.location.pathname = '/';
                }
            } else {
                Alert({...data});
                this.form.current.disabled = false;
                this.setState({logging: false});
            }
        });
    }

    /**
     * RECOVERY LOGIN
     */
    recovery = (e: any) => {
        e.preventDefault();
        const {Alert} = this.props;
        const {fields} = this.state;
        if (!Validate.Form(this.form.current)) return false;
        this.setState({logging: true});
        (async () => {
            const response = await Service.getLoginService().recovery(fields);
            if (response.status === 200) {
                Alert({
                    ...response.data,
                    buttons: [
                        {
                            label: 'Ok, Entendi',
                            callback: (props: any) => {
                                this.form.current.disabled = false;
                                this.setState({recovery: false, send: false});
                                fields.email = '';
                                fields.code = '';
                                fields.password = '';
                                fields.repassword = '';
                                this.setState({fields})
                                props.close();
                            }
                        }
                    ]
                });
            } else {
                Alert({...response.data});
            }
            setTimeout(() => this.setState({logging: false}), 100);
        })();
    }

    /**
     * SET VALUE FIELD
     * @param target
     */
    setValue = (target: any) => {
        const fields: any = this.state.fields;
        fields[target.name] = target.value;
        this.setState({fields})
    }

    /**
     * CANCEL RECOVERY
     */
    cancelRecovery = () => {
        window.location.href = '/';
    }

    /**
     * SIGNUP CLIENT
     */
    update = async (e: any) => {
        e.preventDefault();
        let {fields, token}: any = this.state;
        fields.token = token;
        if (!Validate.Form(this.form.current)) return false;
        this.setState({send: true});
        this.form.current.disabled = true;

        if (fields.repassword !== fields.password) {
            this.props.Alert({
                title: 'Senhas invalidas',
                message: 'As senhas não conferem, verifique e tente novamente',
                buttons: [
                    {
                        label: 'OK, Entendi',
                        callback: (props: any) => {
                            this.form.current.disabled = false;
                            this.setState({send: false});
                            fields.code = '';
                            fields.password = '';
                            fields.repassword = '';
                            this.setState({fields})
                            props.close();
                        }
                    }
                ]
            });
            return;
        }

        Promise.all([Service.getLoginService().password_change(fields)]).then((queue: any) => {
            let [response]: any = queue;
            let {data}: any = response;
            if (data.status) {
                this.props.Alert({
                    ...data,
                    buttons: [
                        {
                            label: 'Ok, Entendi',
                            callback: () => {
                                window.location.href = '/';
                                this.setState({send: false});
                            }
                        }
                    ]
                });
            } else {
                this.props.Alert({
                    ...data,
                    buttons: [
                        {
                            label: 'Ok, Entendi',
                            callback: (props: any) => {
                                this.form.current.disabled = false;
                                this.setState({recovery: false, send: false});
                                fields.code = '';
                                fields.password = '';
                                fields.repassword = '';
                                this.setState({fields})
                                props.close();
                            }
                        }
                    ]
                });
            }

        });
    }

    /**
     * SET ACTION
     * @param e
     */
    setAction = (e: any) => {
        const {token, recovery}: any = this.state;
        if (token) {
            return this.update(e)
        } else {
            return recovery ? this.recovery(e) : this.login(e);
        }
    }
    render() {
        const {logging, user, token, fields, recovery}: any = this.state;
        const {entity}: any = this.props;
        const {appVersion}: any = global;
        return (
            <main className={styles.PageLogin}>
                <section className={styles.BoxLogin}>
                    <form method='post' onSubmit={(e: any) => this.setAction(e)}>
                        {Object.keys(entity).length <= 0 && <div className={styles.LoaderLogin}>
                            <span>
                                <figure/>
                            </span>
                        </div>}
                        {Object.keys(entity).length > 0 && <>
                            <Logo/>
                            {!user && <>
                                <fieldset ref={this.form} disabled={logging}>
                                    {token && <>
                                        <Input
                                            label='Código de autenticação'
                                            type='text'
                                            required={true}
                                            value={fields.code}
                                            name='code'
                                            placeholder='Informe o código de autenticação'
                                            change={(e: any) => this.setValue(e.target)}
                                        />
                                        <Input
                                            label='Nova senha'
                                            required={true}
                                            type='password'
                                            value={fields.password}
                                            name='password'
                                            placeholder='******'
                                            change={(e: any) => this.setValue(e.target)}
                                        />
                                        <Input
                                            label='Confirma senha'
                                            required={true}
                                            type='password'
                                            value={fields.repassword}
                                            name='repassword'
                                            placeholder='******'
                                            change={(e: any) => this.setValue(e.target)}
                                        />
                                        <div className={styles.footLogin}>
                                            <span onClick={() => this.cancelRecovery()}>Cancelar</span>
                                            <Button type='submit' label={logging ? 'Aguarde' : 'Salvar'}/>
                                        </div>
                                    </>}
                                    {(!recovery && !token) && <>
                                        <Input
                                            label='Usuário/E-mail'
                                            type='text'
                                            required={true}
                                            value={fields.username}
                                            name='username'
                                            placeholder='Informe seu usuário ou E-mail.'
                                            change={(e: any) => this.setValue(e.target)}
                                        />
                                        <Input
                                            label='Senha'
                                            required={true}
                                            type={'password'}
                                            value={fields.password}
                                            name='password'
                                            placeholder='Informe sua senha.'
                                            change={(e: any) => this.setValue(e.target)}
                                        />
                                        <div className={styles.footLogin}>
                                            <span onClick={() => this.setState({recovery: true, token: false})}><i className='fal fa-sync'/> Esqueceu sua senha?</span>
                                            <Button type='submit' icon={logging ? 'spin fa-spinner' : 'arrow-right'} side='right' label={logging ? 'Aguarde' : 'Entrar'}/>
                                        </div>
                                    </>}
                                    {(recovery && !token) && <>
                                        <small>Informe seu e-mail para<br/>recuperar sua senha</small>
                                        <Input
                                            label='Usuário/E-mail'
                                            required={true}
                                            type='text'
                                            value={fields.email}
                                            name='email'
                                            placeholder='Informe seu usuário ou e-mail'
                                            change={(e: any) => this.setValue(e.target)}
                                        />

                                        <div className={styles.footLogin}>
                                            <span onClick={() => this.setState({recovery: false, token: false})}><i className='fal fa-arrow-left'/>Voltar para login</span>
                                            <Button type='submit' icon={logging ? 'spin fa-spinner' : 'arrow-right'} side='right' label={logging ? 'Aguarde...' : 'Enviar'}/>
                                        </div>
                                    </>}
                                </fieldset>
                            </>}
                            {user && <div style={{maxWidth: 250}}>
                                <h1>Olá, <b>{user?.name}</b> bem vindo a gestão de pedidos vamos começar!</h1>
                            </div>}
                        </>}
                    </form>
                </section>
                <div className={styles.Version}>
                    <small><b>Pedreiro Digital Studio</b> © 2020 Todos os direitos reservados.</small>
                    <small>Versão <b>{appVersion}</b></small>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
