/**
 * PARAMETER PAGE FORM
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import React from 'react';
import { bindActionCreators } from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import { connect } from "react-redux";
import { Default as Layout } from '../../../layout';
import { Form } from '../../../components/Crud';

/**
 * COMPONENT FORM Parameter
 */
class Page extends Form {

    render() {
        return (<Layout>
            {super.render()}
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
