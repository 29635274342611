/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "./store/actions/adminActionCreators";
import * as authActionCreators from "./store/actions/authActionCreators";
import {connect} from "react-redux";
import {Service} from './api';
import Loader from "./components/Loader";
import {Storage, Utils} from "./api/utils";
import {io} from "socket.io-client";
import Logo from "./components/Logo";
import style from "./entity.module.scss"

class Entities extends Component<any, any> {

    state = {
        loader: true,
        data: false,
        disabled: false
    }

    /**
     * ONLOAD
     */
    async componentDidMount() {
        try {
            const {setEntitie, setMenu, setPermissions, setAllData} = this.props;

            Promise.all([this.getEntity(), this.getMenu(), this.getData()]).then((queue: any) => {
                let [entity, navigate, data]: any = queue;
                if (entity) {
                    if (entity?.color) {
                        document.documentElement.style.setProperty(`--primary`, entity?.color);
                        document.documentElement.style.setProperty(`--primary-over-10`, `${entity?.color}10`);
                        document.documentElement.style.setProperty(`--primary-over-20`, `${entity?.color}20`);
                    }
                    if (entity?.color_secondary) {
                        document.documentElement.style.setProperty(`--secondary`, entity?.color_secondary);
                    }
                    document.title = entity?.name;
                    setEntitie(entity);
                    Storage.set('entity', entity.guid);
                    if (data) {
                        data.logo = {
                            default: this.getImage(entity, 'logo'),
                            white: this.getImage(entity, 'logo_white'),
                            product: this.getImage(entity, 'logo_product'),
                            receipt: this.getImage(entity, 'logo_receipt')
                        }

                        setAllData(data);

                        const {menus, permissions}: any = navigate;
                        setMenu(menus);
                        setPermissions(permissions);
                    }
                    this.setState({loader: false}, () => {
                        const optSocket: any = {
                            transports: ['websocket', 'polling'],
                            query: {entity: entity?._id, adm: true}
                        };
                        let socket = io(`${Utils.getEnvironment('socket')}`, optSocket);
                        socket.emit('join', {
                            entity: entity?._id
                        })
                        this.props.setSocket(socket);
                        // @ts-ignore
                        global.socket = socket;
                        // this.manifest();
                    });
                }
            });

        } catch (error: any) {
            const {Alert} = this.props;
            Alert({
                type: 'error',
                title: 'Error',
                message: error?.message
            });

            this.setState({loader: false});
        }
    }

    /**
     * GET IMAGE LOGO
     * @param entity
     * @param name
     */
    getImage = (entity: any, field: any) => {
        return Utils.showImage('entities', entity, field);
    }

    /**
     * GET ENTITY
     */
    async getEntity() {
        const {data} = await Service.getEntitiesService().getEntity();
        return data;
    }

    /**
     * GET MENU
     */
    async getMenu() {
        if (this.props.user) {
            const {data} = await Service.getEntitiesService().getMenu();
            return data;
        } else {
            return [];
        }
    }

    /**
     * GET DATA
     */
    async getData() {
        if (this.props.user) {
            const {data} = await Service.getEntitiesService().getData();
            return data;
        } else {
            return [];
        }
    }

    /**
     * GENERATE MANIFEST PWA
     */
    manifest = () => {
        const {entity}: any = this.props;
        const {dimensions, type}: any = entity?.pwa_icon || {};
        const theme_color: any = document.querySelector('[name="theme-color"]');
        const background_color: any = document.querySelector('[name="background-color"]');
        theme_color.setAttribute("content", entity?.color);
        background_color.setAttribute("content", entity?.color);

        const ico_time: any = new Date();
        let data_manifest = {
            "name": entity?.name,
            "short_name": "App",
            "description": entity?.message,
            "start_url": '/',
            "background_color": entity?.color,
            "theme_color": entity?.color,
            "display": "standalone",
            "orientation": "portrait-primary",
            "icons": [
                {
                    src: `${Utils.showImage('entities', entity, 'pwa_icon')}?${ico_time.getTime()}`,
                    sizes: `${dimensions?.width}x${dimensions?.height}`,
                    type,
                }
            ]
        }

        const link = document.createElement("link");
        link.rel = "manifest";
        const stringManifest = JSON.stringify(data_manifest);
        link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest))
        document.head.appendChild(link);
    }

    /**
     * RENDER
     */
    render() {
        const {loader, children}: any = this.state;
        const {entity}: any = this.props;
        return (<>
            {loader && <Loader/>}
            {(!loader && entity) && children}
            {(!loader && !entity?._id) && <>
                <div className={style.Notfound}>
                    <hgroup>
                        <Logo/>
                        <article>
                            <h1>404 Não encontrado</h1>
                            <span>A entidade informada não foi encontrada!</span>
                        </article>
                    </hgroup>
                </div>
            </>}
        </>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Entities);
