/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: '',
        search: '',
        listing: [],
    }

    field = createRef<any>();

    componentDidMount() {
        // @ts-ignore
        this.setState({listing: this.props.value || []});
    }

    removeItem = (item: any) => {
        let {listing}: any = this.state;
        listing = listing.filter((r: any) => r !== item);
        // @ts-ignore
        this.setState({listing, search: ''}, () => {
            this.props.set_value({target: this.field.current, value: listing, name: this.props.name});
        });
    }

    addTage = () => {
        try {
            const search: any = this.field.current.value;
            let {listing}: any = this.state;
            if (!listing.includes(search)) {
                listing.push(search);
            }
            // @ts-ignore
            this.setState({listing}, () => {
                this.field.current.value = '';
                this.props.set_value({target: this.field.current, value: listing, name: this.props.name});
            })
        } catch (error: any) {
            console.log(error);
        }
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {listing}: any = this.state;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldSelect} data-multi={this.props.multi}
                         data-disabled={this.props.disabled} data-selected={this.props.value !== undefined}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <article>
                            <hgroup>
                                {listing.map((item: any, idx: any) => <span key={idx}>
                                <i className='fal fa-times' onClick={() => this.removeItem(item)}/>{item}</span>)}
                                <div>
                                    <input ref={this.field} placeholder='Digite para adicionar uma tag...'/>
                                </div>
                            </hgroup>
                            <button type='button' onClick={() => this.addTage()}>ADICIONAR</button>
                        </article>
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);