/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

const initialState = {
    data: [],
    all_data: {},
    field: {},
    fields: {},
    alert: false,
    entity: {},
    permissions: {},
    menus: [],
    socket: null,
};

/**
 * REDUCE ADMIN
 * @param state
 * @param action
 * @returns {{data: []}|{data}}
 */
const admin = (state = initialState, action: any) => {
    let fieldsState: any = {};
    switch (action.type) {
        case 'SET-DATA':
            return {...state, data: action.data};
        case 'SET-ALL-DATA':
            return {...state, all_data: action.all_data};
        case 'SET-FIELD':
            return {...state, field: action.field};
        case 'SET-ALERT':
            return {...state, alert: action.alert};
        case 'SET-ENTITY':
            return {...state, entity: action.entity};
        case 'SET-MENU':
            return {...state, menus: action.menus};
        case 'SET-MODULE':
            return {...state, module: action.module};
        case 'SET-SOCKET':
            return {...state, socket: action.socket};
        case 'SET-FIELDS':
            fieldsState = {...state};
            const field: any = action.fields;
            fieldsState.fields[field.name] = field.value;
            return fieldsState;
        case 'POPULATE-FIELDS':
            return {...state, fields: action.fields};
        case 'SET-PERMISSIONS':
            return {...state, permissions: action.permissions};
        default:
            return state;
    }
};

export default admin;