import {Component} from "react";

/**
 * DEFINE STATES
 */
class State {
    name?: any;
    type?: any;
    fields?: any;
    value?: any;
    datalist?: any;
    data?: any;
    moduleName?: any;
    fieldName?: any;
    file?: any;
    loader?: any;
    progress?: any;
    periods?: any;
    days?: any;
    url?: any;
    galery?: any;
    index?: any;
    files?: any;
}

/**
 *  DEFINE PROPS
 */
export class Props {
    name?: any;
    label?: any;
    type?: any;
    required?: any;
    placeholder?: any;
    setFields?: any;
    onlyButton?: any;
    icon?: any;
    action?: any;
    fields?: any;
    change?: any;
    change_money?: any;
    keyup?: any;
    blur?: any;
    focus?: any;
    value?: any;
    datalist?: any;
    theme?: any;
    checked?: any;
    address?: any;
    contact?: any;
    bank?: any;
    service?: any;
    active?: any;
    price?: any;
    calculation?: any;
    redes?: any;
    operations?: any;
    populateFields?: any;
    accept?: any;
    drag?: any;
    disabled?: any;
    clear?: any;
    noRemove?: any;
    reference?: any;
    readonly?: any;
    rows?: any;
    clicked?: any;
    update?: any;
    selectUnselect?: any;
    label_option?: any;
    obs?: any;
    items?: any;
    populate?: any;
    adpter?: any;
    multi?: any;
    keydown?: any;
    remove_video?: any;
    google?: any;
    set_value_address?: any;
    collector?: any;
    set_value?: any;
    data_extra?: any;
    set_emoji?: any;
    avatar?: any;
    emojis?: any;
    side?: any;
    click?: any;
    excludes?: any;
    set_galery?: any;
    module?: any;
    uploaded?: any;
    data_object?: any;
}

/**
 * CLASSES PROPS FIELDS
 */
export default class FieldsProops extends Component<Props, State> {

    /**
     * SHOW HIDDEN PASSWORD FIELD
     */
    showHidePwd = () => {
        let {type} = this.state;
        if (type === 'password') {
            type = 'text';
        } else {
            type = 'password';
        }
        this.setState({type});
    }

    /**
     * CHECK TYPE IN EDIT
     */
    checkEdit = (props: any) => {
        return props.required;
    }
}