/**
 * AUDIT SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-5-8
 */

import AbstractService from '../../library/AbstractService';

export default class AuditService extends AbstractService {
    name= 'audit';
}