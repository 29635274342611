/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import style from "./style.module.scss";
import {Utils} from "../../../api/utils";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'input',
        value: [],
        periods: [],
        days: []
    }

    week = [
        {id: 0, label: 'D'},
        {id: 1, label: 'S'},
        {id: 2, label: 'T'},
        {id: 3, label: 'Q'},
        {id: 4, label: 'Q'},
        {id: 5, label: 'S'},
        {id: 6, label: 'S'},
    ];

    componentDidMount() {
        if (this.props.fields?.availability) {
            const {periods, days}: any = this.props.fields?.availability;
            this.setState({periods, days});
        }
    }

    /**
     * CHECK DAYS SELECTED
     * @param day
     */
    checkDays = (day: any) => {
        const {days}: any = this.state;
        return days.some((x: any) => x === day.id);
    }

    /**
     * ADD NEW TURN
     */
    addTurn = () => {
        let {days}: any = this.state;
        let periods = {
            id: Utils.ObjectId(),
            start: '08:00',
            end: '23:00'
        };
        this.setState({periods});
        this.props.update({periods, days});
    }

    /**
     * SET DAY WEEK
     * @param day
     */
    setDay = (day: any) => {
        let {periods, days}: any = this.state;
        let exists = days.some((x: any) => x === day.id);
        if (exists) {
            days = days.filter((r: any) => r !== day.id);
        } else {
            days.push(day.id);
        }
        this.setState({days});
        this.props.update({periods, days});
    }

    /**
     * REMOVE TURN
     * @param item
     */
    removeTurn = () => {
        let {days}: any = this.state;
        let periods = {};
        this.setState({periods});
        this.props.update({periods, days});
    }

    /**
     * SET HOUR OF TURN
     * @param target
     * @param item
     */
    setHour = (target: any) => {
        let {periods, days}: any = this.state;
        periods[target.name] = target.value;
        this.setState({periods});
        this.props.update({periods, days});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {periods}: any = this.state;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <h1>Dias da semana</h1>
                        <div>Informe aqui os dias da semana e turnos<br/>para a campanha ficar disponível</div>
                        <div className={style.Calendar}>
                            <ul>
                                {this.week.map((key: any, idx: any) => <li key={idx} data-exists={this.checkDays(key)} onClick={() => this.setDay(key)}>{key.label}</li>)}
                            </ul>
                            <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}><h1>Horários</h1> {Object.keys(periods).length <= 0 && <i className='fal fa-plus fa-lg' style={{marginLeft: 15, cursor: 'pointer'}} onClick={() => this.addTurn()}/>}</div>
                            {Object.keys(periods).length > 0 && <article>
                            <span>
                                <input type='time' name='start' value={periods?.start} onChange={(e: any) => this.setHour(e.target)}/>
                                <input type='time' name='end' value={periods?.end} onChange={(e: any) => this.setHour(e.target)}/>
                            </span>
                                <i className='fal fa-trash fa-lg' onClick={() => this.removeTurn()}/>
                            </article>}
                        </div>
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);