/**
 * STUDENTS PAGE VIEW
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {View} from '../../components/Crud';
import style from "./style.module.scss";
import Logo from "../../components/Logo";
import Loader from "../../components/Loader";

/**
 * COMPONENT VIEW STUDENTS
 */
class HistorytPrint extends View {

    report: any = React.createRef();

    componentDidMount() {
        super.componentDidMount();
        document.documentElement.style.setProperty(`--body-scroll`, 'scroll');
        this.setState({loader: false});
    }

    get_data_history = (year: any = null) => {
        const {document}: any = this.props;
        if (!document) return false;
        if (document?.year.includes(year.toString())) {
            document.approved = document.approved === 'Sim' ? 'Aprovado' : 'Reprovado';
            return document;
        }
        return null
    }

    get_data_unit = (year: any = null) => {
        const {document}: any = this.props;
        if (!document) return false;
        if (year === null) return document?.student_data?._unit;
        if (document?.year.includes(year.toString())) {
            return document?.student_data?._unit;
        }
        return null
    }

    render() {
        const {loading, document, extra}: any = this.props;
        const {subjects, categories}: any = extra;
        const {student_data}: any = document;
        const current_date: any = new Date(document?.createAt);
        return (<>
            {!document?._id && <Loader/>}
            {document?._id && <div className={style.Printer} ref={this.report}>

                <header>
                    <figure>
                        <img src={require('../../assets/estado.png')} alt='Estado do Ceará'/>
                        <hr/>
                        <Logo brand={true}/>
                    </figure>

                    <strong>Estado do Ceará</strong>
                    <h1>{student_data?._unit?.name}</h1>
                </header>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div><b>ENTIDADE MATENEDORA:</b> {student_data?._unit?.name}</div>
                            <div><b>ESTABELECIMENTO:</b> {student_data?._unit?.name}</div>
                            <div>
                                <b>ENDEREÇO:</b> {student_data?._unit?.address}
                            </div>
                            <div><b>INEP:</b> {student_data?._unit?.inep}</div>
                            <div><b>TELEFONES:</b> {student_data?._unit?.contact}</div>
                        </td>
                        <td>
                            <div><b>CNPJ:</b> {student_data?._unit?.cnpj}</div>
                            <div><b>MUNICÍPIO:</b> {student_data?._unit?.county}
                                <b>ESTADO:</b> {student_data?._unit?.state}</div>
                            <div><b>E-MAIL:</b> {student_data?._unit?.email}</div>
                            <div><b>CEP: </b> {student_data?._unit?.address}</div>
                            <div><b>DEPENDÊNCIA ADMINISTRATIVA:</b> {student_data?._unit?.dependencies}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h1>HISTÓRICO ESCOLAR</h1>

                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div><b>NOME DO ALUNO (A):</b> {student_data?.name}</div>
                            <div><b>FILIAÇÃO: PAI:</b> {student_data?.affiliation?.father}
                                <b>MÃE:</b> {student_data?.affiliation?.mother}</div>
                            <div><b>CIDADE/ESTADO:</b> {student_data?.naturalness}</div>
                            <div><b>NACIONALIDADE:</b> {student_data?.nationality}</div>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <h1>NOTAS E/OU CONCEITOS</h1>

                {!loading && <table>
                    <thead>
                    <tr>
                        <th>ÁREA DE CONHECIMENTOS</th>
                        <th colSpan={4}>ANOS</th>
                    </tr>
                    <tr>
                        <th>Base Nacional Comum 9394 / 96</th>
                        {categories && categories.map((b: any, i: any) => <th key={i}>{b}º Ano</th>)}
                    </tr>
                    </thead>
                    <tbody>

                    {subjects && subjects.map((subject: any, s: any) => <tr key={s}>
                        <td>
                            {subject?.name}
                        </td>
                        {categories && categories.map((b: any, i: any) => <td key={i}></td>)}
                    </tr>)}

                    <tr>
                        <td colSpan={5} align={'left'}><b>Carga Horária</b></td>
                    </tr>
                    <tr>
                        <td>Dias Letivos</td>
                        {categories && categories.map((b: any, i: any) => <td
                            key={i}>{this.get_data_history((i + 1))?.school_days}</td>)}
                    </tr>
                    <tr>
                        <td>Carga Horária Anual</td>
                        {categories && categories.map((b: any, i: any) => <td
                            key={i}>{this.get_data_history((i + 1))?.workload}</td>)}
                    </tr>
                    <tr>
                        <td>Resultado Final</td>
                        {categories && categories.map((b: any, i: any) => <td key={i}>
                            <b>{this.get_data_history((i + 1))?.approved}</b></td>)}
                    </tr>
                    </tbody>
                </table>}

                <h1>ESTUDOS REALIZADOS</h1>

                <table>
                    <thead>
                    <tr>
                        <th align='center'>ANO</th>
                        <th>ANO LETIVO</th>
                        <th>ESTABELECIMENTO DE ENSINO</th>
                        <th>MUNICÍPIO</th>
                        <th>UF</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories && categories.map((b: any, i: any) => <tr key={i}>
                        <td align='center'>{(i + 1)}º</td>
                        <td>{this.get_data_history((i + 1))?.school_year}</td>
                        <td>{this.get_data_unit((i + 1))?.name}</td>
                        <td>{this.get_data_unit((i + 1))?.county}</td>
                        <td>{this.get_data_unit((i + 1))?.state}</td>
                    </tr>)}
                    </tbody>
                </table>

                {document?.note && <>
                    <h1>OBSERVAÇÕES</h1>
                    <small>{document?.note}</small>
                </>}

                <div>{this.get_data_unit()?.county}-{this.get_data_unit()?.state}, {current_date.toLocaleDateString('pt-BR', {day: '2-digit'})} DE {current_date.toLocaleDateString('pt-BR', {month: 'long'}).toUpperCase()} DE {current_date.getFullYear()}</div>

                <article>
                   <span>
                       <label>DIRETOR (A) REG./ NOMEAÇÃO</label>
                   </span>
                    <span>
                    <label>SECRETÁRIO REG. Nº</label>
                   </span>
                </article>
            </div>}
        </>);
    }
}

const mapStateToProps = (state: any) => ({...state.admin, ...state.auth,});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({...adminActionCreators, ...authActionCreators,}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(HistorytPrint);