/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {lazy, Suspense} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Utils} from '../../../api/utils';
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';

/**
 * COMPONENT FIELD
 */

class Field extends FieldsProops {

    state = {
        moduleName: '',
        fieldName: '',
    }

    FieldMulti: any;

    componentDidMount() {
        const {module}: any = this.props;
        const moduleName: any = Utils.ucFirst(module);
        const fieldName: any = Utils.ucFirst(this.props.name);
        this.FieldMulti = lazy(() => import(`../../../pages/${moduleName}/Fields/${fieldName}`).catch(() => ({
            default: () => <div className={styles.FieldNotfound}>
                <i className='fa fa-exclamation-triangle'/> Componente <b>{Utils.ucFirst(this.props.name)}</b> não foi encontrado!
            </div>
        })));
        this.setState({moduleName, fieldName});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {FieldMulti} = this;
        return (<>
            {this.check_exclude_field() && null}
            {[undefined, false].includes(this.check_exclude_field()) && <>
                <Suspense fallback={null}>
                    {FieldMulti && <FieldMulti/>}
                </Suspense>
            </>}
        </>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);