/**
 * MODULE PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Service} from '../../../api';
import {Default as Layout} from '../../../layout';
import {View} from '../../../components/Crud';
import {Utils} from '../../../api/utils';
import {Button} from '../../../components/Fields';
import styles from '../../../components/Crud/style.module.scss';
import style from './style.module.scss';


/**
 * COMPONENT VIEW MODULE
 */
class Page extends View {

    callBack = () => {
        (async () => {
            const {document}: any = this.state;
            let fields: any = await this.getFields(document?.to);
            let modules: any = await this.getModules();
            const core_control: any = ['api', 'audit', 'dashboard', 'entities', 'entity', 'login', 'module', 'parameter', 'plugins', 'profile', 'register', 'settings', 'user'];
            const path_core: any = core_control.includes(document?.to.toLowerCase());
            let model: any = (await import(`../../../api/models/${path_core ? 'core/' : ''}${Utils.ucFirst(document?.to)}`)).default;
            this.setState({model, fields, modules});
            this.populateField();
        })();
    }

    /**
     * POPULATES FIELDS
     */
    populateField = () => {
        const {fields}: any = this.state;
        if (this.state.model) {
            let modelCurrent: any = this.state.model;
            const contenFields: any = this.state.model.map((f: any) => f.id);
            contenFields.push(...['_id', '__v', 'status']);
            const newFields: any = [];
            Object.keys(fields).filter((f: any) => !contenFields.includes(f)).map((field: any) => {
                newFields.push({
                    id: field,
                    form: true,
                    order: 0,
                    label: Utils.ucFirst(field),
                    list: false,
                    required: true,
                    sort: true,
                    type: "text"
                });
                return field;
            });
            modelCurrent.map((f: any) => {
                document.querySelector(`[name="${f.id}"]`)?.setAttribute('value', f.label);
                return f;
            });
        }
    }

    /**
     * GET ALL MODULES
     */
    getFields = async (name: any) => {
        const {data} = await Service.getModuleService().get_model(name);
        return data;
    }

    /**
     * GET ALL MODULES
     */
    getModules = async () => {
        let {data} = await Service.getModuleService().all();
        data = data.filter((p: any) => p.parent);
        data.unshift({
            id: null,
            name: '...'
        });
        return data.filter((d: any) => !d.isroute);
    }

    /**
     * UPDATE FIELDS
     */
    update = () => {
        let {model, document}: any = this.state;
        Service.getModuleService().save_model({name: document?.to, model});
    }

    /**
     * GET PARAM NAME
     * @param key
     * @param field
     * @returns
     */
    getParam = (key: any, field: any) => {
        try {
            const model: any = this.state.model.find((k: any) => k.id === key);
            if (field === 'service') {
                return model[field].name;
            } else {
                return model[field];
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * GET PARAM NAME
     * @param key
     * @param field
     * @returns
     */
    getParamSelect = (key: any, field: any) => {
        const model: any = this.state.model.find((k: any) => k.id === key);
        try {
            if (field === 'name') {
                return model['service'].name;
            } else if (field === 'type_data') {
                return model['type_data'].format;
            } else {
                return model['service'].field;
            }
        } catch (error: any) {
            return null;
        }
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueType = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;
        if (getIndex === -1) {
            newModel.push({
                form: true,
                id: id,
                order: 0,
                label: newModel.label,
                list: false,
                required: false,
                sort: false,
                type: target.value,
                type_format: 'object'
            });
        } else {
            if (target.value === 'select') {
                newModel[getIndex]['service'] = {
                    name: target.value,
                    field: 'name'
                };
            } else {
                delete newModel[getIndex]['service'];
            }

            if (target.value === 'multi') {
                newModel[getIndex]['type'] = target.value;
                newModel[getIndex]['type_format'] = 'object';
            } else {
                delete newModel[getIndex]['type_format'];
                newModel[getIndex]['type'] = target.value;
            }
        }
        this.setState({model: newModel});
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueCheck = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;
        if (getIndex === -1) {
            newModel.push({
                form: false,
                order: 0,
                id: id,
                label: Utils.ucFirst(target.value),
                list: false,
                required: false,
                sort: false,
                type: target.value
            });
        } else {
            newModel[getIndex][target.name] = !newModel[getIndex][target.name];
        }
        this.setState({model: newModel});
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueSelect = (id: any, target: any) => {
        (async () => {
            let {model} = this.state;
            const getIndex: any = model.findIndex((f: any) => f.id === id);
            const newModel: any = model;
            if (getIndex === -1) {
                newModel.push({
                    form: true,
                    order: 0,
                    id: id,
                    label: newModel.label,
                    list: false,
                    required: false,
                    service: {name: target.value, field: 'name'},
                    sort: false,
                    type: target.value
                });
            } else {
                this.setState({modules_field: []});
                let fields: any = await this.getFields(target.value);
                fields = Object.keys(fields).filter((f: any) => !['_id', '__v'].includes(f))
                fields.unshift('Selecione um campo...');
                this.setState({modules_field: fields});
                newModel[getIndex]['service'] = {
                    name: target.value,
                    field: 'name'
                };
            }
            this.setState({model: newModel});
        })();
    }

    /**
     * SET VALUE MODEL STATIC
     * @param id
     * @param target
     */
    setValueSelectStatic = (id: any, target: any) => {
        (async () => {
            let {model} = this.state;
            const getIndex: any = model.findIndex((f: any) => f.id === id);
            const newModel: any = model;
            if (getIndex === -1) {
                newModel.push({
                    form: true,
                    order: 0,
                    id: id,
                    label: newModel.label,
                    list: false,
                    required: false,
                    service: {name: target.value, field: 'name'},
                    sort: false,
                    type: target.value,
                });
            } else {
                newModel[getIndex]['type_format'] = target.value || 'object';
            }
            this.setState({model: newModel});
        })();
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueSelectField = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;
        newModel[getIndex]['service']['field'] = target.value;
        this.setState({model: newModel});
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueLabel = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;

        if (getIndex === -1) {
            newModel.push({
                id: id,
                label: '',
                order: 0,
                items: [],
                form: false,
                list: false,
                drag: false,
                required: false,
                sort: false,
                type: 'text'
            });
        } else {
            newModel[getIndex]['label'] = target.value;
        }
        this.setState({model: newModel});
    }

    setValueArray = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;

        if (getIndex === -1) {
            newModel.push({
                id: id,
                label: '',
                order: 0,
                items: [],
                form: false,
                list: false,
                drag: false,
                required: false,
                sort: false,
                type: 'text'
            });
        } else {
            // newModel[getIndex]['items'] = target.value.join(',');
        }

        this.setState({model: newModel});
    }

    /**
     * SET VALUE MODEL
     * @param id
     * @param target
     */
    setValueOrder = (id: any, target: any) => {
        let {model} = this.state;
        const getIndex: any = model.findIndex((f: any) => f.id === id);
        const newModel: any = model;
        if (target.name === "order") {
            newModel[getIndex]['order'] = parseInt(target.value);
        }
        this.setState({model: newModel});
    }

    /**
     * REMOVE FIELDS NOT KEYABLES
     * @returns
     */
    getData = () => {
        const {fields}: any = this.state;
        const removeFields = ['_id', '__v', 'createAt', 'updateAt', 'status', 'token', 'tokenReset', 'entity'];
        if (fields === undefined) return false;
        let data: any = Object.keys(fields).filter(f => !removeFields.includes(f));
        return data;
    }

    render() {
        const {fields, modules}: any = this.state;
        return (<Layout>
            <>
                <div className={styles.ViewContain}>
                    <section>
                        {this.getTitle('Configurando', 'user-lock')}
                        <div group-button='true'>
                            <Button label='Cacncelar' icon='times' action={() => this.cancel()}/>
                            <Button label='Atualizar campos' icon='check' action={() => this.update()}/>
                        </div>
                    </section>
                    <section className={style.FormField}>
                        {this.getData() && <>
                            {this.getData().map((key: any, idx: number) => <div className={style.ItemForm} key={idx}>
                                <article>
                                    <hgroup>
                                        <input id={'order'} type={'number'} value={this.getParam(key, 'order') || (idx + 1)} min={1} max={99} name={'order'} data-type='order' placeholder={'Nº'} onChange={(e: any) => this.setValueOrder(key, e.target)}/>
                                    </hgroup>
                                    <hgroup>
                                        <input id={key} name={key} placeholder={`${key}/${fields[key].instance}`} onChange={(e) => this.setValueLabel(key, e.target)}/>
                                    </hgroup>
                                    {this.getParam(key, 'type') === 'array' && <hgroup>
                                        <input id={key} name={key} placeholder='Insert value' onChange={(e) => this.setValueArray(key, e.target)}/>
                                    </hgroup>}


                                    {this.getParam(key, 'type') === 'multi' && <hgroup>
                                        <select name='multi_type' onChange={(e) => this.setValueSelectStatic(key, e.target)}>
                                            <option value='null'>...</option>
                                            <option value='object' selected={this.getParam(key, 'type_format') === 'object'}>Objeto</option>
                                            <option value='array' selected={this.getParam(key, 'type_format') === 'array'}>Lista</option>
                                        </select>
                                    </hgroup>}

                                    {this.getParam(key, 'type') === 'select' && <hgroup>
                                        {modules.length > 0 && <select name='service' onChange={(e) => this.setValueSelect(key, e.target)}>
                                            {modules.length > 0 && modules.sort(Utils.OrderBy('name')).map((opt: any, opt_idx: any) =>
                                                <option key={opt_idx} selected={this.getParamSelect(key, 'name') === opt?.to} value={opt?.to}>{opt.name}</option>
                                            )}
                                        </select>}
                                    </hgroup>}

                                </article>
                                <article>
                                    <hgroup>
                                        <Button label='multi' active={this.getParam(key, 'type') === 'multi'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'multi'})
                                        }}/>
                                        <Button label='email' active={this.getParam(key, 'type') === 'email'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'email'})
                                        }}/>
                                        <Button label='text' active={this.getParam(key, 'type') === 'text'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'text'})
                                        }}/>
                                        <Button label='tel' active={this.getParam(key, 'type') === 'tel'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'tel'})
                                        }}/>
                                        <Button label='cel' active={this.getParam(key, 'type') === 'cel'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'cel'})
                                        }}/>
                                        <Button label='number' active={this.getParam(key, 'type') === 'number'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'number'})
                                        }}/>
                                        <Button label='password' active={this.getParam(key, 'type') === 'password'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'password'})
                                        }}/>
                                        <Button label='select' active={this.getParam(key, 'type') === 'select'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'select'})
                                        }}/>
                                        <Button label='checkbox' active={this.getParam(key, 'type') === 'checkbox'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'checkbox'})
                                        }}/>
                                        <Button label='address' active={this.getParam(key, 'type') === 'address'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'address'})
                                        }}/>
                                        <Button label='object' active={this.getParam(key, 'type') === 'object'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'object'})
                                        }}/>
                                        <Button label='list' active={this.getParam(key, 'type') === 'list'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'list'})
                                        }}/>
                                        <Button label='array' active={this.getParam(key, 'type') === 'array'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'array'})
                                        }}/>
                                        <Button label='tags' active={this.getParam(key, 'type') === 'tags'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'tags'})
                                        }}/>
                                        <Button label='money' active={this.getParam(key, 'type') === 'money'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'money'})
                                        }}/>
                                        <Button label='emoji' active={this.getParam(key, 'type') === 'emoji'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'emoji'})
                                        }}/>
                                        <Button label='editor' active={this.getParam(key, 'type') === 'editor'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'editor'})
                                        }}/>
                                        <Button label='textarea' active={this.getParam(key, 'type') === 'textarea'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'textarea'})
                                        }}/>
                                        <Button label='date' active={this.getParam(key, 'type') === 'date'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'date'})
                                        }}/>
                                        <Button label='file' active={this.getParam(key, 'type') === 'file'} action={() => {
                                            this.setValueType(key, {name: 'type', value: 'file'})
                                        }}/>
                                    </hgroup>
                                    {this.getParam(key, 'type') === 'array' && this.getParam(key, 'items') && <hgroup className={style.Items}>
                                        {this.getParam(key, 'items').map((item: any, x: any) => <span key={x}>
                                            <label>{item}</label><i className='fal fa-times'/>
                                        </span>)}
                                    </hgroup>}
                                    <hgroup>
                                        <div>
                                            <span>Ordernar <input type='checkbox' className='switch' name='sort' value={this.getParam(key, 'sort')} checked={this.getParam(key, 'sort')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>
                                            <span>Obrigatório <input type='checkbox' className='switch' name='required' value={this.getParam(key, 'required')} checked={this.getParam(key, 'required')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>
                                            <span>Listar <input type='checkbox' name='list' className='switch' value={this.getParam(key, 'list')} checked={this.getParam(key, 'list')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>
                                            {this.getParam(key, 'type') === 'array' && <span>Unico <input type='checkbox' name='unique' className='switch' value={this.getParam(key, 'unique')} checked={this.getParam(key, 'unique')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>}
                                            {this.getParam(key, 'type') === 'file' && <span>Dragdrop <input type='checkbox' name='drag' className='switch' value={this.getParam(key, 'drag')} checked={this.getParam(key, 'drag')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>}
                                            {this.getParam(key, 'type') === 'address' && <span>Dragdrop <input type='checkbox' name='drag' className='switch' value={this.getParam(key, 'drag')} checked={this.getParam(key, 'drag')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>}
                                            <span>Formulário <input type='checkbox' name='form' className='switch' value={this.getParam(key, 'form')} checked={this.getParam(key, 'form')} onChange={(e) => this.setValueCheck(key, e.target)}/></span>
                                        </div>
                                    </hgroup>
                                </article>
                            </div>)}
                        </>}
                    </section>
                </div>
            </>
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);