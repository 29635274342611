/**
 * INPUTS PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-24
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {View} from '../../../components/Crud';
import styles from "../../../components/Crud/style.module.scss";
import style from "./style.module.scss";
import {Utils} from "../../../api/utils";
import {Button} from "../../../components/Fields";
import Plugins from "./plugins";
import Permissions from "./permissions";

/**
 * COMPONENT VIEW INPUTS
 */
class Page extends View {

    callBack = () => {
        let {plugins, modules, permissions, profile}: any = this.state.extra;
        let data_view: any = {};
        if (plugins) {
            plugins = plugins.sort(Utils.OrderBy('name'));
        }
        if (permissions) {
            permissions = permissions.sort(Utils.OrderBy('label'));
        }
        data_view.profile = profile || {};
        data_view.plugins = plugins || [];
        data_view.modules = modules || [];
        data_view.permissions = permissions || [];
        data_view.tab = 'plugins';

        this.setState({data_view});
    }

    /**
     * SET ACTVE
     * @param target
     */
    setActive = async (target: any) => {
        try {
            let {document}: any = this.state;
            if (!document.plugins) document.plugins = {};
            document.plugins[target.code] = !document.plugins[target.code];
            this.setState({document});
            await this.getService().save(document);
        } catch (error: any) {
            const {Alert}: any = this.props;
            Alert({
                title: 'Error',
                message: error?.message
            })
        }
    }

    /**
     * CHECK PLUGIN
     * @param item
     */
    checkPlugin = (item: any) => {
        let {document}: any = this.state;
        if (!document?.plugins) return false;
        return document?.plugins[item.code];
    }

    /**
     * RESET PLUGINS AND PERMISSIONS DEFAULT
     */
    reset = async () => {
        try {
            let {document}: any = this.state;
            let {Alert}: any = this.props;
            const {profile}: any = this.state.data_view;
            Alert({
                type: 'confirm',
                title: 'Resetar permissões',
                message: `Deseja resetar todas as permissões para as do perfil ${profile?.name}?`,
                buttons: [
                    {
                        label: 'Sim',
                        callback: async (props: any) => {
                            let {permissions}: any = profile;
                            document.permissions = permissions;
                            await this.getService().save(document);
                            this.setState({document});
                            props.close();
                        }
                    },
                    {
                        label: 'Não',
                        close: true
                    }
                ]
            });
        } catch (error: any) {
            const {Alert}: any = this.props;
            Alert({
                title: 'Error',
                message: error?.message
            })
        }
    }

    /**
     * SET TAB VIEW
     * @param tab
     */
    setTab = (tab: any) => {
        let {data_view}: any = this.state;
        data_view.tab = tab;
        this.setState({data_view});
    }

    /**
     * CHECK PERMISSION IN PERMISSION PROFILE
     * @param row
     * @param item
     */
    checkPermissionInProfile = (row: any, item: any): boolean => {
        let {document}: any = this.state;
        try {
            if (!document.permissions) return false;
            return document.permissions[row?.to][item.value];
        } catch (error) {
            return false;
        }
    }

    /**
     * ADD NEW PERMISSIONS
     * @param row
     * @param item
     */
    addPermission = (row: any, item: any) => {
        let document: any = this.state.document;
        if (!document.permissions) {
            document.permissions = {};
        }
        if (!Object.keys(document.permissions).includes(row?.to)) {
            document.permissions[row?.to] = {};
            document.permissions[row?.to][item.value] = true;
        } else {
            document.permissions[row?.to][item.value] = !document.permissions[row?.to][item.value];
        }
        this.setState({document});
        this.getService().save(document).then();
    }

    /**
     * CHECK ALL PERMISSION
     * @param row
     */
    checkAllPermission = (row: any) => {
        const data_view: any = this.state.data_view;
        let document: any = this.state.document;
        if (!document.permissions) {
            document.permissions = {};
        }

        if (!Object.keys(document.permissions).includes(row?.to)) {
            document.permissions[row?.to] = {};
            data_view.permissions.map((p: any) => {
                document.permissions[row?.to][p.value] = true;
                return p;
            });
        } else {
            data_view.permissions.map((p: any) => {
                document.permissions[row?.to][p.value] = !document.permissions[row?.to][p.value];
                return p;
            });
        }
        this.setState({document});
        this.getService().save(document).then();
    }

    /**
     * CHECK ALL MODULES
     * @param item
     */
    checkAllModule = (item: any) => {
        const {data_view}: any = this.state;
        let {document}: any = this.state;
        if (!document.permissions) {
            document.permissions = {};
        }
        data_view?.modules.map((mod: any) => {
            if (!document.permissions[mod?.to]) {
                document.permissions[mod?.to] = {};
            }
            if (!Object.keys(document.permissions).includes(mod?.to)) {
                document.permissions[mod?.to][item.value] = true;
            } else {
                document.permissions[mod?.to][item.value] = !document.permissions[mod?.to][item.value];
            }
            return mod;
        });
        this.setState({document});
        this.getService().save(document).then();
    }

    /**
     * CHECK AND VALIDADE FIELD
     * @param item
     */
    checkValidate = (item: any) => {
        const {document}: any = this.state;
        if (document?.permissions === undefined) return;
        if (document?.permissions[item?.to]) {
            return Object.values(document?.permissions[item?.to]).some((s: any) => s);
        } else {
            return false;
        }
    }

    render() {
        let {document, data_view, loading}: any = this.state;
        return (<Layout>
            <>
                {!this.getPermissions('view') && <section className={styles.NoList}>
                <span>
                    NO ACCESS
                </span>
                </section>}

                {this.getPermissions('view') && Object.keys(document).length <= 0 && !loading && <div className={styles.noitems}>
                    EMPTY
                </div>}

                {this.getPermissions('view') && loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
                </div>}

                {this.getPermissions('view') && !loading && <div className={styles.ViewContain}>
                    {<>
                        <section>
                            {this.getTitle('')}
                            <div className={style.TabView}>
                                <button data-active={data_view.tab === 'plugins'} onClick={() => this.setTab('plugins')}><i className='fal fa-plug'/> PLUGINS</button>
                                <button data-active={data_view.tab === 'permissions'} onClick={() => this.setTab('permissions')}><i className='fal fa-lock'/> PERMISSÕES</button>
                            </div>
                            <hgroup group-button='true'>
                                {this.getPermissions('admin') && <Button disabled={data_view.tab === 'plugins'} label='RESETAR PERMISSÕES' icon='sync' action={() => this.reset()}/>}
                                <Button label='VOLTAR' icon='arrow-left' action={() => this.cancel()}/>
                            </hgroup>
                        </section>
                        <section style={{padding: 0}}>
                            {data_view.tab === 'plugins' && <Plugins
                                data={data_view}
                                active={this.setActive}
                                check={this.checkPlugin}/>}

                            {data_view.tab === 'permissions' && <Permissions
                                data={data_view}
                                checkAllModule={this.checkAllModule}
                                checkValidate={this.checkValidate}
                                checkAllPermission={this.checkAllPermission}
                                addPermission={this.addPermission}
                                checkPermissionInProfile={this.checkPermissionInProfile}/>}
                        </section>
                    </>}
                </div>}
            </>
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
