/**
 * SUPPLIERS SERVICE
 * Author: Thiago Silva
 * CreateAt: 2021-3-23
 */

import AbstractService from '../../library/AbstractService';

export default class DashboardService extends AbstractService {
    name= 'dashboard';
    async getLatestProducts() {
        return await this.getAdapter().get(`dashboard`);
    }
}