//IMPORT assessment
import Assessment from './pages/Assessment'
import AssessmentForm from './pages/Assessment/form'
import AssessmentView from './pages/Assessment/view'
//IMPORT bags
import Bags from './pages/Bags'
import BagsForm from './pages/Bags/form'
import BagsView from './pages/Bags/view'
//IMPORT subjectcategory
import Subjectcategory from './pages/Subjectcategory'
import SubjectcategoryForm from './pages/Subjectcategory/form'
import SubjectcategoryView from './pages/Subjectcategory/view'
//IMPORT behavior
import Behavior from './pages/Behavior'
import BehaviorForm from './pages/Behavior/form'
import BehaviorView from './pages/Behavior/view'
//IMPORT contracts
import Contracts from './pages/Contracts'
import ContractsForm from './pages/Contracts/form'
import ContractsView from './pages/Contracts/view'
//IMPORT subjects
import Subjects from './pages/Subjects'
import SubjectsForm from './pages/Subjects/form'
import SubjectsView from './pages/Subjects/view'
//IMPORT students
import Students from './pages/Students'
import StudentsForm from './pages/Students/form'
import StudentsView from './pages/Students/view'
//IMPORT report
import Report from './pages/Report'
import ReportForm from './pages/Report/form'
import ReportView from './pages/Report/view'
//IMPORT curriculum
import Curriculum from './pages/Curriculum'
import CurriculumForm from './pages/Curriculum/form'
import CurriculumView from './pages/Curriculum/view'
//IMPORT history
import History from './pages/History'
import HistoryForm from './pages/History/form'
import HistoryView from './pages/History/view'
//IMPORT informative
import Informative from './pages/Informative'
import InformativeForm from './pages/Informative/form'
import InformativeView from './pages/Informative/view'
//IMPORT messages
import Messages from './pages/Messages'
import MessagesForm from './pages/Messages/form'
import MessagesView from './pages/Messages/view'
//IMPORT plains
import Plains from './pages/Plains'
import PlainsForm from './pages/Plains/form'
import PlainsView from './pages/Plains/view'
//IMPORT teachers
import Teachers from './pages/Teachers'
import TeachersForm from './pages/Teachers/form'
import TeachersView from './pages/Teachers/view'
//IMPORT classes
import Classes from './pages/Classes'
import ClassesForm from './pages/Classes/form'
import ClassesView from './pages/Classes/view'
//IMPORT periods
import Periods from './pages/Periods'
import PeriodsForm from './pages/Periods/form'
import PeriodsView from './pages/Periods/view'
//IMPORT units
import Units from './pages/Units'
import UnitsForm from './pages/Units/form'
import UnitsView from './pages/Units/view'
//IMPORT duedate
import Duedate from './pages/Duedate'
import DuedateForm from './pages/Duedate/form'
import DuedateView from './pages/Duedate/view'
export default [

   //IMPORT FOR ASSESSMENT
   {path:'/assessment', component:Assessment},
   {path:'/assessment/form', component:AssessmentForm},
   {path:'/assessment/form/:id', component:AssessmentForm},
   {path:'/assessment/view', component:AssessmentView},
   {path:'/assessment/view/:id', component:AssessmentView},
   //IMPORT FOR BAGS
   {path:'/bags', component:Bags},
   {path:'/bags/form', component:BagsForm},
   {path:'/bags/form/:id', component:BagsForm},
   {path:'/bags/view', component:BagsView},
   {path:'/bags/view/:id', component:BagsView},
   //IMPORT FOR SUBJECTCATEGORY
   {path:'/subjectcategory', component:Subjectcategory},
   {path:'/subjectcategory/form', component:SubjectcategoryForm},
   {path:'/subjectcategory/form/:id', component:SubjectcategoryForm},
   {path:'/subjectcategory/view', component:SubjectcategoryView},
   {path:'/subjectcategory/view/:id', component:SubjectcategoryView},
   //IMPORT FOR BEHAVIOR
   {path:'/behavior', component:Behavior},
   {path:'/behavior/form', component:BehaviorForm},
   {path:'/behavior/form/:id', component:BehaviorForm},
   {path:'/behavior/view', component:BehaviorView},
   {path:'/behavior/view/:id', component:BehaviorView},
   //IMPORT FOR CONTRACTS
   {path:'/contracts', component:Contracts},
   {path:'/contracts/form', component:ContractsForm},
   {path:'/contracts/form/:id', component:ContractsForm},
   {path:'/contracts/view', component:ContractsView},
   {path:'/contracts/view/:id', component:ContractsView},
   //IMPORT FOR SUBJECTS
   {path:'/subjects', component:Subjects},
   {path:'/subjects/form', component:SubjectsForm},
   {path:'/subjects/form/:id', component:SubjectsForm},
   {path:'/subjects/view', component:SubjectsView},
   {path:'/subjects/view/:id', component:SubjectsView},
   //IMPORT FOR STUDENTS
   {path:'/students', component:Students},
   {path:'/students/form', component:StudentsForm},
   {path:'/students/form/:id', component:StudentsForm},
   {path:'/students/view', component:StudentsView},
   {path:'/students/view/:id', component:StudentsView},
   //IMPORT FOR REPORT
   {path:'/report', component:Report},
   {path:'/report/form', component:ReportForm},
   {path:'/report/form/:id', component:ReportForm},
   {path:'/report/view', component:ReportView},
   {path:'/report/view/:id', component:ReportView},
   //IMPORT FOR CURRICULUM
   {path:'/curriculum', component:Curriculum},
   {path:'/curriculum/form', component:CurriculumForm},
   {path:'/curriculum/form/:id', component:CurriculumForm},
   {path:'/curriculum/view', component:CurriculumView},
   {path:'/curriculum/view/:id', component:CurriculumView},
   //IMPORT FOR HISTORY
   {path:'/history', component:History},
   {path:'/history/form', component:HistoryForm},
   {path:'/history/form/:id', component:HistoryForm},
   {path:'/history/view', component:HistoryView},
   {path:'/history/view/:id', component:HistoryView},
   //IMPORT FOR INFORMATIVE
   {path:'/informative', component:Informative},
   {path:'/informative/form', component:InformativeForm},
   {path:'/informative/form/:id', component:InformativeForm},
   {path:'/informative/view', component:InformativeView},
   {path:'/informative/view/:id', component:InformativeView},
   //IMPORT FOR MESSAGES
   {path:'/messages', component:Messages},
   {path:'/messages/form', component:MessagesForm},
   {path:'/messages/form/:id', component:MessagesForm},
   {path:'/messages/view', component:MessagesView},
   {path:'/messages/view/:id', component:MessagesView},
   //IMPORT FOR PLAINS
   {path:'/plains', component:Plains},
   {path:'/plains/form', component:PlainsForm},
   {path:'/plains/form/:id', component:PlainsForm},
   {path:'/plains/view', component:PlainsView},
   {path:'/plains/view/:id', component:PlainsView},
   //IMPORT FOR TEACHERS
   {path:'/teachers', component:Teachers},
   {path:'/teachers/form', component:TeachersForm},
   {path:'/teachers/form/:id', component:TeachersForm},
   {path:'/teachers/view', component:TeachersView},
   {path:'/teachers/view/:id', component:TeachersView},
   //IMPORT FOR CLASSES
   {path:'/classes', component:Classes},
   {path:'/classes/form', component:ClassesForm},
   {path:'/classes/form/:id', component:ClassesForm},
   {path:'/classes/view', component:ClassesView},
   {path:'/classes/view/:id', component:ClassesView},
   //IMPORT FOR PERIODS
   {path:'/periods', component:Periods},
   {path:'/periods/form', component:PeriodsForm},
   {path:'/periods/form/:id', component:PeriodsForm},
   {path:'/periods/view', component:PeriodsView},
   {path:'/periods/view/:id', component:PeriodsView},
   //IMPORT FOR UNITS
   {path:'/units', component:Units},
   {path:'/units/form', component:UnitsForm},
   {path:'/units/form/:id', component:UnitsForm},
   {path:'/units/view', component:UnitsView},
   {path:'/units/view/:id', component:UnitsView},
   //IMPORT FOR DUEDATE
   {path:'/duedate', component:Duedate},
   {path:'/duedate/form', component:DuedateForm},
   {path:'/duedate/form/:id', component:DuedateForm},
   {path:'/duedate/view', component:DuedateView},
   {path:'/duedate/view/:id', component:DuedateView},
];
