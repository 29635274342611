/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import {Utils} from "../../../api/utils";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        data: [],
        type: 'input',
        value: []
    }

    async componentDidMount() {
        this.setState({data: this.props.items});
    }

    checkSelected = (key: any) => {
        if (!this.props.value) return false;
        return this.props.value.includes(key);
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {data}: any = this.state;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props?.disabled}>
                        <section>
                            {this.props?.label && <label>{this.props.label}</label>}
                            {this.props?.obs && <small>{this.props.obs}</small>}
                        </section>
                        {!data && <em>
                            Lista não definda
                        </em>}
                        {data && <ul>
                            {data.map((key: any, idx: any) => <li key={idx} onClick={() => this.props.clicked(key)} data-selected={this.checkSelected(key)}>
                                <span data-name={Utils.noAccents(key)}>{key}</span>
                            </li>)}
                        </ul>}
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);