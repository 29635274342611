/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */


/**
 * SET DATA DOCUMENTS
 * @param data
 * @returns {{data, type: string}}
 */
const setData = (data: any) => {
    return {
        type: 'SET-DATA',
        data
    };
}

/**
 * SET FIELD COMPONENT
 * @param field
 * @returns {{field, type: string}}
 */
const setField = (field: any) => {
    return {
        type: 'SET-FIELD',
        field
    };
}

/**
 * GET ALL FIELDS IN STORE
 * @param fields
 * @returns {{type: string, fieldS}}
 */
const setFields = (fields: any) => {
    return {
        type: 'SET-FIELDS',
        fields
    };
}

/**
 * POPULATE ALL FIELDS THIS FORM
 * @param fields
 * @returns {{type: string, fieldS}}
 */
const populateFields = (fields: any) => {
    return {
        type: 'POPULATE-FIELDS',
        fields
    };
}

/**
 * SHOW ALERT CUSTOM
 * @param alert
 * @returns {{type: string, alert}}
 * @constructor
 */
const Alert = (alert: any) => {
    return {
        type: 'SET-ALERT',
        alert
    };
}

/**
 * SET ENTITY
 * @param entity
 * @returns {{type: string, entity}}
 */
const setEntitie = (entity: any) => {
    return {
        type: 'SET-ENTITY',
        entity
    };
}

/**
 * SET ROUTES
 * @param menus
 * @returns {{type: string, menus}}
 */
const setMenu = (menus: any) => {
    return {
        type: 'SET-MENU',
        menus
    };
}

/**
 * SET MODULE
 * @param module
 * @returns {{type: string, module}}
 */
const setModule = (module: any) => {
    return {
        type: 'SET-MODULE',
        module
    };
}

/**
 * SET MODULE
 * @returns {{type: string, module}}
 * @param socket
 */
const setSocket = (socket: any) => {
    return {
        type: 'SET-SOCKET',
        socket
    };
}

/**
 * SET MODULE
 * @param permissions
 * @returns {{type: string, permissions}}
 */
const setPermissions = (permissions: any) => {
    return {
        type: 'SET-PERMISSIONS',
        permissions
    };
}

/**
 *
 * @param all_data
 */
const setAllData = (all_data: any) => {
    return {
        type: 'SET-ALL-DATA',
        all_data
    };
}

export {setData, setField, setFields, populateFields, Alert, setEntitie, setMenu, setModule, setPermissions, setAllData, setSocket};