/**
 * USER PAGE INDEX
 * Author: Thiago Silva
 * CreateAt: 2021-3-19
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {List} from '../../../components/Crud';
import {Select} from "../../../components/Fields";

/**
 * COMPONENT PAGE USER
 */
class Page extends List {

    addFilter = () => {
        const {filter, extra}: any = this.state;
        return (<>
            <Select value={filter.status} name='status' label_option='Status' items={extra} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'status', value: ''})}/>
            {extra?.entity.length > 0 && <Select value={filter.entity} name='entity' label_option='Entidade' items={extra} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'entity', value: ''})}/>}
            {extra?.profile.length > 0 && <Select value={filter.profile} name='profile' label_option='Perfil' items={extra} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'profile', value: ''})}/>}
        </>);
    }

    render() {
        return (<><Layout>
            {super.render()}
        </Layout></>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);