/**
*/

import {
   AssessmentService,
   BagsService,
   SubjectcategoryService,
   BehaviorService,
   ContractsService,
   SubjectsService,
   StudentsService,
   ReportService,
   CurriculumService,
   HistoryService,
   InformativeService,
   MessagesService,
   PlainsService,
   TeachersService,
   ClassesService,
   PeriodsService,
   UnitsService,
   DuedateService

} from './services';

import ServiceCore from "./serviceCore";


export default class Service extends ServiceCore {

    /**
     * CREATE STATIC INSTANCE
     */
   static AssessmentService: any;
   static BagsService: any;
   static SubjectcategoryService: any;
   static BehaviorService: any;
   static ContractsService: any;
   static SubjectsService: any;
   static StudentsService: any;
   static ReportService: any;
   static CurriculumService: any;
   static HistoryService: any;
   static InformativeService: any;
   static MessagesService: any;
   static PlainsService: any;
   static TeachersService: any;
   static ClassesService: any;
   static PeriodsService: any;
   static UnitsService: any;
   static DuedateService: any;

    /**
    * SERVICE AVALIAÇÕS
    */
    static getAssessmentService() {
        if (Service.AssessmentService) return Service.AssessmentService;
        Service.AssessmentService = new AssessmentService();
        return Service.AssessmentService;
    }

    /**
    * SERVICE BOLSAS
    */
    static getBagsService() {
        if (Service.BagsService) return Service.BagsService;
        Service.BagsService = new BagsService();
        return Service.BagsService;
    }

    /**
    * SERVICE CATEGORIA DISCIP.
    */
    static getSubjectcategoryService() {
        if (Service.SubjectcategoryService) return Service.SubjectcategoryService;
        Service.SubjectcategoryService = new SubjectcategoryService();
        return Service.SubjectcategoryService;
    }

    /**
    * SERVICE COMPORTAMENTOS
    */
    static getBehaviorService() {
        if (Service.BehaviorService) return Service.BehaviorService;
        Service.BehaviorService = new BehaviorService();
        return Service.BehaviorService;
    }

    /**
    * SERVICE CONTRATOS
    */
    static getContractsService() {
        if (Service.ContractsService) return Service.ContractsService;
        Service.ContractsService = new ContractsService();
        return Service.ContractsService;
    }

    /**
    * SERVICE DISCIPLINAS
    */
    static getSubjectsService() {
        if (Service.SubjectsService) return Service.SubjectsService;
        Service.SubjectsService = new SubjectsService();
        return Service.SubjectsService;
    }

    /**
    * SERVICE ESTUDANTES
    */
    static getStudentsService() {
        if (Service.StudentsService) return Service.StudentsService;
        Service.StudentsService = new StudentsService();
        return Service.StudentsService;
    }

    /**
    * SERVICE FREQUÊNCIA
    */
    static getReportService() {
        if (Service.ReportService) return Service.ReportService;
        Service.ReportService = new ReportService();
        return Service.ReportService;
    }

    /**
    * SERVICE GRADE CURRICULAR
    */
    static getCurriculumService() {
        if (Service.CurriculumService) return Service.CurriculumService;
        Service.CurriculumService = new CurriculumService();
        return Service.CurriculumService;
    }

    /**
    * SERVICE HISTÓRICO ESCOLAR
    */
    static getHistoryService() {
        if (Service.HistoryService) return Service.HistoryService;
        Service.HistoryService = new HistoryService();
        return Service.HistoryService;
    }

    /**
    * SERVICE INFORMATIVE
    */
    static getInformativeService() {
        if (Service.InformativeService) return Service.InformativeService;
        Service.InformativeService = new InformativeService();
        return Service.InformativeService;
    }

    /**
    * SERVICE MESSAGENS
    */
    static getMessagesService() {
        if (Service.MessagesService) return Service.MessagesService;
        Service.MessagesService = new MessagesService();
        return Service.MessagesService;
    }

    /**
    * SERVICE PLANOS
    */
    static getPlainsService() {
        if (Service.PlainsService) return Service.PlainsService;
        Service.PlainsService = new PlainsService();
        return Service.PlainsService;
    }

    /**
    * SERVICE PROFESSORES
    */
    static getTeachersService() {
        if (Service.TeachersService) return Service.TeachersService;
        Service.TeachersService = new TeachersService();
        return Service.TeachersService;
    }

    /**
    * SERVICE TURMAS
    */
    static getClassesService() {
        if (Service.ClassesService) return Service.ClassesService;
        Service.ClassesService = new ClassesService();
        return Service.ClassesService;
    }

    /**
    * SERVICE TURNOS
    */
    static getPeriodsService() {
        if (Service.PeriodsService) return Service.PeriodsService;
        Service.PeriodsService = new PeriodsService();
        return Service.PeriodsService;
    }

    /**
    * SERVICE UNIDADES
    */
    static getUnitsService() {
        if (Service.UnitsService) return Service.UnitsService;
        Service.UnitsService = new UnitsService();
        return Service.UnitsService;
    }

    /**
    * SERVICE VENCIMENTOS
    */
    static getDuedateService() {
        if (Service.DuedateService) return Service.DuedateService;
        Service.DuedateService = new DuedateService();
        return Service.DuedateService;
    }
}