import React from 'react'
import style from './style.module.scss';

export default class Loader extends React.Component {
    render() {
        return (<>
                <div className={style.Preloader}>
                    <figure>
                        <span/>
                        <svg viewBox="0 0 195 161.64">
                            <g id="EducaIcon">
                                <path id="orelha_b" className="cls-2" d="m161.47,158.09c10.72-29.87,21.44-59.75,32.16-89.62.42-1.17.73-2.37,1.37-3.46-2.43-.85-4.69-2.1-7.05-3.12-2.29-.99-2.35-.93-3.16,1.33-10.37,28.89-20.74,57.79-31.12,86.68-9.97-3.31-19.9-6.74-30.31-8.4-7.38-1.18-14.8-1.99-22.28-1.25-4.17.41-8.38.71-12.37,2.21-.45-.25-.8-.14-1.07.29h0c-.18.09-.53.23-.52.27.12.44.43.36.75.23.36.12.73.26,1.06-.07,1.88-.14,3.77.6,5.52.53,5.56-.2,10.85,1.33,16.25,2.02,5.67.72,11.21,2.21,16.72,3.78,9.82,2.8,19.3,6.45,28.49,10.87,4.05,1.95,4.01,2.05,5.55-2.26Z"/>
                                <path id="orelha_a" className="cls-2"
                                      d="m46.84,122.67c6.88,4.04,13.68,8.19,20.64,13-.45-2.35-1.98-3.12-2.89-4.36-4.15-5.65-9.69-9.88-15.36-13.74-3.89-2.65-7.57-5.9-12.28-7.26-2.64-2.82-6.42-3.6-9.71-5.19-4.47-2.16-9.25-3.68-13.89-5.47-.16-.21-.32-.43-.48-.64.37-1.22.69-2.45,1.12-3.65,9.71-27.09,19.44-54.18,29.16-81.27.29-.8.55-1.61.86-2.4.5-1.29-.12-1.94-2.01-2.04-1.78-.1-3.56-.53-5.14-1.35-1.29-.66-2.23-.6-2.65.53-.22.6-.46,1.19-.68,1.79C22.54,41.22,11.55,71.8.58,102.39c-.27.76-.9,1.49-.39,2.55,8.09,1.77,16.13,4.11,23.97,7.11,7.81,2.99,15.42,6.35,22.68,10.61Z"/>
                                <path id="folha_b" className="cls-1" d="m183.15,46.39c-8.78-3.16-17.84-5.29-27.03-6.78-7.19-1.16-14.43-1.37-21.69-.51-5.67.67-11.11,2.08-16.52,3.94-2.9,1-4.19,2.5-5.14,5.16-10.41,29.21-20.9,58.38-31.37,87.57-.5,1.39-1,2.78-1.77,4.93,3.08-1.17,5.49-2.08,7.99-2.71,7.69-1.92,15.49-2.72,23.39-2.24,5.73.35,11.4,1.05,17.01,2.36,6.4,1.5,12.77,3.11,19.03,5.12,4.47,1.43,3.73,2.26,5.45-2.53,10.86-30.17,21.66-60.37,32.48-90.55.98-2.73.97-2.76-1.83-3.77Z"/>
                                <path id="folha_a" className="cls-1" d="m108.67,40.94c-4.83-9.14-11.61-16.59-19.73-22.73-9.96-7.53-21.02-13.18-32.78-17.41-3.68-1.32-3.16-1.26-4.36,2.1-10.67,29.7-21.32,59.4-31.97,89.1-.32.9-.66,1.8-.94,2.71-.66,2.16-.65,2.16,1.38,3.01,3.09,1.29,6.17,2.58,9.26,3.87-.02,0-.04,0-.06,0,.46.56,1.05.79,1.77.72-.19-.24-.4-.41-.63-.54.44.08.81.32,1.13.72,3.79,1.33,7.19,3.51,10.71,5.36,5.22,2.73,10.12,6.05,14.74,9.8,7.18,5.82,13.44,12.37,18.06,20.42.15.26.3.56,1.11.44.52-1.45,1.11-3.1,1.71-4.76,10.33-28.8,20.63-57.61,31.02-86.39.84-2.34.82-4.09-.41-6.42Z"/>
                            </g>
                        </svg>
                    </figure>
                </div>
            </>
        );
    }
}