/**
 * USER PAGE FORM
 * Author: Thiago Silva
 * CreateAt: 2021-3-19
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {Form} from '../../../components/Crud';
import {Service} from "../../../api";

/**
 * COMPONENT FORM USER
 */
class Page extends Form {

    title = 'Atualizar dados do usuário';
    icon = 'user';


    /**
     * ONLOAD COMPONENT
     */
    filter_model = (model: any) => {
        model = this.base_model.filter((r: any) => !['store', 'profile', 'entity', 'admin', 'profile_name'].includes(r.id)).map((f: any) => {
            if (f.id === 'email') {
                f.disabled = true;
            }
            return f;
        });
        const {user}: any = this.props;
        this.setState({btnBackHidden: false, module: 'user'});


        Service.getUserService().get(user.id).then((result: any) => {
            const {data}: any = result;
            delete data?.documents?.profile_name;
            delete data?.documents?.store;
            delete data?.documents?.createAt;
            delete data?.documents?.profile;
            delete data?.documents?.status;

            if (data?.documents?.password) {
                delete data?.documents?.password;
            }
            this.props.setData(data.documents);
            this.props.populateFields(data.documents);
            this.setState({fields: data.documents, loader: false});
        });
        return model;
    }

    callbackSave = () => {
        this.props.Alert({
            type: 'success',
            title: 'Dados atualizados',
            message: 'Seus dados foram atualizados com sucesso'
        });
        return false;
    }

    render() {
        return (<><Layout>
            {super.render()}
        </Layout></>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);