/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import {Utils} from '../../../api/utils';
import {Emitter, Service} from '../../../api';

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'file',
        file: {},
        metadata: {},
        loader: false,
        maxsize: 2048 * 2048 * 100,
        progress: ''
    }

    areaDrag = createRef<any>();
    progress = createRef<any>();

    acepts = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'application/svg+xml', 'image/svg+xml', 'image/gif']

    componentDidMount() {
        Emitter.on('CLEAR_FILE', () => this.clear_file());
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */
    checkName() {
        return (this.props.value === null || this.props.value === "") ? 'SELECIONE UM ARQUIVO PARA ENVIAR...' : this.props.value?.filename;
    }

    upload(file: any) {
        const {Alert, module}: any = this.props;
        this.setState({loader: true});
        (async () => {
            const classService: any = Service;
            let moduleName = Utils.ucFirst(module);
            moduleName = moduleName.replace('-', '');
            await classService[`get${moduleName}Service`]().upload(file,
                (result: any, progress: any) => this.setState({progress}),
                (success: any) => this.setState({loader: false, progress: ''}),
                (error: any) => Alert({title: 'Error', message: error?.message})
            );
        })();
    }

    clear = () => {
        const {Alert}: any = this.props;
        Alert({
            type: 'confirm',
            title: 'Remover arquivo',
            message: 'Deseja remover este arquivo agora?',
            buttons: [
                {
                    label: 'Não',
                    close: true
                },
                {
                    label: 'Sim',
                    callback: (props: any) => this.clear_file()
                }
            ]
        })
    }

    clear_file = async () => {
        const {Alert, fields, module}: any = this.props;
        this.setState({loader: true})
        const classService: any = Service;
        let moduleName = Utils.ucFirst(module);
        moduleName = moduleName.replace('-', '');
        const file = fields[this.props.name].filename;
        classService[`get${moduleName}Service`]().clear_file({
            _id: fields._id,
            field: this.props.name,
            file
        }).then((success: any) => {
            this.props.fields[this.props.name] = {};
            this.props.setFields(this.props.fields);
            this.areaDrag.current.style.backgroundColor = 'transparent';
            this.setState({loader: false});
            Alert(false);
        }).catch((error: any) => {
            Alert(false);
        });
    }

    ondrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        let {file}: any = this.state;
        let {fields, Alert}: any = this.props;

        const item = e.dataTransfer.items[0];
        if (item.kind === 'file') {
            file = item.getAsFile();
        }
        const fileSize: any = this.state.maxsize;

        if (file.size > fileSize) {
            Alert({
                title: 'Limite excedido',
                message: `Não foi possivel enviar o arquivo pois excede o mimite máximo de <b>${Utils.SizeUnits(fileSize)}</b> o arquivo tem <b>${Utils.SizeUnits(file.size)}</b>`
            })
            return;
        }
        const acepts: any = this.acepts;
        if (!acepts.includes(file?.type)) {
            Alert({
                title: 'Arquivo não permitido',
                message: `O arquivo que enviou não é do tipo valido verifique os tipos abaixo e tente novamento: <br/> ${acepts.join(', ')}`
            })
            return;
        }
        this.areaDrag.current.style.borderColor = '#00000026';
        this.areaDrag.current.style.borderWidth = 'thin';
        let extension = file.type.split('/')[1] || file.type;

        if (extension.indexOf('svg') !== -1) {
            extension = 'svg';
        }

        file.registername = `${Utils.ObjectId()}.${extension}`;
        const reader: any = new FileReader();
        let checkImage: any = new Image();
        reader.onloadend = () => {
            checkImage.onload = () => {
                fields[this.props?.name] = {
                    orginal: file.name,
                    filename: file.registername,
                    type: file.type.trim(),
                    size: file.size,
                    data: reader.result,
                    dimensions: {width: checkImage.width, height: checkImage.height}
                };
                this.props.setFields(fields);
                this.setState({file});

            }
            checkImage.src = reader.result;
        }
        reader.readAsDataURL(file);
        try {
            if (!Object.keys(fields).includes(this.props.name)) {
                fields[this.props.name] = {
                    orginal: file.name,
                    filename: file.registername,
                    type: file.type.trim(),
                    size: file.size,
                    data: reader.result,
                    dimensions: {}
                };
            }
            const current = fields[this.props.name];
            file.current = current.filename;
        } catch (error: any) {
            Alert({
                title: 'Error',
                message: error?.message
            });
        }
        this.upload(file);
    }

    ondragover = (e: any) => {
        e.preventDefault();
        this.areaDrag.current.style.borderColor = 'var(--primary)';
        this.areaDrag.current.style.backgroundColor = '#00000020';
    }

    ondragenter = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    ondragleave = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.areaDrag.current.style.borderColor = '#00000026';
        this.areaDrag.current.style.backgroundColor = 'transparent';
    }

    selectFile = (e: any) => {
        let file = e.files[0];
        let {fields, Alert}: any = this.props;
        const fileSize: any = this.state.maxsize;
        if (file.size > fileSize) {
            Alert({
                title: 'Limite excedido',
                message: `Não foi possivel enviar o arquivo pois excede o mimite máximo de <b>${Utils.SizeUnits(fileSize)}</b> o arquivo tem <b>${Utils.SizeUnits(file.size)}</b>`
            })
            return;
        }
        const acepts: any = this.acepts;
        if (!acepts.includes(file?.type)) {
            Alert({
                title: 'Arquivo não permitido',
                message: `O arquivo que enviou não é do tipo valido verifique os tipos abaixo e tente novamento: <br/> ${acepts.join(', ')}`
            })
            return;
        }

        const extension = file.type.split('/')[1] || file.type;
        file.registername = `${Utils.ObjectId()}.${extension}`;
        const reader: any = new FileReader();

        reader.onloadend = () => {
            let checkImage: any = new Image();
            checkImage.onload = () => {

                fields[this.props?.name] = {
                    orginal: file.name,
                    filename: file.registername,
                    type: file.type.trim(),
                    size: file.size,
                    data: reader.result,
                    dimensions: {width: checkImage.width, height: checkImage.height}
                };
                this.props.setFields(fields);
                this.setState({file});
            }
            checkImage.src = reader.result;
        }
        reader.readAsDataURL(file);
        try {
            if (!Object.keys(fields).includes(this.props.name)) {
                fields[this.props.name] = {
                    orginal: file.name,
                    filename: file.registername,
                    type: file.type.trim(),
                    size: file.size,
                    data: reader.result,
                    dimensions: {}
                };
            }
            const current = fields[this.props.name];
            file.current = current.filename;
        } catch (error: any) {
            Alert({
                title: 'Error',
                message: error?.message
            });
        }
        this.upload(file);
    }

    checkFileExist = () => {
        const {fields, name}: any = this.props;
        if (!Object.keys(fields).includes(name)) return true;
        if ([undefined, null, ' '].includes(fields[this.props?.name])) return true;
        if (typeof (fields[this.props?.name]) === 'string') {
            return true;
        } else {
            return Object.keys(fields[this.props?.name]).length <= 0;
        }
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        const {module, fields}: any = this.props;
        const {loader, progress}: any = this.state;
        let {size, type, dimensions}: any = fields[this.props?.name] || {};
        if (!dimensions) {
            dimensions = {width: 0, height: 0};
        }
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-drag={this.props.drag} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        {!this.props.drag && <>
                            <i className={`fal fa-upload`}/>
                            <input
                                name={this.props.name}
                                checked={this.props.type === 'checkbox' ? this.props.value : false}
                                required={this.props.required}
                                type={this.state.type}
                                data-name={this.checkName()}
                                data-title='Clique aqui para selecionar um arquivo.'
                                placeholder={this.props.placeholder}
                                className={this.props.type === 'checkbox' ? 'switch' : ''}
                                onChange={this.props.change}
                                data-theme={this.props.theme ? this.props.theme : 'default'}
                            />
                        </>}
                        {this.props.drag && <>
                            <div ref={this.areaDrag}
                                 onDrop={(e) => this.ondrop(e)}
                                 onDragOver={(e) => this.ondragover(e)}
                                 onDragEnter={(e) => this.ondragenter(e)}
                                 onDragLeave={(e) => this.ondragleave(e)}>
                                {!loader && this.checkFileExist() && <>
                                    <span>Arraste e solte aqui a imagem para fazer upload ou clique no botão para procura-lá!</span>
                                    <article>
                                        <input type='file' data-title='Procurar...' onChange={(e) => this.selectFile(e.target)}/>
                                        <button><i className='fa fa-folder'/>Selecionar arquivo</button>
                                    </article>
                                </>}

                                {loader && <div className={styles.LoaderList} ref={this.progress}>
                                    <span data-pct={progress}><figure role="progressbar"/></span>
                                </div>}
                                {!loader && !this.checkFileExist() && <figure style={{backgroundImage: `url(${fields[this.props.name]?.data !== undefined ? fields[this.props.name]?.data : Utils.showImage(module, fields, this.props.name)})`}}/>}
                                {!loader && !this.checkFileExist() && <>
                                    <i className='fa fa-times' data-remove="true" onClick={() => this.clear()}/>
                                </>}
                                {!this.checkFileExist() && Utils.SizeUnits(size || 0) > 0 && <big>{Utils.SizeUnits(size || 0)} | {type} | {`${dimensions?.width}x${dimensions?.height}`}</big>}
                            </div>
                        </>}
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
;