/**
 * STUDENTS PAGE VIEW
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../layout';
import {View} from '../../components/Crud';
import styles from "../../components/Crud/style.module.scss";
import style from "./style.module.scss";
import {Button} from "../../components/Fields";
import {Utils} from "../../api/utils";
import {Service} from "../../api";
import CurrentDate from "../../api/utils/Date"

/**
 * COMPONENT VIEW STUDENTS
 */
class Page extends View {

    report: any = React.createRef();

    callBack = () => {
        const {data_view, extra}: any = this.state;
        const {reports, bimistres, all_assessments}: any = extra;
        data_view.reports = reports || {}
        data_view.assessments = all_assessments || {}
        data_view.bimistres = bimistres || [];
        const current_date: any = new Date()
        data_view.months = Array.from({length: 12}, (e: any, i: any) => {
            const _date: any = new Date(current_date.getFullYear(), i, 1);
            return {
                id: i,
                month: _date.toISOString(),
                label: _date.toLocaleDateString("pt-BR", {month: "short"})
            };
        });
        this.setState({data_view});
    }
    set_tab = (tab: any) => {
        // @ts-ignore
        this.setState({tab});
    }
    generate_parcel = () => {
        const {Alert}: any = this.props;
        const {document}: any = this.state;
        Alert({
            title: 'Gerar parcelas',
            message: `Deseja criar as parcelas desta aluno(a) agora?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde gerando parcelas...', buttons: []})
                        Service.getStudentsService().installments({id: document._id}).then((result: any) => {
                            let {extra}: any = this.state;
                            let {data}: any = result;
                            if (data?.installments.length > 0) {
                                extra.installments = data?.installments;
                                this.setState({extra});
                                props.close();
                            }
                        });
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }
    get_date = (install: any) => {
        let date: any = new Date(install?.due_date);
        let month: any = (date.getMonth() + 1);
        let year: any = date.getFullYear();
        month = month <= 9 ? `0${month}` : month;
        return `${month}/${year}`;
    }
    get_day = (install: any) => {
        let date: any = new Date(install?.due_date);
        let day: any = (date.getDate() + 1);
        day = day <= 9 ? `0${day}` : day;
        return day;
    }
    install_total_values = (installments: any, name: any) => {

        let paid: any = installments.filter((f: any) => f?.status === 1)?.map((m: any) => {
            if (m?.delay_days && m?.amount_delay) {
                return m?.amount_delay;
            } else {
                return m?.amount;
            }
        });
        let remaining: any = installments.filter((f: any) => f?.status === 0)?.map((m: any) => {
            if (m?.delay_days && m?.amount_delay) {
                return m?.amount_delay;
            } else {
                return m?.amount;
            }
        });

        let data: any = {
            'paid': paid.length > 0 ? paid.reduce((a: any, b: any) => a + b) : 0,
            'remaining': remaining.length > 0 ? remaining.reduce((a: any, b: any) => a + b) : 0,
            'total': installments.map((m: any) => {
                if (m?.delay_days && m?.amount_delay) {
                    return m?.amount_delay;
                } else {
                    return m?.amount;
                }
            }).reduce((a: any, b: any) => a + b)
        }
        return Utils.formatValue(data[name]);
    }
    down_payment = (item: any) => {
        const {Alert}: any = this.props;
        const {document}: any = this.state;
        const expired_value: any = this.get_expired(item);
        const get_value_amount_delay: any = this.check_amount_delay_value(item)

        const alert_object: any = {
            title: 'Baixa na mensalidade',
            message: `Deseja dar baixa nessa parcela agora?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        Service.getStudentsService().down_payment({id: item._id}).then((result: any) => {
                            const {extra}: any = this.state;
                            let {installments}: any = extra;
                            let {data}: any = result;
                            installments = installments.map((install: any) => {
                                if (install?._id === item?._id) {
                                    install.status = 1;
                                    install.payment_date = data?.installment?.payment_date
                                }
                                return install;
                            });
                            extra.installments = installments;
                            this.setState({extra});
                            props.close();
                        });
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        }

        if (expired_value > 0 && document?._unit?.amount_delay > 0) {
            alert_object.title = 'Mensalidade em atraso'
            alert_object.message = `Esta mensalidade esta em atraso há mais de <b>(${expired_value})</b> dias, com valor atualizado de <b>${Utils.formatValue(get_value_amount_delay)}</b>, deseja quita-lá com o valor normal ou valor com a multa aplicada.<br/><br/>Escolha uma opção abaixo:`
            alert_object.buttons = [
                {
                    label: Utils.formatValue(get_value_amount_delay),
                    callback: () => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        Service.getStudentsService().down_payment({id: item._id, value: get_value_amount_delay, days: expired_value}).then((result: any) => {
                            const {extra}: any = this.state;
                            let {installments}: any = extra;
                            let {data}: any = result;
                            installments = installments.map((install: any) => {
                                if (install?._id === item?._id) {
                                    install.status = 1;
                                    install.payment_date = data.installment?.payment_date
                                }
                                return install;
                            });
                            extra.installments = installments;
                            // @ts-ignore
                            this.setState({extra});
                            Alert(false);
                        });
                    }
                },
                {
                    label: Utils.formatValue(item?.amount),
                    callback: () => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        Service.getStudentsService().down_payment({id: item._id, value: item?.amount, days: expired_value}).then((result: any) => {
                            const {extra}: any = this.state;
                            let {installments}: any = extra;
                            let {data}: any = result;
                            installments = installments.map((install: any) => {
                                if (install?._id === item?._id) {
                                    install.status = 1;
                                    install.payment_date = data.installment?.payment_date
                                }
                                return install;
                            });
                            extra.installments = installments;
                            this.setState({extra});
                            Alert(false);
                        });
                    }
                },
                {
                    label: 'Não quitar',
                    close: true
                }
            ];
        }

        Alert(alert_object);
    }
    save_school_report = () => {
        const {Alert}: any = this.props;
        Alert({
            title: 'Salvar Boletim',
            message: `Deseja lançar as notas do aluno(a) agora?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: async (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        let {data_view, document}: any = this.state;
                        const {reports}: any = data_view;
                        const student: any = document._id;
                        await Service.getStudentsService().school_report({student, reports});
                        props.close();
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }
    save_school_assessment = () => {
        const {Alert}: any = this.props;
        Alert({
            title: 'Salvar Avaliação',
            message: `Deseja lançar as avaliações do aluno(a) agora?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: async (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        let {data_view, document}: any = this.state;
                        const {assessments}: any = data_view;
                        const student: any = document._id;
                        await Service.getStudentsService().school_assessment({student, assessments});
                        props.close();
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }
    get_due_date = (date: any) => {
        if (!date?.payment_date) return false;
        return `${Utils.formatDateString(date?.payment_date, true)}`
    }
    check_paid_installment = () => {
        const {extra}: any = this.state;
        let {installments}: any = extra;
        let current_date: any = new Date(CurrentDate);
        let defeated: any = [];
        installments.filter((f: any) => f.status === 0).map((install: any) => {
            let due_date: any = install?.due_date.split('T')[0];
            if (current_date.toISOString().split('T')[0] > due_date) {
                defeated.push(install);
            }
            return install;
        });
        return defeated;
    }
    get_expired_days = (installment: any) => {
        let current_date: any = new Date(CurrentDate);
        let due_date: any = new Date(installment?.due_date);
        let diffmonth: any = (current_date - due_date);
        let diffdays: any = diffmonth / (1000 * 60 * 60 * 24);
        return diffdays < 0 ? false : parseInt(diffdays);
    }
    get_expired = (installment: any) => {
        const {document}: any = this.state;
        const {_unit}: any = document;

        if (_unit?.amount_delay > 0) {
            let current_date: any = new Date(CurrentDate);
            let due_date: any = new Date(installment?.due_date);
            let diffmonth: any = (current_date - due_date);
            let diffdays: any = diffmonth / (1000 * 60 * 60 * 24);
            return diffdays < 0 ? false : parseInt(diffdays);
        } else {
            return 0;
        }
    }
    check_amount_delay_value = (install: any) => {
        const {document}: any = this.state;
        const {_unit}: any = document;
        if (_unit?.amount_delay > 0) {
            let delay: any = this.get_expired(install);
            const value: any = (_unit?.amount_delay * delay);
            if (document?._unit?.amount_delay > 0) {
                return (install?.amount + value);
            } else {
                return install?.amount;
            }
        } else {
            return install?.amount;
        }
    }
    check_amount_delay = (install: any) => {
        const {document}: any = this.state;
        const {_unit}: any = document;
        let delay: any = this.get_expired(install);
        const value: any = (_unit?.amount_delay * delay);
        if (document?._unit?.amount_delay > 0) {
            return (<small>Valor da multa {Utils.formatValue(value)} e valor atualizado com multa: {Utils.formatValue(install?.amount + value)}</small>);
        } else {
            return null;
        }
    }
    set_status = (install: any) => {
        let check_defeated: any = this.check_paid_installment();
        let name: any = 'aberta';
        let status: any = 0;
        if (install?.status === 1) {
            name = 'paga';
            status = 1;
        } else if (check_defeated.some((s: any) => s?._id === install._id)) {
            name = 'vencida';
            status = 2;
        }
        return (<label data-name={name} data-status={status}/>)
    }
    set_value = (target: any) => {
        let {data_view}: any = this.state;
        let {Alert}: any = this.props;
        if (!data_view.reports) {
            data_view.reports = {};
        }
        if (target.value > 10) {
            Alert({
                title: 'Valor não permitido',
                message: 'O valor da nota deve está entre 0 e 10 para ser válido!'
            })
            data_view.reports[target.name] = '';
            return;
        }
        data_view.reports[target.name] = target.value;
        this.setState({data_view});
    }
    check_value = (bim: any) => {
        let {data_view}: any = this.state;
        try {
            return data_view?.reports[bim];
        } catch (error: any) {
            return '';
        }
    }
    set_value_ass = (target: any) => {
        let {data_view}: any = this.state;
        if (!data_view.assessments) {
            data_view.assessments = {};
        }
        data_view.assessments[target.name] = target.value.toUpperCase();
        this.setState({data_view});
    }
    check_value_ass = (ass: any) => {
        let {data_view}: any = this.state;
        try {
            return data_view?.assessments[ass];
        } catch (error: any) {
            return '';
        }
    }
    calculate_result = (subject: any) => {
        try {
            let {data_view}: any = this.state;
            const {reports}: any = data_view;
            const total: any = [];
            Object.keys(reports).map((f: any) => {
                if (f.indexOf(subject) !== -1 && reports[f] !== "") {
                    total.push(parseFloat(reports[f].replace(',', '.')));
                }
                return f;
            });
            let note: any = total.reduce((a: any, b: any) => (a + b)) / total.length;
            note = note.toFixed(2);
            return note;
        } catch (error: any) {
            return 0;
        }
    }
    sum_total_lack = (s: any, p: any) => {
        const {extra}: any = this.state;
        const {frequency}: any = extra;
        return frequency.filter((f: any) => f?.subject === s?._id && f?.presence === p).length || '-';
    }
    sum_total_lack_report = (s: any, p: any) => {
        const {extra}: any = this.state;
        const {frequency}: any = extra;
        return frequency.filter((f: any) => f?.subject === s?._id && f?.presence === p).length || '-';
    }
    show_presence = (s: any, i: any) => {
        let currente_date: any = new Date(Utils.getDateTimeCurrent());
        let get_year: any = currente_date.getFullYear().toString();
        let get_day: any = i < 9 ? `0${(i + 1)}` : (i + 1);
        let mouth: any = `${get_year}-${get_day}`;
        const {extra}: any = this.state;
        const {frequency}: any = extra;
        return frequency.filter((f: any) => f?.date === mouth && f?.subject === s?._id && !f?.presence).length || '-';
    }
    clear_installments = () => {
        const {Alert}: any = this.props;
        const {extra, document}: any = this.state;
        Alert({
            title: 'Excluir parcelas',
            message: `Deseja excluir todas as parcelas deste aluno?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde excluindo parcelas...', buttons: []});
                        this.setState({extra});
                        Service.getStudentsService().clear_installments({id: document._id}).then((result: any) => {
                            Alert(false);
                            extra.installments = [];
                            this.setState({extra});
                        });
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });

    }
    set_behav_value = (target: any) => {
        let {data_view}: any = this.state;
        if (!data_view.behavior) {
            data_view.behavior = {};
        }
        data_view.behavior[target.name] = target.value;
        this.setState({data_view});
    }
    set_emoji = (emoji: any) => {
        let {data_view}: any = this.state;
        if (!data_view.behavior) {
            data_view.behavior = {};
        }
        data_view.behavior['emoji'] = emoji;
        this.setState({data_view});
    }
    save_behavior = () => {
        let {data_view, document}: any = this.state;
        let {Alert}: any = this.props;
        if (!Object.keys(data_view).includes('behavior')) {
            Alert({
                title: 'Comportamento',
                message: 'Selecione um emoji para salvar o comportamento de hoje do aluno(a)'
            })
            return;
        }
        data_view.behavior['student'] = document?._id;
        Alert({
            title: 'Salvar comportamento',
            message: `Deseja salvar agora o comportamento?`,
            buttons: [
                {
                    label: 'Sim',
                    callback: async (props: any) => {
                        Alert({icon: 'wait', message: 'Aguarde...', buttons: []})
                        let {data_view}: any = this.state;
                        const {behavior}: any = data_view;
                        Service.getStudentsService().behavior(behavior).then((response: any) => {
                            const {data}: any = response;
                            if (response.status === 200) {
                                Alert({
                                    title: 'Comportamento registrado',
                                    message: 'O comportamento de hoje foi registrado com sucesso!',
                                });
                            } else {
                                Alert({...data})
                            }

                            let {data_view}: any = this.state;
                            data_view.behavior = {
                                emoji: null,
                                note: '',
                                student: '',
                            };
                            this.setState({data_view});

                        }).catch((error: any) => {
                            const {data}: any = error;
                            Alert({...data})
                        });
                    }
                },
                {
                    label: 'Não',
                    close: true
                }
            ]
        });
    }
    print_report = (printer: any) => {
        window.open(`${this.props.location.pathname}?report=true`)
    }
    check_active = (emoji: any) => {
        let {data_view}: any = this.state;
        if (!emoji) return false;
        if (!data_view?.behavior) return false;
        return emoji?.aliases[0] === data_view?.behavior['emoji']?.aliases[0];
    }

    render() {
        const item: any = this.props.children;
        const {loading, document, extra, tab, data_view}: any = this.state;
        const {subjects, installments, behaviors, assessments}: any = extra;
        const {months, bimistres}: any = data_view;
        return (<Layout>
            <>
                {loading && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
                </div>}

                {!loading && !this.getPermissions('view') && <div className={styles.MenuEmpty}>
                    <img src={require('../../assets/denied.svg')} alt='Forbiden'/>
                    <br/>
                    <label>Seus previlégios de usuário não lhe dão acesso para visualizar</label>
                    <br/>
                    <Button label='VOLTAR' data-name="save" icon='times' action={() => this.cancel()}/>
                </div>}

                {!loading && this.getPermissions('view') && <div className={styles.ViewContain}>
                    {this.props.children && React.cloneElement(item, {...this.props, ...this.state})}
                    {!this.props.children && <>
                        <section>
                            <h1><i className='fa-solid fa-id-card'/> GERENCIAR DADOS DO ALUNO</h1>
                            <div group-button='true' style={{gap: 15, display: 'flex'}}>
                                <Button label='VOLTAR' data-name="save" icon='arrow-left' action={() => this.cancel()}/>
                                {tab === 2 && <Button label='SALVAR' icon={'check'} action={() => this.save_school_report()}/>}
                                {tab === 3 && <Button label='SALVAR' icon={'check'} action={() => this.save_behavior()}/>}
                                {tab === 4 && <Button label='SALVAR' icon={'check'} action={() => this.save_school_assessment()}/>}
                            </div>
                        </section>
                        {<section data-view={this.props.module}>
                            <div className={style.Student}>
                                <hgroup>
                                    <div>
                                        <figure style={{backgroundImage: `url(${Utils.thumbnail(document)})`}}>
                                            {installments.length > 0 && <label data-status={this.check_paid_installment().length <= 0}/>}
                                            {document?.terms && <i className='fa-solid fa-circle-check'/>}
                                        </figure>
                                        <span>
                                            <h1>{document?.name}</h1>
                                            <label><b>Data Nasc.: </b> {Utils.formatDateString(document?.birthday)}</label>
                                            {document?.responsible_name && <label><b>Responsável: </b> {document?.responsible_name}</label>}
                                            {document?.responsible_email && <label><b>Email: </b> {document?.responsible_email}</label>}
                                            {document?.responsible_phone && <label><b>Contato: </b> {document?.responsible_phone}</label>}
                                        </span>
                                    </div>
                                </hgroup>
                                <hgroup>
                                    <h1>MAT: {document?.register} </h1>
                                    <div>
                                        <span><b>Serie: </b> {document?._classe?.name}</span>
                                        <span><b>Turma: </b> {document?.classe}</span>
                                    </div>
                                    <span><b>Turno: </b> {document?._period?.name}</span>
                                    <span><b>Unidade: </b> {document?._unit?.name}</span>
                                    <span><b>Professor(a): </b> {document?._teacher?.name}</span>
                                </hgroup>
                            </div>
                            <div className={style.StudentTabs}>
                                <span data-active={tab === 0} onClick={() => this.set_tab(0)}><strong>FINANCEIRO</strong></span>
                                <span data-active={tab === 1} onClick={() => this.set_tab(1)}><strong>FREQUÊNCIA</strong></span>
                                {!document?._category?.assessment && <span data-active={tab === 2} onClick={() => this.set_tab(2)}><strong>BOLETIM</strong></span>}
                                {document?._category?.behavior && <span data-active={tab === 3} onClick={() => this.set_tab(3)}><strong>COMPORTAMENTO</strong></span>}
                                {document?._category?.assessment && <span data-active={tab === 4} onClick={() => this.set_tab(4)}><strong>AVALIAÇÃO</strong></span>}
                            </div>
                            <div className={style.StudentTabsContain}>
                                {tab === 0 && <>
                                    <div className={style.Financial}>

                                        {installments.length <= 0 && <>
                                            <img src={require('../../assets/payment.svg')} alt='Payment'/>
                                            <span>Este aluno(a) não possui mensalidades, deseja criá-las agora?</span>
                                            <Button label='Gerar parcelas' icon='file-invoice' action={() => this.generate_parcel()}/>
                                        </>}

                                        {installments.length > 0 && <div className={style.Installments}>

                                            <hgroup>
                                                <span>
                                                    <strong>Abertas</strong>
                                                    <label>{installments.filter((f: any) => f?.status === 0).length} parcelas</label>
                                                </span>
                                                <span>
                                                    <strong>Pagas</strong>
                                                    <label>{installments.filter((f: any) => f?.status === 1).length} parcelas</label>
                                                </span>
                                                <span>
                                                    <strong>Vencidas</strong>
                                                    <label>{installments.filter((f: any) => f?.status === 0 && this.check_paid_installment().some((s: any) => s._id === f?._id)).length} parcelas
                                                        {document?._unit?.amount_delay > 0 && <small>Multa por atraso: {Utils.formatValue(document?._unit?.amount_delay)}/{document?._unit?.type_delay}</small>}
                                                    </label>
                                                </span>
                                                <span>
                                                    <strong>Pago</strong>
                                                    <label>{this.install_total_values(installments, 'paid')}</label>
                                                </span>
                                                <span>
                                                    <strong>Restante</strong>
                                                    <label>{this.install_total_values(installments, 'remaining')}</label>
                                                </span>
                                                <span>
                                                    <strong>Total</strong>
                                                    <label>{this.install_total_values(installments, 'total')}</label>
                                                </span>
                                            </hgroup>

                                            <hgroup style={{flexDirection: 'column'}}>
                                                <ul>
                                                    {installments.map((install: any, i: any) => <li key={i}>
                                                        <span>{this.get_date(install)}</span>
                                                        <div>
                                                            <article>
                                                                <strong>{install?.amount === 0 ? 'Bolsa integral' : Utils.formatValue(install?.amount)}</strong>
                                                                {this.set_status(install)}
                                                            </article>
                                                        </div>

                                                        {this.get_expired_days(install) && install?.status !== 1 &&
                                                            <figure>
                                                                <label>
                                                                    <small>Vencido em: {Utils.formatDateString(install?.due_date)} à {this.get_expired_days(install)} Dias</small>
                                                                    {this.check_amount_delay(install)}
                                                                </label>
                                                            </figure>}

                                                        <figure>
                                                            {install?.status === 1 && <>
                                                                <label>Pago em: {this.get_due_date(install) || '00/00/0000'}</label>
                                                                <span>Valor pago: <b>{Utils.formatValue(this.check_amount_delay_value(install) || 0)}</b></span>
                                                            </>}
                                                        </figure>

                                                        {install?.status === 0 && <hgroup>
                                                            <button onClick={() => this.down_payment(install)}>Dar baixa <i className='fa fa-download'/></button>
                                                        </hgroup>}
                                                    </li>)}
                                                </ul>

                                            </hgroup>
                                            <div>
                                                <Button theme={'red'} label='Excluir parcelas' icon='trash' action={() => this.clear_installments()}/>
                                            </div>
                                        </div>}
                                    </div>
                                </>}

                                {tab === 1 && subjects && <>
                                    <div className={style.Frequency}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Disciplinas</th>
                                                {months.map((b: any, i: any) => <th key={i}>{b?.label}</th>)}
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <th></th>
                                                {Array.from({length: 12}, (e: any, i: any) => <th key={i}>F</th>)}
                                                <th>F</th>
                                                <th>P</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {subjects.map((subject: any, s: any) => <tr key={s}>
                                                <td>
                                                    {subject?.name}
                                                </td>
                                                {Array.from({length: 12}, (e: any, i: any) => <td key={i}>{this.show_presence(subject, i)}</td>)}
                                                <td>{this.sum_total_lack(subject, false)}</td>
                                                <td>{this.sum_total_lack(subject, true)}</td>
                                            </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                {tab === 2 && !document?._category?.assessment && <>
                                    <div className={style.Subjects}>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Disciplinas</th>
                                                {bimistres.map((b: any, i: any) => <th key={i}>{b?.name}</th>)}
                                                <th>Resultado</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {subjects.length > 0 && subjects.map((subject: any, s: any) => <tr key={s}>
                                                <td>
                                                    {subject?.name}
                                                </td>
                                                {bimistres.map((b: any, i: any) => <td key={i}>
                                                    <input name={`bim_${(b?.id)}_${subject?._id}`} value={this.check_value(`bim_${(b?.id)}_${subject?._id}`)} onChange={(e: any) => this.set_value(e.target)} data-id={subject?._id}/>
                                                </td>)}
                                                <td>{this.calculate_result(subject?._id)}</td>
                                            </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                {tab === 3 && document?._category?.behavior && <>
                                    <div className={style.Behaviors}>
                                        <div>Registrar o comportamento dos alunos no sistema escolar é importante para acompanhar e avaliar seu desempenho, identificar necessidades de desenvolvimento, estabelecer um histórico escolar e adaptar o ensino às necessidades individuais de cada estudante.<br/><br/>É fundamental que a escola mantenha esse registro atualizado e preciso para promover uma educação de qualidade e desenvolvimento pleno dos estudantes.</div>
                                        {behaviors && <ul>
                                            {behaviors.map((behav: any, b: any) => <li key={b} onClick={() => this.set_emoji(behav?.emoji)} data-active={this.check_active(behav.emoji)}>
                                                <figure>{behav?.emoji?.emoji}</figure>
                                                <span>
                                                    <b>{behav?.name}</b>
                                                    <small>{behav?.description}</small>
                                                </span>
                                            </li>)}
                                        </ul>}
                                        <fieldset>
                                            <label>Informe alguma nota referente ao dia.</label>
                                            <textarea rows={5} name='note' value={data_view?.behavior?.note} onChange={(e: any) => this.set_behav_value(e.target)}/>
                                        </fieldset>
                                    </div>
                                </>}

                                {tab === 4 && document?._category?.assessment && <>

                                    <div className={style.Assessments}>
                                        {assessments.map((ass: any, a: any) => <table key={a}>
                                            <caption>{ass?.name}</caption>
                                            <thead>
                                            <tr>
                                                <th>Disciplinas</th>
                                                {bimistres.map((b: any, i: any) => <th key={i}>{b?.name}</th>)}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {ass.items.length > 0 && ass.items.map((asse: any, s: any) => <tr key={s}>
                                                <td>
                                                    {asse?.name}
                                                </td>
                                                {bimistres.map((b: any, i: any) => <td key={i}>
                                                    <input name={`ass_${(b?.id)}_${asse?._id}`} value={this.check_value_ass(`ass_${(b?.id)}_${asse?._id}`)} onChange={(e: any) => this.set_value_ass(e.target)} data-id={asse?._id}/>
                                                </td>)}
                                            </tr>)}
                                            </tbody>
                                        </table>)}
                                    </div>

                                </>}

                            </div>
                        </section>}
                    </>}
                </div>}
            </>
        </Layout>);
    }
}

const mapStateToProps = (state: any) => ({...state.admin, ...state.auth,});
const mapDispatchToProps = (dispatch: any) => bindActionCreators({...adminActionCreators, ...authActionCreators,}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);