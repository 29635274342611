/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import Storage from '../../api/utils/Storage';
import jwtDecode from "jwt-decode";

const initialState = {
    auth: !!Storage.get('auth'),
    user: Storage.get('auth') ? jwtDecode(Storage.get('auth')) : null
};

/**
 * REDUCE AUTH
 * @param state
 * @param action
 * @returns {{auth: *}|{auth: {}}}
 */
const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case 'SET-AUTH':
            return {...state, auth: action.auth};
        case 'SET-USER':
            return {...state, user: action.user};
        default:
            return state;
    }
};

export default auth;
