/**
 * CONTRACTS PAGE INDEX
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../layout';
import {List} from '../../components/Crud';
import {Select} from "../../components/Fields";

/**
 * COMPONENT PAGE CONTRACTS
 */
class Page extends List {
    addFilter = () => {
        const {filter, extra}: any = this.state;
        return (<>
            {extra?.entity.length > 0 && <Select value={filter.entity} name='entity' label_option='Entidade' items={extra} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'entity', value: ''})}/>}
            <Select value={filter.status} name='status' label_option='Status' items={extra?.status} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'status', value: ''})}/>
        </>);
    }

    render() {
        return (<Layout>
            {super.render()}
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);