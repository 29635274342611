/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef} from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import {Service} from "../../../api";
import {Utils} from "../../../api/utils";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'input',
        loader: false,
        data: []
    }

    fields = createRef<any>();

    componentDidMount() {
        this.setState({type: this.props.type});
    }

    search_google() {
        const address: any = this.props.value;
        const {Alert}: any = this.props;
        if (address === "") {
            Alert({
                title: 'Campo vazio',
                message: 'Para busca um enderço preencha o campo com o nome da rua e número para continuar com a busca!'

            });
            return;
        }

        (async () => {
            this.setState({loader: true});
            const {Alert, module, name}: any = this.props;
            const classService: any = Service;
            let moduleName = Utils.ucFirst(module);
            const {data}: any = await classService[`get${moduleName}Service`]().google_address(address);
            if (data.length > 0) {
                this.props.populate({data, name})
                this.setState({data, loader: false});
            } else {
                Alert({...data});
                this.setState({loader: false});
            }
        })();
    }

    search(e: any) {
        const cep: any = e.value;
        if (cep.length === 8) {
            (async () => {
                this.setState({loader: true});
                const {Alert, module, name}: any = this.props;
                const classService: any = Service;
                let moduleName = Utils.ucFirst(module);
                const {data}: any = await classService[`get${moduleName}Service`]().search_cep(cep);
                if (data.status) {
                    this.props.populate({data, name})
                    this.setState({loader: false});
                } else {
                    Alert({...data});
                    this.setState({loader: false});
                }
            })();
        }
    }

    set_value_address = (opt: any, name: any) => {
        this.props.set_value_address(opt, this.props.name);
        this.setState({data: []});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    /**
     * POPULATE THE FIELD WHEN EDITING
     */

    render() {
        const {cep, street, addOn, neighborhood, locality, uf, number}: any = this.props?.value || {};
        const {loader, data}: any = this.state;
        const {name}: any = this.props;
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        {this.props.google && <>
                            <div>
                                <input
                                    name={this.props.name}
                                    id={this.props.name}
                                    autoComplete='false'
                                    readOnly={this.props.readonly || false}
                                    checked={this.props.type === 'checkbox' ? this.props.value : false}
                                    required={this.props.required}
                                    disabled={this.props.disabled}
                                    ref={this.props.reference}
                                    type={this.state.type}
                                    placeholder={this.props.placeholder}
                                    value={this.props.value || ''}
                                    onChange={this.props.change}
                                    data-theme={this.props.theme ? this.props.theme : 'default'}
                                />
                                <button type='button' onClick={() => this.search_google()}>BUSCAR</button>
                            </div>
                            {data?.length > 0 && <hgroup>
                                <ul id={`list-${this.props.name}`}>
                                    {data?.map((opt: any, x: any) => <li key={x} onClick={() => this.set_value_address(opt, this.props.name)}>{opt?.description}</li>)}
                                </ul>
                            </hgroup>}
                        </>}
                        {!this.props.google && <>
                            {loader && <span>
                        <label><i className='fal fa-sync fa-spin'/>Pesquisando aguarde...</label>
                        </span>}
                            <fieldset ref={this.fields} disabled={loader}>
                                <div>
                                    <input placeholder='CEP' name='cep' value={cep} data-parent={name} onChange={this.props.change} maxLength={8} onKeyUp={(e: any) => this.search(e.target)}/>
                                </div>
                                <div>
                                    <input placeholder='Logradouro' name='street' value={street} data-parent={name} onChange={this.props.change}/>
                                </div>
                                <div>
                                    <input placeholder='Nº' name='number' value={number} data-parent={name} onChange={this.props.change}/>
                                </div>
                                <div>
                                    <input placeholder='Complemento' name='addOn' value={addOn} data-parent={name} onChange={this.props.change}/>
                                </div>
                                <div>
                                    <input placeholder='Cidade' name='locality' value={locality} data-parent={name} onChange={this.props.change}/>
                                </div>
                                <div>
                                    <input placeholder='Bairro' name='neighborhood' value={neighborhood} data-parent={name} onChange={this.props.change}/>
                                </div>
                                <div>
                                    <input placeholder='Estado' name='uf' value={uf} data-parent={name} onChange={this.props.change}/>
                                </div>
                            </fieldset>
                        </>}
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
;