/**
 * HISTORY PAGE VIEW
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../store/actions/adminActionCreators";
import * as authActionCreators from "../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {View} from '../../components/Crud';
import ReportPrint from "./report";
import HistorytPrint from "./history";

/**
 * COMPONENT VIEW HISTORY
 */
class Page extends View {


    render() {
        const {search}: any = this.props.location;
        if (search) {
            const obj_search = search.split("=");
            const data_search: any = {[obj_search[0].replace('?', '')]: Boolean(obj_search[1])}
            return (<>
                {data_search?.report && <ReportPrint {...this.state} {...this.props}/>}
                {data_search?.history && <HistorytPrint {...this.state} {...this.props}/>}
            </>);
        }

        return (<></>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Page);