/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React, {createRef, Component} from 'react';
import {Storage, Utils} from '../../api/utils';
import {
    Button,
    Input,
    Select,
    Multi,
    File,
    Money,
    Checkbox,
    Icon,
    Availability,
    List,
    Address,
    Editor,
    Tags,
    Emoji
} from '../Fields';
import {Emitter, Service} from '../../api';
import styles from './style.module.scss';

/**
 * CLASS LIST ABSTRACT
 */
export class Props {
    page?: any;
    location?: any;
    menus?: any;
    totalPages?: any;
    history?: any;
    fields?: any;
    Alert?: any;
    match?: any;
    setModule?: any;
    module?: any;
    setFields?: any;
    address?: any;
    bank?: any;
    setAddress?: any;
    setData?: any;
    readonly?: any;
    populateFields?: any;
    permissions?: any;
    entity?: any;
}

export default class Form extends Component<Props, any> {

    /**
     * SET STATES
     */
    state = {
        model: [],
        allowed: false,
        module: '',
        all_data: {},
        extra: {},
        fields: {},
        loading: false,
        btnBackHidden: true,
        btnSaveHidden: true,
        permissions: false,
        entity: false,
        loader: true
    }

    /**
     * REF FORM
     */
    private form = createRef<any>();
    base_model: any;
    module: any;
    original_module: any;
    title: any;
    icon: any;

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.permissions !== prevProps.permissions) {
            this.setState({permissions: this.props.permissions});
        }

        if (this.props.entity !== prevProps.entity) {
            this.setState({entity: this.props.entity});
        }
    }


    /**
     * ONLOAD COMPONENT
     */
    componentDidMount() {
        try {
            let module: any = Utils.ucFirst(this.props.location.pathname.split('/')[1]);
            Promise.all([this.getModel(module)]).then(async (response: any) => {
                setTimeout(async () => {
                    let [model]: any = response;
                    let model_entity: any = {
                        id: 'entity',
                        label: 'Entidade',
                        list: false,
                        form: true,
                        required: true,
                        service: {name: 'entities', field: 'name', extra: true},
                        type: 'select'
                    }
                    model.push(model_entity);
                    this.base_model = model;
                    this.module = module;
                    this.original_module = module.toLowerCase();
                    const {permissions}: any = this.props;
                    this.props.setModule(String(module).toLocaleLowerCase());
                    module = this.replace_module();
                    model = this.filter_model(model);
                    this.setState({module, permissions});

                    if (this.props.match.params.id) {
                        const {data} = await this.getService().get(this.props.match.params.id);
                        if (data?.documents?.password) {
                            delete data?.documents?.password;
                        }
                        this.props.setData(data.documents);
                        this.props.populateFields(data.documents);
                        const {extra}: any = data;
                        if (extra) {
                            this.setState({extra});
                        }

                        try {
                            if (extra.excludes.length > 0) {
                                model = model.filter((r: any) => !extra?.excludes.includes(r?.id))
                            }
                        } catch (error: any) {
                        }

                        this.setState({model, fields: data.documents, loader: false});
                    } else {
                        const {data} = await this.getService().get_selects();
                        const {extra}: any = data;

                        if (extra) {
                            this.setState({extra});
                        }

                        try {
                            if (extra.excludes.length > 0) {
                                model = model.filter((r: any) => !extra?.excludes.includes(r?.id))
                            }
                        } catch (error: any) {
                        }

                        const fields: any = {};
                        Object.keys(model).map((fld: any) => {
                            if (model[fld].type_format === 'array') {
                                fields[model[fld].id] = [];
                            } else if (model[fld].type_format === 'object') {
                                fields[model[fld].id] = {};
                            } else {
                                fields[model[fld].id] = '';
                            }
                            return fld;
                        });

                        this.props.populateFields(fields);
                        this.setState({model, fields, loader: false});
                    }
                    this.accessibility();
                }, 100);
            });
        } catch (error: any) {
            this.exceptError(error);
        }
    }

    /**
     * CAPTURE ERROR
     * @param error
     */
    exceptError(error: any) {
        const {Alert}: any = this.props;
        Alert({
            title: 'Error debug view',
            debug: true,
            autoSize: '80%',
            message: error.message
        });
        this.setState({loading: false});
    }

    /**
     * GET MODEL DEFAULT
     */
    async getModel(module: any = null) {
        try {
            const guid: any = Storage.exists('entity', '');
            const [client]: any = guid;

            if (module === 'Account') {
                module = 'User';
            }

            const core_control: any = ['api', 'audit', 'dashboard', 'entities', 'entity', 'login', 'module', 'parameter', 'plugins', 'profile', 'register', 'settings', 'user'];
            const path_core: any = core_control.includes(module.toLowerCase());

            let model: any = (await import(`../../api/models/${path_core ? 'core/' : ''}${module}`)).default;
            try {
                model = (await import(`../../api/models/${path_core ? 'core/' : ''}${client}/${module}`)).default;
            } catch (error: any) {
            }
            return model;
        } catch (error: any) {
            console.log(error);
        }
    }

    replace_module = () => {
        return this.module;
    }

    filter_model = (model: any) => {
        const {permissions, entity}: any = this.state;
        if (!permissions.entities?.isAdmin) {
            let remove_fields: any = ['key_google', 'public_vapidkey', 'private_vapidkey', 'entity']
            model = model.filter((m: any) => !remove_fields.includes(m?.id));
        }

        if (['module', 'entities', 'plugins', 'api'].includes(this.original_module)) {
            let remove_fields: any = ['entity']
            model = model.filter((m: any) => !remove_fields.includes(m?.id));
        }

        if (entity?.plugins) {
            if (entity?.plugins?.store_no_category) {
                let remove_fields: any = ['category']
                model = model.filter((m: any) => !remove_fields.includes(m?.id));
            }
        }

        return model;
    }

    /**
     * ACCESSIBILITY
     */
    accessibility = () => {
        document.onkeydown = (e: any) => {

            if (e.key.toLowerCase() === 'c' && e.shiftKey) {
                if (e.target.type === undefined) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.cancel();
                }
            }
            if (e.key.toLowerCase() === 's' && e.shiftKey) {
                if (e.target.type === undefined) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.save();
                }
            }
        };
    }

    /**
     * GET SERVICE MODULE
     * @returns
     */
    getService = () => {
        const classService: any = Service;
        return classService[`get${this.state.module.replace('-', '')}Service`]();
    }

    /**
     * GET TITLE MODULE
     */
    getTitle = () => {
        const {menus} = this.props;
        const {module} = this.state;
        if (module) {
            const {id} = this.props.match.params;
            if (menus.length <= 0) return '';
            const currentModule = menus.find((m: any) => m?.to === module.toLocaleLowerCase());

            /**
             * RENDER NAME TITLE
             */
            return (<h1>
                <i className={`fal fa-${this.icon ? this.icon : `${id ? 'edit' : 'plus'}`}`}/>
                <span>{this.title ? this.title : `${id ? 'Editar' : 'Adicionar'} ${currentModule?.name}`}</span>
            </h1>);
        }
    }

    /**
     * VALIDATE ALL FIELDS IN FORM CONTAIN ATTRIBUTE REQUIRED
     * @returns
     */
    validate = (): boolean => {
        const fields: any = this.state.fields;
        /**
         * GET ALL FILDS IN FORM
         */
        let total: number = this.form.current.querySelectorAll("input:required, select:required, textarea:required").length;
        let validated: number = 0;

        /**
         * SHOW FORM IS EMPTY REQUIRED
         */
        let allFields: any = this.form.current.querySelectorAll("input:required, select:required, textarea:required")

        if (Object.values(allFields).length > 0) {

            Object.values(allFields).reverse().map((field: any, index: number) => {
                if (fields._id && field.type === 'password') {
                    validated++;
                } else {
                    if (field.value === '') {
                        if (field.parentNode.tagName === 'FIELDSET') {
                            field.parentNode.parentNode.setAttribute('data-empty', true);
                        } else {
                            field.parentNode.setAttribute('data-empty', true);
                        }
                        field.focus();
                        validated--;
                    } else if (!this.validateType(field)) {
                        if (field.parentNode.tagName === 'FIELDSET') {
                            field.parentNode.parentNode.setAttribute('data-empty', true);
                        } else {
                            field.parentNode.setAttribute('data-empty', true);
                        }

                        field.focus();
                        validated--;
                    } else {
                        if (field.parentNode.tagName === 'FIELDSET') {
                            field.parentNode.parentNode.removeAttribute('data-empty');
                        } else {
                            field.parentNode.removeAttribute('data-empty');
                        }
                        validated++;
                    }
                }
                return field;
            });
            return (total === validated);
        } else {
            return true;
        }
    }

    /**
     * VALIDATE OF TYPE FIELD
     */
    validateType = (field: any) => {
        if (field.type === 'email') {
            const mail = new RegExp(/^[A-Za-z0-9_\-\\.]+@[A-Za-z0-9_\-\\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
            return mail.test(field.value);
        } else {
            return true;
        }
    }

    /**
     * CONVERT FIELDS FORMATS
     * @param fields
     */
    convertFields = (fields: any) => {
        const {model}: any = this.state;
        const newFields: any = {}
        Object.keys(fields).map((m: any) => {
            let field = model.find((fl: any) => fl.id === m);
            if (field !== undefined) {
                if (field.type === 'money') {
                    newFields[m] = fields[field.id];
                }
                if (field.type === 'file') {
                    delete fields[field.id].data;
                    newFields[m] = fields[field.id];
                }
            }
            return m;
        });
        fields = {...fields, ...newFields};
        return fields;
    }

    hiddeButton = (btn: any, status: Boolean) => {
        this.setState({[`btn${btn}Hidden`]: status})
    }

    /**
     * SAVE FORM
     */
    save = async () => {
        const {Alert} = this.props;
        let {fields}: any = this.state;

        if (this.validate()) {
            const result: any = await this.getService().save(this.convertFields(fields));
            const {id} = this.props.match.params;
            if (result.status === 200) {
                if (this.callbackSave()) {
                    Alert({
                        type: 'success',
                        title: id ? 'Editando' : 'Cadastrando',
                        message: id ? 'Registro editado com sucesso' : 'Registro cadastrado com sucesso',
                        buttons: [
                            {
                                label: 'Novo',
                                callback: (props: any) => {
                                    this.newItem();
                                    this.props.Alert(false);
                                },
                            },
                            {
                                label: 'Listar',
                                callback: (props: any) => {
                                    this.cancel();
                                    this.props.Alert(false);
                                },
                            }
                        ]
                    });
                }
            } else {
                if (result.data.message) {
                    Alert({
                        type: 'error',
                        message: result.data.message
                    });
                } else {
                    let errors = '';
                    result.data.writeErrors.map((error: any) => {
                        errors += `${error.errmsg}<br/>`;
                        return error;
                    });
                    Alert({
                        type: 'error',
                        message: errors
                    });
                }
            }
        }
    }

    callbackSave = () => {
        return true;
    }

    /**
     * CANCEL FORM
     */
    cancel = () => {
        this.props.setFields({});
        this.props.history.push(`/${this.state.module.toLocaleLowerCase()}`);
    }

    /**
     * NEW ITEM
     */
    newItem = () => {
        this.props.setFields({});
        const fields: any = Object.keys(this.state.fields);
        if (fields.includes('cover') || fields.includes('logo') || fields.includes('image')) {
            ['cover', 'logo', 'image'].map((img: any) => {
                Emitter.emit('CLEAR_FILE', null);
                return img;
            });
        }
        this.setState({fields: {}});
        this.props.history.push(`/${this.state.module.toLocaleLowerCase()}/form`);
    }

    /**
     * SET VALUE FIELD
     * @param target
     * @param item
     */
    setValue = (target: any, item: any) => {
        const {fields}: any = this.state;
        if (target?.type === 'file') {
            fields[target?.type] = target?.files[0];
            fields[target?.name] = {
                filename: target?.files[0].name,
                type: target?.files[0].type.trim(),
                size: target?.files[0].size,
                data: null
            };
        } else if (item.type === 'data-object') {
            if (!fields[item.id]) {
                fields[item.id] = {};
            }
            fields[item.id][target?.name] = target?.value;
        } else if (target?.type === 'icon') {
            fields[target?.name] = target?.value;
        } else {
            fields[target?.name] = target?.value;
        }

        this.setState({fields})
    }

    setVIdeo = (target: any) => {
        const fields: any = this.state.fields;
        fields[target.name] = target.value;
        this.setState({fields})
    }

    remove_video = (target: any) => {
        const fields: any = this.state.fields;
        fields[target.name] = '';
        this.props.populateFields(fields);
        this.setState({fields})
    }

    change_money = (value: any, name: any) => {
        const fields: any = this.state.fields;
        fields[name] = value;
        this.setState({fields})
    }

    addStore = (store: any) => {
        const fields: any = this.state.fields;
        if (!fields.stores) {
            fields.stores = [];
        }
        if (!fields.stores.includes(store._id)) {
            fields.stores.push(store._id);
        } else {
            fields.stores = fields.stores.filter((r: any) => r !== store._id);
        }
        this.setState({fields})
    }

    selectAllUnselect = (stores: any) => {
        const fields: any = this.state.fields;
        if (fields.stores.length > 0) {
            fields.stores = [];
        } else {
            fields.stores = stores.map((r: any) => r._id);
        }
        this.setState({fields})
    }

    updateAvailability = (data: any) => {
        const fields: any = this.state.fields;
        fields.availability = data;
        this.setState({fields})
    }

    /**
     * SET VALUE MONEY
     * @param target
     */
    setValueMoney = (target: any) => {
        const fields: any = this.state.fields;
        fields[target.name] = target.value;
        this.setState({fields});
    }

    /**
     * BLUR ACTIVE MONEY
     * @param target
     */
    blurMoney = (target: any) => {
        const fields: any = this.state.fields;
        let getValue = target.value.replace(/,/g, ".").replace(/,/g, ".")
        getValue = parseFloat(getValue.replace('R$', ''));
        fields[target.name] = getValue.toLocaleString('pt-BR',
            {
                style: 'currency',
                currency: 'BRL'
            }
        );
        this.setState({fields});
    }

    /**
     * SET VALUE FIELD SELECT
     * @param item
     */
    setValueSelect = (item: any) => {
        const fields: any = this.state.fields;
        if (item.target.multiple) {
            if (fields[item.target.name].length <= 0) {
                fields[item.target.name] = [];
            }
            fields[item.target.name][0] = item.target.value;
        } else {
            fields[item.target.name] = item.target.value;
        }
        this.setState({fields})
    }

    /**
     * SET VALUE FIELD CHECKBOX
     * @param item
     */
    setValueCheck = (item: any) => {
        const fields: any = this.state.fields;
        fields[item.name] = !fields[item.name];
        this.setState({fields})
    }

    /**
     * CLEAR SELECT
     * @param e
     */
    clearSelect = (e: any) => {
        const fields: any = this.state.fields;
        const name = e.parentNode.querySelector('select').name;
        fields[name] = "";
        this.setState({fields})
    }

    /**
     * GET PERMISSIONS
     * @param permission
     * @returns
     */
    getPermissions = (permission: any) => {
        const {permissions}: any = this.state;
        if (permissions) {
            if (this.original_module?.toLocaleLowerCase() === 'account') {
                return true;
            } else {
                const moduleName: any = this.state?.module?.toLocaleLowerCase();
                const permissionName = `can${Utils.ucFirst(permission)}`;
                if (this.props?.permissions[moduleName]) {
                    return this.props?.permissions[moduleName][permissionName];
                } else {
                    return false;
                }
            }
        }
    }

    /**
     * UPDATE FIELDS ADDRESS
     * @param data
     */
    updateAddress = (data: any) => {
        const fields: any = this.state.fields;

        if (!fields[data.target.dataset.parent]) {
            fields[data.target.dataset.parent] = {};
        }
        fields[data.target.dataset.parent][data.target.name] = data.target.value;
        this.setState({fields});
    }

    set_value_address = (adr: any, name: any) => {
        const fields: any = this.state.fields;
        fields[name] = adr?.description;
        this.setState({fields});
    }

    /**
     * POPULATE FIELDS OF ADDRESS
     * @param params
     */
    populateAddress = (params: any) => {
        let {fields}: any = this.state;
        fields[params?.name] = {
            cep: Utils.noAccents(params?.data?.cep),
            street: params?.data?.logradouro,
            addOn: params?.data?.complemento,
            number: '',
            neighborhood: params?.data?.bairro,
            locality: params?.data?.localidade,
            uf: params?.data?.uf
        };
        this.setState({fields});
    }

    /**
     * UPDATE VALUE TEXTEDITOR
     * @param value
     * @param item
     */
    setTextArea = (value: any, item: any) => {
        const fields: any = this.state.fields;
        fields[item.id] = value;
        this.setState({fields})
    }

    /**
     * POPULATE MULTI SELECT
     * @param name
     * @param data
     */
    populate_mult_select = (name: any, data: any) => {
        let {fields}: any = this.state;
        fields[name] = data;
        this.setState({fields}, () => this.callbackSelect(name, data));
    }

    /**
     * UPDATE FIELD ARRAY
     * @param value
     * @param item
     */
    updateArray = (value: any, item: any) => {
        const fields: any = this.state.fields;

        if (!fields[item.id]) {
            fields[item.id] = [];
        }

        if (item.unique) {
            if (fields[item.id].includes(value)) {
                fields[item.id] = [];
            } else {
                fields[item.id] = value;
            }

            this.setState({fields});
            return;
        }

        if (!fields[item.id].includes(value)) {
            fields[item.id].push(value);
        } else {
            fields[item.id] = fields[item.id].filter((i: any) => i !== value);
        }
        this.setState({fields});
    }

    /**
     * CALLBACK INVOKE BEFORE SELECT ITEM
     * @param name
     * @param data
     */
    callbackSelect = (name: any, data: any) => {
    }

    /**
     * GET GROUP IN MODEL LIST
     */
    get_groups = () => {
        const {model}: any = this.state;
        if (model.length <= 0) return [];
        let groups: any = model.filter((f: any) => f?.group).map((g: any) => g?.group);
        // @ts-ignore
        return [...new Map(groups.map(item => [item.id, item])).values()];
    }

    get_data_extra = (name: any) => {
        let {extra}: any = this.state;
        return extra[name] || [];
    }

    /**
     * SET VALUE FIELD
     * @param target
     */
    setValueDate = (target: any) => {
        const fields: any = this.state.fields;
        if (target.type === 'datepicker') {
            fields[target.name] = target.value;
        } else {
            fields[target.target.name] = target.target.value;
        }
        this.setState({fields})
    }
    setEmoji = (target: any, item: any) => {
        const {fields}: any = this.state;
        fields[item.id] = target;
        this.setState({fields});
    }

    /**
     * GET ALL FIELDS
     * @param item
     * @param index
     * @param fields
     */
    all_fields = (item: any, index: any, fields: any) => {
        try {
            return (<>
                {['text', 'email', 'password', 'number', 'color', 'tel', 'cel', 'textarea', 'data-object'].includes(item.type) &&
                    <Input
                        key={index}
                        name={item.id}
                        disabled={item.disabled}
                        rows={item.rows}
                        type={item.type || 'text'}
                        data_object={item.data_object}
                        readonly={item.readonly}
                        label={item.label}
                        required={item.required}
                        change={(e: any) => this.setValue(e.target, item)}
                        value={fields[item.id]}
                    />}

                {['date', 'datepicker', 'date_input'].includes(item.type) &&
                    <Input
                        key={index}
                        name={item.id}
                        disabled={item.disabled}
                        rows={item.rows}
                        readonly={item.readonly}
                        type={item.type}
                        label={item.label}
                        required={item.required}
                        change={(e: any) => this.setValueDate(e)}
                        value={fields[item.id]}
                    />}

                {['checkbox'].includes(item.type) && <Checkbox
                    key={index}
                    name={item.id}
                    disabled={item.disabled}
                    type={item.type}
                    label={item.label}
                    required={item.required}
                    change={(e: any) => this.setValueCheck(e.target)}
                    value={fields[item.id] || false}
                />}

                {item.type === 'money' && <Money
                    key={index}
                    name={item.id}
                    disabled={item.disabled}
                    type={item.type}
                    label={item.label}
                    required={item.required}
                    change_money={(value: any, name: any) => this.change_money(value, name)}
                    value={fields[item.id]}
                />}

                {['multi-select', 'select'].includes(item.type) &&
                    <Select
                        key={index}
                        name={item.id}
                        disabled={item.disabled}
                        clear={this.clearSelect}
                        {...item}
                        value={fields[item.id] || []}
                        required={item.required}
                        multi={item.multi}
                        data_extra={item?.service?.extra ? this.get_data_extra(item?.id) : []}
                        change={(e: any) => this.setValueSelect(e)}
                        populate={(name: any, data: any) => this.populate_mult_select(name, data)}
                        label={item.label}
                    />}

                {item.type === 'multi' && <>
                    <Multi
                        key={index}
                        name={item.id}
                        label={item.label}
                        required={item.required}
                        change={(e: any) => this.setValue(e.target, item)}
                        value={fields[item.id]}
                    />
                </>}

                {item.type === 'file' && <File
                    key={index}
                    name={item.id}
                    disabled={item.disabled}
                    label={item.label}
                    type={item.type}
                    avatar={item.avatar}
                    drag={item?.drag || false}
                    required={item.required}
                    change={(e: any) => this.setValue(e.target, item)}
                    value={fields[item?.id]}
                />}

                {['icon', 'social'].includes(item.type) && <Icon
                    key={index}
                    name={item.id}
                    type={item.type}
                    disabled={item.disabled}
                    label={item.label}
                    required={item.required}
                    click={(e: any) => this.setValue(e, item)}
                    value={fields[item?.id]}
                />}

                {item.type === 'emoji' && <Emoji
                    key={index}
                    name={item.id}
                    disabled={item.disabled}
                    label={item.label}
                    required={item.required}
                    set_emoji={(e: any) => this.setEmoji(e, item)}
                    value={fields[item?.id]}
                />}

                {item.type === 'availability' && <Availability
                    key={index}
                    name={item.id}
                    disabled={item.disabled}
                    label={item.label}
                    required={item.required}
                    update={(e: any) => this.updateAvailability(e)}
                    value={fields[item?.id]}
                />}

                {item.type === 'array' &&
                    <List
                        key={index}
                        name={item.id}
                        disabled={item.disabled}
                        label={item.label}
                        items={item.items}
                        required={item.required}
                        clicked={(e: any) => this.updateArray(e, item)}
                        value={fields[item?.id]}
                    />}

                {item.type === 'address' &&
                    <Address
                        key={index}
                        name={item.id}
                        google={item.google}
                        disabled={item.disabled}
                        label={item.label}
                        obs={item.obs}
                        required={item.required}
                        change={(e: any) => item?.google ? this.setValue(e.target, item) : this.updateAddress(e)}
                        populate={(p: any) => item?.google ? {} : this.populateAddress(p)}
                        set_value_address={(adr: any, name: any) => item?.google ? this.set_value_address(adr, name) : ''}
                        value={fields[item?.id]}
                    />}

                {['editor'].includes(item.type) &&
                    <Editor
                        key={index}
                        name={item.id}
                        disabled={item.disabled}
                        rows={item.rows}
                        type={item.type || 'text'}
                        readonly={item.readonly}
                        label={item.label}
                        required={item.required}
                        change={(e: any) => this.setTextArea(e, item)}
                        value={fields[item.id]}
                    />}
                {item.type === 'tags' && <>
                    <Tags
                        key={index}
                        collector={item.collector}
                        name={item.id}
                        label={item.label}
                        required={item.required}
                        obs={item.obs}
                        populate={(name: any, data: any) => this.populate_mult_select(name, data)}
                        set_value={(e: any) => this.setValue(e, item)}
                        value={fields[item.id]}
                    />
                </>}
            </>)
        } catch (error: any) {
            return ''
        }
    }

    send = (e: any) => {
        e.preventDefault();
        this.save();
    }

    /**
     * RENDER HTML
     * @returns
     */
    render() {
        const {model, btnBackHidden, btnSaveHidden, loader} = this.state;
        const item: any = this.props.children;
        const {fields}: any = this.state;

        this.get_groups();
        let allowed: any = (this.getPermissions('create') || this.getPermissions('update'));
        return (<>
            {loader && <div className={styles.LoaderList}>
                    <span>
                        <figure/>
                    </span>
            </div>}
            {!loader && !allowed && <div className={styles.MenuEmpty}>
                <img src={require('../../assets/denied.svg')} alt='Forbiden'/>
                <label>Seus previlégios de usuário não lhe dão acesso para editar ou cadastrar</label>
                <br/>
                <Button label='VOLTAR' data-name="save" icon='times' action={() => this.cancel()}/>
            </div>}
            {!loader && allowed && <>
                {this.props.children && React.cloneElement(item, {...this.props, ...this.state})}
                {!this.props.children && <div className={`${styles.FormContain}`}>
                    <form method='posts' onSubmit={(e: any) => this.send(e)}>
                        <section>
                            {this.getTitle()}
                            <div group-button='true'>
                                {btnBackHidden && <Button label='CANCELAR' data-name="save" icon='times' action={() => this.cancel()}/>}
                                {btnSaveHidden && <Button label='SALVAR' type='submit' data-name="cancel" icon='check'/>}
                            </div>
                        </section>
                        <section ref={this.form} data-form={this.props.module}>

                            {this.get_groups().length <= 0 && <>
                                {model.filter((show: any) => show.form).sort((a: any, b: any) => a.order - b.order).map((item: any, index: any) =>
                                    <div key={index} data-name={item.id}>{this.all_fields(item, index, fields)}</div>
                                )}
                            </>}

                            {this.get_groups().length > 0 &&
                                this.get_groups().map((group: any, g: any) => <hgroup key={g}>
                                    <h1>{group?.name}</h1>
                                    <div>
                                        {model.filter((show: any) => show.form && show?.group?.id === group?.id).sort((a: any, b: any) => a.order - b.order).map((item: any, index: any) =>
                                            <div key={index}
                                                 data-name={item.id}>{this.all_fields(item, index, fields)}</div>
                                        )}
                                    </div>
                                </hgroup>)}
                            <div className={styles.ButtonsFoot}>
                            </div>
                        </section>
                    </form>
                </div>}
            </>}
        </>);
    }
}