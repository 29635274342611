//IMPORT API
import Api from './pages/core/Api'
import ApiForm from './pages/core/Api/form'
import ApiView from './pages/core/Api/view'

//IMPORT AUDIT
import Audit from './pages/core/Audit'
import AuditView from './pages/core/Audit/view'

//IMPORT USER
import User from './pages/core/User'
import UserForm from './pages/core/User/form'
import UserView from './pages/core/User/view'
import UserAccount from './pages/core/User/account'
import Login from './pages/core/Login'

//IMPORT ENTITIES
import Entities from './pages/core/Entities'
import EntitiesForm from './pages/core/Entities/form'
import EntitiesView from './pages/core/Entities/view'

//IMPORT MODULE
import Module from './pages/core/Module'
import ModuleForm from './pages/core/Module/form'
import ModuleView from './pages/core/Module/view'

//IMPORT PARAMETER
import Parameter from './pages/core/Parameter'
import ParameterForm from './pages/core/Parameter/form'
import ParameterView from './pages/core/Parameter/view'

//IMPORT PLUGINS
import Plugins from './pages/core/Plugins'
import PluginsForm from './pages/core/Plugins/form'
import PluginsView from './pages/core/Plugins/view'

//IMPORT PROFILE
import Profile from './pages/core/Profile'
import ProfileForm from './pages/core/Profile/form'
import ProfileView from './pages/core/Profile/view'

export default [

    //IMPORT FOR PUSH
    {path: '/api', component: Api},
    {path: '/api/form', component: ApiForm},
    {path: '/api/form/:id', component: ApiForm},
    {path: '/api/view/:id', component: ApiView},

    //IMPORT FOR API
    {path: '/audit', component: Audit},
    {path: '/audit/view/:id', component: AuditView},

    //IMPORT FOR ENTITIES
    {path: '/entities', component: Entities},
    {path: '/entities/form', component: EntitiesForm},
    {path: '/entities/form/:id', component: EntitiesForm},
    {path: '/entities/view/:id', component: EntitiesView},

    //IMPORT FOR MODULE
    {path: '/module', component: Module},
    {path: '/module/form', component: ModuleForm},
    {path: '/module/form/:id', component: ModuleForm},
    {path: '/module/view/:id', component: ModuleView},

    //IMPORT FOR PARAMETER
    {path: '/parameter', component: Parameter},
    {path: '/parameter/form', component: ParameterForm},
    {path: '/parameter/form/:id', component: ParameterForm},
    {path: '/parameter/view/:id', component: ParameterView},

    //IMPORT FOR PLUGINS
    {path: '/plugins', component: Plugins},
    {path: '/plugins/form', component: PluginsForm},
    {path: '/plugins/form/:id', component: PluginsForm},
    {path: '/plugins/view/:id', component: PluginsView},

    //IMPORT FOR PROFILE
    {path: '/profile', component: Profile},
    {path: '/profile/form', component: ProfileForm},
    {path: '/profile/form/:id', component: ProfileForm},
    {path: '/profile/view/:id', component: ProfileView},

    //IMPORT FOR USER
    {path: '/user', component: User},
    {path: '/user/form/', component: UserForm},
    {path: '/user/form/:id', component: UserForm},
    {path: '/user/view/:id', component: UserView},
    {path: '/user/account', component: UserAccount},
    {path: '/login', component: Login},
];
