/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import FieldsProops from "../FieldsProops";
import styles from './style.module.scss';
import Icons from "./icons";

/**
 * COMPONENT FIELD
 */
class Field extends FieldsProops {

    state = {
        type: 'icon',
        icon: '',
        brands: ['fa-facebook-square', 'fa-facebook', 'fa-instagram', 'fa-instagram-square', 'fa-youtube', 'fa-youtube-square']
    }

    componentDidMount() {
        this.setState({type: this.props.type});
    }

    check_exclude_field = () => {
        try {
            return this.props.items.excludes.includes(this.props.name);
        } catch (error: any) {
            return false;
        }
    }

    render() {
        return (
            <>
                {this.check_exclude_field() && null}
                {[undefined, false].includes(this.check_exclude_field()) && <>
                    <div className={styles.fieldInput} data-disabled={this.props.disabled}>
                        {this.props.label && <label>{this.props.label}</label>}
                        {this.props.obs && <small>{this.props.obs}</small>}
                        <hgroup>
                            {this.props.type === 'icon' && <ul>
                                {Icons.map((key: any, idx: any) => <li key={idx} data-active={key.replace("fa-", "") === this.props.value} onClick={() => this.props.click({type: 'icon', name: 'icon', value: key.replace("fa-", "")})}>
                                    <i className={`fal ${key}`}/>
                                </li>)}
                            </ul>}

                            {this.props.type === 'social' && <ul>
                                {this.state.brands.map((key: any, idx: any) => <li key={idx} data-active={key.replace("fa-", "") === this.props.value} onClick={() => this.props.click({type: 'icon', name: 'icon', value: key.replace("fa-", "")})}>
                                    <i className={`fa-brands ${key}`}/>
                                </li>)}
                            </ul>}
                        </hgroup>
                    </div>
                </>}
            </>
        );
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Field);
;