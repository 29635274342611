/**
 * PROFILE PAGE VIEW
 * Author: Thiago Silva
 * CreateAt: 2021-3-21
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {View} from '../../../components/Crud';
import styles from '../../../components/Crud/style.module.scss';
import style from './style.module.scss';
import {Button} from '../../../components/Fields';
import {Utils} from "../../../api/utils";

/**
 * COMPONENT VIEW PROFILE
 */

class Page extends View {

    /**
     * LOAD COMPONENT
     */
    callBack = () => {
        let {modules, permissions}: any = this.state.extra;
        permissions.sort(Utils.OrderBy('label'));
        this.setState({data_view: {modules, permissions}});
    }

    /**
     * CHECK PERMISSION IN PERMISSION PROFILE
     * @param row
     * @param item
     */
    checkPermissionInProfile = (row: any, item: any): boolean => {
        let document: any = this.state.document;
        try {
            if (!document.modules) return false;
            return document.modules[row?.to].permissions[item.value];
        } catch (error) {
            return false;
        }
    }

    /**
     * ADD NEW PERMISSIONS
     * @param row
     * @param item
     */
    addPermission = (row: any, item: any) => {
        let document: any = this.state.document;
        if (!document.modules) {
            document.modules = {};
        }
        if (!Object.keys(document.modules).includes(row?.to)) {
            document.modules[row?.to] = {
                vers: [],
                permissions: {}
            };
            document.modules[row?.to].permissions[item.value] = true;
        } else {
            document.modules[row?.to].permissions[item.value] = !document.modules[row?.to].permissions[item.value];
        }
        this.setState({document});
    }

    /**
     * ADDING VERB TO PERMISSIONS
     * @param row
     * @param value
     */
    addVerb = (row: any, value: any) => {
        let document: any = this.state.document;
        if (!document.modules) {
            document.modules = {};
        }
        if (!document.modules[row?.to].vers.includes(value)) {
            document.modules[row?.to].vers.push(value);
        } else {
            document.modules[row?.to].vers = document.modules[row?.to].vers.filter((v: any) => v !== value);
        }
        this.setState({document});
    }

    /**
     * CHECK ALL PERMISSION
     * @param row
     */
    checkAllPermission = (row: any) => {
        const data_view: any = this.state.data_view;
        let document: any = this.state.document;

        if (!document.modules) {
            document.modules = {};
        }

        if (!Object.keys(document.modules).includes(row?.to)) {
            document.modules[row?.to] = {
                vers: [],
                permissions: {}
            };
            data_view.permissions.map((p: any) => {
                document.modules[row?.to].permissions[p.value] = true;
                return p;
            });
        } else {
            data_view.permissions.map((p: any) => {
                document.modules[row?.to].permissions[p.value] = !document.modules[row?.to].permissions[p.value];
                return p;
            });
        }
        this.setState({document});
    }

    /**
     * CHECK ALL MOUDULES
     * @param item
     */
    checkAllModule = (item: any) => {
        const data_view: any = this.state.data_view;
        let document: any = this.state.document;
        if (!document.modules) {
            document.modules = {};
        }
        data_view.modules.map((mod: any) => {
            if (!document.modules[mod?.to]) {
                document.modules[mod?.to] = {
                    vers: [],
                    permissions: {}
                };
            }
            if (!Object.keys(document.modules).includes(mod?.to)) {
                document.modules[mod?.to].permissions[item.value] = true;
            } else {
                document.modules[mod?.to].permissions[item.value] = !document.modules[mod?.to].permissions[item.value];
            }
            return mod;
        });
        this.setState({document});
    }

    /**
     * CHECL ALL PERMISSION AND MODULES
     */
    checkAll = () => {
        const data_view: any = this.state.data_view;
        let document: any = this.state.document;
        if (!document.modules) {
            document.modules = {};
        }
        data_view.modules.map((mod: any) => {
            if (!document.modules[mod?.to]) {
                document.modules[mod?.to] = {
                    vers: [],
                    permissions: {}
                };
            }
            data_view.modules.map((perm: any) => {
                document.modules[mod?.to].permissions[perm.value] = true;
                return perm;
            });
            return mod;
        });
        this.setState({document});
    }

    /**
     * CHECK VALIDATE PERMISSIONS
     * @param item
     */
    checkValidate = (item: any) => {
        const {document}: any = this.state;
        if (document?.modules === undefined) return;
        if (document?.modules[item?.to]) {
            return Object.values(document?.modules[item?.to].permissions).some((s: any) => s);
        } else {
            return false;
        }
    }

    /**
     * CHECK VALIDATE PERMISSIONS
     * @param item
     * @param value
     */
    checkVerbValidate = (item: any, value: any) => {
        const {document}: any = this.state;
        if (document?.modules === undefined) return;
        if (document?.modules[item?.to]) {
            return document?.modules[item?.to].vers.includes(value);
        } else {
            return false;
        }
    }

    render() {
        const {loading, document}: any = this.state;
        const data_view: any = this.state.data_view;
        return (<Layout>
            <div className={styles.ViewContain}>
                {loading && <div className={styles.LoaderList}>
                        <span>
                            <figure/>
                        </span>
                </div>}
                {!loading && <>
                    <section>
                        {this.getTitle('Configurando', 'user-lock')}
                        <div style={{marginRight: 10}}><Button label='Marcar todos' icon='check-double' action={() => this.checkAll()}/></div>
                        <div style={{marginRight: 10}}><Button label='Salvar' icon='check' action={() => this.save()}/></div>
                        <Button label='Voltar' icon='arrow-left' action={() => this.cancel()}/>
                    </section>
                    <section style={{padding: 0}}>
                        <table className={style.TablePermissions}>
                            <thead>
                            <tr>
                                <th/>
                                <th/>
                                {data_view.permissions && data_view.permissions.map((th: any, th_idx: number) =>
                                    <th key={th_idx} onClick={() => this.checkAllModule(th)}>{th.label.toUpperCase().slice(0, 4)}</th>
                                )}
                            </tr>
                            </thead>
                        </table>
                        <div className={style.ScrollTable}>
                            <table className={style.TablePermissions}>
                                <tbody>
                                {data_view.modules && data_view.modules.map((tr: any, tr_idx: number) =>
                                    <tr key={tr_idx} style={{backgroundColor: tr.isroute ? '#c9d3e650' : '', opacity: this.checkValidate(tr) ? 1 : 0.3}}>
                                        <td onClick={() => this.checkAllPermission(tr)}><i className={`fal fa-${tr.icon}`}/></td>
                                        <td>
                                            <span>{tr.name}</span>
                                            {document.verbs && <hgroup>
                                                {document.verbs.map((verb: any, x: any) =>
                                                    <small key={x}
                                                           data-active={this.checkValidate(tr)}
                                                           data-selected={this.checkVerbValidate(tr, verb)}
                                                           data-name={verb.toLowerCase()}
                                                           onClick={() => this.checkValidate(tr) ? this.addVerb(tr, verb) : {}}>{verb}</small>
                                                )}
                                            </hgroup>}
                                        </td>
                                        {data_view.permissions && data_view.permissions.map((td: any, td_idx: number) =>
                                            <td key={td_idx}>
                                                {<div onClick={() => this.addPermission(tr, td)}>
                                                    <i className={`fal fa-${this.checkPermissionInProfile(tr, td) ? 'check' : 'times'}`} style={{color: this.checkPermissionInProfile(tr, td) ? 'var(--color-green)' : 'var(--color-red)'}}/>
                                                </div>}
                                            </td>)}
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </section>
                </>}
            </div>
        </Layout>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);