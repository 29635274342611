/**
 * Author: Thiago Silva
 * Date: 13/02/2021
 */

import axios from 'axios';
import {Storage, Utils} from '../utils';
import jwtDecode from "jwt-decode";

/**
 * SERVICE ABSTRACT
 */
export default class AbstractService {

    name: any;
    response: any;
    domain: any;
    protocol: any;
    adapter: any;
    url: any;
    entity: any;

    /**
     * CONSTRUCTOR
     */
    constructor() {

        /**
         *GET DOMAIN AND PROTOCOL
         */
        this.domain = window.location.hostname;
        this.protocol = window.location.protocol;

        /**
         * SETTING CONFIGURATION AXIOS
         */
        this.adapter = axios.create({
            baseURL: `${Utils.getEnvironment('api')}`,
            timeout: 60000,
            headers: {
                get: {'Content-Type': 'application/json'},
                post: {'Content-Type': 'multipart/form-data'},
                put: {'Content-Type': 'multipart/form-data'},
                delete: {'Content-Type': 'application/json'}
            }
        });

        /**
         * REQUEST INTERCEPTORS
         */
        this.adapter.interceptors.request.use((config: any) => {
            if (!!Storage.get('auth')) {
                let token: any = Storage.get('auth');
                let user: any = jwtDecode(token);
                config.headers.UserId = user.id;
                config.headers.Authorization = `Bearer ${token}`;
                config.headers.PageOrigin = window.location.pathname.split('/')[2] || 'home';
                config.headers.Module = document.location.pathname.split('/')[1] || 'localhost';
            }
            config.headers.Entity = this.getEntityHost();
            return config;
        }, (error: any) => {
            try {
                if (error.response.data.message === 'jwt expired' && error.response.data.name === 'TokenExpiredError') {
                    localStorage.removeItem('auth');
                    document.location.pathname = '/';
                }
                return error.response;
            } catch (e) {
                localStorage.removeItem('auth');
                document.location.pathname = '/';
            }
        });

        /**
         * RESPONSE INTERCEPTORS
         */
        this.adapter.interceptors.response.use((response: any) => {
            return response;
        }, (error: any) => {
            try {
                if (error.response.data.message === 'jwt expired' && error.response.data.name === 'TokenExpiredError') {
                    localStorage.removeItem('auth');
                    document.location.pathname = '/';
                }
            } catch (e) {
            }
            return error.response;
        });

    }

    /**
     * GET ENTITY URL
     * @returns {*}
     */
    getEntityHost() {
        return this.domain;
    }

    /**
     * GET ADAPTER
     * @returns AxiosInstance
     */
    getAdapter() {
        return this.adapter;
    }

    /**
     * GET STORAGE
     * @returns {{prototype: Storage, new(): Storage}}
     */
    getStorage() {
        return Storage;
    }

    /**
     * GET URL BY NAME
     * @returns {string}
     */
    getUrl() {
        return this.url = this.name;
    }

    /**
     * LIST ALL DOCUMENTS BY PAGINATION
     * @param params
     * @returns AxiosResponse
     */
    async list(params: any) {
        return await this.getAdapter().get(this.getUrl(), {params});
    }

    /**
     * LIST ALL DOCUMENTS
     * @param params
     * @returns AxiosResponse
     */
    async all(params: any = {}) {
        return await this.getAdapter().get(`${this.getUrl()}/all`);
    }

    /**
     * GET DOCUMENT BY ID
     * @param id
     * @returns AxiosResponse
     */
    async get(id: any) {
        return await this.getAdapter().get(this.getUrl(), {params: {id}});
    }

    async get_selects() {
        return await this.getAdapter().get(`${this.getUrl()}/get_selects`, {});
    }

    /**
     * SAVE AND UPDATE DOCUMENT
     * @param data
     */
    async save(data: any) {
        if (data._id) {
            this.response = await this.getAdapter().put(this.getUrl(), this.getDataForm(data));
        } else {
            this.response = await this.getAdapter().post(this.getUrl(), this.getDataForm(data));
        }
        return this.response;
    }

    /**
     * DELETE DOCUMENT BY ID
     * @param id
     * @returns AxiosResponse
     */
    async remove(id: any) {
        return await this.getAdapter().delete(this.getUrl(), {params: {id}});
    }

    async delete_all(ids: any) {
        return await this.getAdapter().delete(this.getUrl(), {params: {ids}});
    }

    /**
     * LIST PARAMETER BY NAME
     * @returns
     */
    async select(params: any) {
        return await this.getAdapter().get(`${this.getUrl()}/select`, {params});
    }

    /**
     * CLEAN FILE
     * @param data
     * @returns
     */
    cleanaupFile(data: any) {
        try {
            Object.keys(data).map(k => {
                if (typeof data[k] === 'object') {
                    if (Object.keys(data[k]).some(i => i === 'filename')) {
                        delete data[k]['data'];
                    }
                }
                return k;
            });
        } catch (e) {
        }
        return data;
    }

    /**
     * CHECK FILE IN DATA CHENGE POST FOR FORMADATA
     * @param data
     * @returns
     */
    getDataForm(data: any) {
        try {
            Object.keys(data).map((field: any) => {
                if (data[field] === "" && typeof (data[field]) === 'string')
                    delete data[field];
                if (Object.keys(data[field]).length <= 0 && typeof (data[field]) === 'object' && field !== 'menu')
                    delete data[field];
                return field;
            });
        } catch (e) {
        }

        if (data.file) {
            let formData = new FormData();
            for (let key in data) {
                if (data[key] instanceof Object && !Array.isArray(data[key])) {
                    Object.keys(data[key]).map(i => formData.append(`${key}[${i}]`, data[key][i]));
                } else if (Array.isArray(data[key])) {
                    Object.values(data[key]).forEach((value: any) => formData.append(key + '[]', value))
                } else {
                    formData.append(key, data[key]);
                }
            }
            formData.append('file', data.file);
            return formData;
        } else {
            Object.keys(data).map((field: any, f: any) => {
                if (data[field] === "")
                    delete data[field];
                return f;
            });

            if (data.json) {
                data.json = JSON.parse(data.json);
            }

            return data;
        }
    }

    /**
     * UPLOAD FILE
     * @param file
     * @param progress
     * @param callback
     * @param error
     */
    upload(file: any, progress: any, callback: any, error: any) {
        try {
            let token: any = null;
            let user: any = {};
            if (!!Storage.get('auth')) {
                token = Storage.get('auth');
                user = jwtDecode(token);
            }
            const module = window.location.pathname.split('/')[1] || 'home';


            const config = {
                headers: {
                    UserId: user.id,
                    modulePath: module,
                    currentFile: file.current,
                    registername: file.registername,
                    PageOrigin: window.location.pathname.split('/')[2] || 'home',
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent: any) => {
                    if (progress) {
                        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        progress(progressEvent, percent)
                    }
                }
            }

            let data = new FormData();

            data.append('file', file);
            this.getAdapter().post(`${module}/upload`, data, config)
                .then((res: any) => callback(res))
                .catch((err: any) => error(err))
        } catch (error: any) {
            return error;
        }
    }

    uploads(file: any, progress: any, callback: any, error: any) {
        try {
            let token: any = null;
            let user: any = {};
            if (!!Storage.get('auth')) {
                token = Storage.get('auth');
                user = jwtDecode(token);
            }
            const module = window.location.pathname.split('/')[1] || 'home';


            const config = {
                headers: {
                    UserId: user.id,
                    modulePath: module,
                    currentFile: file.current,
                    registername: file.registername,
                    PageOrigin: window.location.pathname.split('/')[2] || 'home',
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent: any) => {
                    if (progress) {
                        const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        progress(progressEvent, percent)
                    }
                }
            }

            let data = new FormData();

            data.append('file', file);

            this.getAdapter().post(`${module}/upload`, data, config)
                .then((res: any) => callback(res))
                .catch((err: any) => error(err))
        } catch (error: any) {
            return error;
        }
    }

    /***
     * SEARCH CEP
     * @param address
     */
    async google_address(address: any) {
        const module = window.location.pathname.split('/')[1] || 'home';
        return this.getAdapter().get(`${module}/google_address`, {params: {address}});
    }

    /***
     * SEARCH CEP
     * @param cep
     */
    async search_cep(cep: any) {
        const module = window.location.pathname.split('/')[1] || 'home';
        return this.getAdapter().get(`${module}/search_cep`, {params: {cep}});
    }

    /**
     * CLEAR AND REMOVE FILE MODULE
     * @param data
     */
    async clear_file(data: any) {
        return await this.getAdapter().post(`${this.getUrl()}/clear_file`, data);
    }
}