/**
 * AUDIT PAGE INDEX
 * Author: Thiago Silva
 * CreateAt: 2021-5-8
 */

import React from 'react';
import {bindActionCreators} from "redux";
import * as adminActionCreators from "../../../store/actions/adminActionCreators";
import * as authActionCreators from "../../../store/actions/authActionCreators";
import {connect} from "react-redux";
import {Default as Layout} from '../../../layout';
import {List} from '../../../components/Crud';
import {Button, Input, Select} from "../../../components/Fields";

/**
 * COMPONENT PAGE AUDIT
 */
class Page extends List {

    componentDidMount() {
        super.componentDidMount();
        this.setState({actView: 'history', searching: false, load: false})
    }

    consult = () => {
        const {filter}: any = this.state;
        const {Alert}: any = this.props;
        if (Object.keys(filter).length <= 0) {
            Alert({
                title: 'Filtros em branco',
                message: 'Preencha um dos filtros para consultar'
            })
            return;
        }
        this.setState({loading: true}, () => {
            this.getData(1).then();
        });
    }

    addFilter = () => {
        const {filter}: any = this.state;
        return (<>
            <Select value={filter.module} name='module' label_option='Selecione um modulo' service={{name: 'module', field: 'name', option: 'to'}} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'module', value: ''})}/>
            <Select value={filter.user} name='user' label_option='Selecione um usuário' service={{name: 'user', field: 'name'}} change={(e: any) => this.setFilter(e.target)} clear={(e: any) => this.setFilter({name: 'user', value: ''})}/>
            <Input value={filter.start_date} name='start_date' type='date' change={(e: any) => this.setFilter(e.target)}/>
            <div>Até</div>
            <Input value={filter.end_date} name='end_date' type='date' change={(e: any) => this.setFilter(e.target)}/>
            <Button label='Buscar' action={() => this.consult()}/>
        </>);
    }

    render() {
        return (<><Layout>
            {super.render()}
        </Layout></>);
    }
}

const mapStateToProps = (state: any) => (
    {
        ...state.admin,
        ...state.auth,
    }
);

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...adminActionCreators,
    ...authActionCreators,
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Page);
